import React, {useState, useEffect} from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { BsPerson, BsBook, BsChatSquareQuote } from "react-icons/bs"
import {useTranslation} from 'react-i18next'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { saveAs } from 'file-saver';

import { useSnackbar } from 'notistack';
// import avatar from '../media/avatar.png'
import avatar from '../media/avatart.png'
import { ProfileAction } from '../../redux';
import { ProfileImageAddAction } from '../../redux';
import { connect } from 'react-redux'
import Loader from '../Loader/Loader';
import './Style.css'
const useStyles = makeStyles((theme) => ({
    profileOuterGrid:{
        paddingBottom: "10vw",
        paddingTop: "7.5vw",
        backgroundColor: "#F4F6FA",
        [theme.breakpoints.down('xs')]: {
            paddingTop: "30vw",
        },
    },
    profileMenuGrid: {
        // backgroundColor: "#ffffff",
    },
    profilMenuItem1Grid: {
        
    },
    profileMenuGridButton: {
        width: "100%",
        borderRadius: 0,
        height: "3vw",
        color: "white",
        fontSize: "0.83vw",
        letterSpacing: "0.1vw",
        backgroundColor: "#3F51B5",
        "&:hover": {
            backgroundColor: "#3F51B5",
        },
        [theme.breakpoints.down('xs')]: {
            height: "8vw",
            fontSize: "2vw",
            padding: "1vw",
            lineHeight: 1.5
        },
    },
    profileMenuGridButtonIcon: {
        marginRight: "1vw"
    },
    profileMenuGridButton1: {
        width: "100%",
        borderRadius: 0,
        height: "3vw",
        color: "black",
        fontSize: "0.83vw",
        letterSpacing: "0.1vw",
        backgroundColor: "white",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#3F51B5",
            color: "white"
        },
        [theme.breakpoints.down('xs')]: {
            height: "8vw",
            fontSize: "2vw",
            padding: "1vw",
            lineHeight: 1.5
        },
    },
    profilMenuGridButtonIcon: {
        marginRight: "1vw",
        [theme.breakpoints.down('xs')]: {
            marginRight: "2vw",
            fontSize: "2.7vw",
        },
    },
    profileInfo: {
        marginTop: "4vw",
        [theme.breakpoints.down('xs')]: {
            marginTop: "8vw",
        },
    },
    profileShortPersonalInfoDiv: {
        margin: "1vw 1vw 2vw 1vw"
    },
    profileShortPersonalInfoImg: {
        width: "60%",
        margin: "0 auto"
    },
    profileShortPersonalInfoBoldText: {
        fontSize: "1vw",
        fontWeight: 600,
        letterSpacing: "0.1vw",
        margin: "1vw",
        textTransform: "uppercase",
        [theme.breakpoints.down('xs')]: {
            margin: "2vw 1.5vw",
            fontSize: "1.6vw",
        },
    },
    profileShortPersonalInfoSmalBoldText: {
        fontWeight: 600,
        fontSize: "0.83vw",
        color: "black",
        margin: "1vw",
        textTransform: "uppercase",
        [theme.breakpoints.down('xs')]: {
            margin: "2vw 1.5vw",
            fontSize: "1.4vw",
        },
    },
    profileShortPersonalInfoSmalLightText: {
        fontWeight: 400,
        fontSize: "0.83vw",
        color: "black",
        margin: "1vw",
        [theme.breakpoints.down('xs')]: {
            margin: "2vw 1.5vw",
            fontSize: "1.4vw",
        },
    },
    profilePersonalInfoActive: {
        opacity: 1,
        position: "relative",
        right: 0,
        transition: "opacity 1.5s ease,  right 1s ease",
    },
    profilePersonalInfoNonActive: {
        opacity: 0,
        width: "44.1vw",
        marginLeft: "22vw",
        position: "fixed",
        right: "-45vw",
        transition: "opacity 1.5s ease, right 1s ease",
    },
    profilePersonalInfoHeader: {
        backgroundColor: "white"
    },
    profilePersonalInfoBody:{
        backgroundColor: "white",
        marginTop: "0.3vw",
        padding: "3vw 2vw",
    },
    profileApplicationInfoBody:{
        backgroundColor: "white",
        marginTop: "0.3vw",
        padding: "3vw",
        [theme.breakpoints.down('xs')]: {
            marginTop: "0.5vw",
            padding: "6vw 3vw",
        },
    },
    table: {
        width: "100%",
        "& .MuiTableCell-root": {
            fontSize: "0.83vw",
            fontFamily: "inherit",
            padding: "0.83vw",
            borderBottom: "0.1vw solid rgba(224, 224, 224, 1)",
            letterSpacing: "0.1vw",
            fontWeight: 500,
            lineHeight: 1.4
        },
        [theme.breakpoints.down('xs')]: {
            "& .MuiTableCell-root": {
                padding: "2vw 1.5vw",
                fontSize: "1.4vw",
            }
        },
    },
    tableContainer : {
        border: "0.1vw solid rgba(224, 224, 224, 1)",
        borderRadius: "0.2vw",
        [theme.breakpoints.down('xs')]: {
            border: "0.2vw solid rgba(224, 224, 224, 1)",
            borderRadius: "1vw",
        },
        
    },
    diplomaButton: {
        backgroundColor: "#3F51B5", 
        color: "white",
        fontSize: "0.83vw",
        padding: "0.5vw 1vw",
        minWidth: "6",
        maxWidth: "8vw",
        textTransform: "none",
        backgroundColor: "#25539A",
        "&:hover": {
            backgroundColor: "#1AB5E0"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.4vw",
            padding: "0.5vw 1vw",
            minWidth: "12vw",
            maxWidth: "15vw",
            borderRadius: "0.5vw"
        },
    }
}))

function Profile({appData, ProfileAction, ProfileImageAddAction}) {
    const {t}=useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        ProfileAction(enqueueSnackbar)
    }, [])
    const {profile} = appData;
    const [profileImage, setProfileImage] = useState(null)
    const [aboutEdit, setAboutEdit] = useState(false)
    const [buttonsState, setButtonsState] = useState({button1: true, button2: false, button3: false})
    const classes = useStyles();
    function createData(id, data, subject, status) {
        return { id, data, subject, status};
      }
      
    const rows = [
    createData("1",'2021-01.15','Departmen1',"finished"),
    createData("2",'2021-03-25','Departmen2',"finished"),
    createData("3",'2021-06-1','Departmen1',"reject"),
    createData("4",'2021-06-5','Departmen2',"reject"),
    createData("5",'2021-07-2','Departmen1',"In progress"),
    ];
    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };
    
    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
        // I am lazy and don't like to type things more than once
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,19); // First ten digits of input only
        const start = input.substring(0,3);
        const code = input.substring(3,5);
        const middle = input.substring(5,8);
        const pair1 = input.substring(8,10);
        const pair2 = input.substring(10,12);
    
        if(input.length > 10){target.value = `+${start}(${code})${middle}-${pair1}-${pair2}`;}
        else if(input.length > 8){target.value = `+${start}(${code})${middle}-${pair1}`;}
        else if(input.length > 5){target.value = `+${start}(${code})${middle}`;}
        else if(input.length > 3){target.value = `+${start}(${code}`;}
        else if(input.length > 0){target.value = `+${start}`;}
    };
    const preview = (e) => {
        document.getElementById("avatarImage").src=URL.createObjectURL(e.target.files[0])
    }
    const DiplomaDownload = () =>{
        if(appData.profile.studentcertificat && appData.profile.studentcertificat.file_collage ) { appData.profile.studentcertificat.file_collage && saveAs( appData.profile.studentcertificat.file_collage, 'file_collage')
        }else{
            const message = t('snackbarDiplomeText');
            enqueueSnackbar(message, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right', }});
        } 
            
    }
    const DiplomaDownloadUnver = () =>{
        if(appData.profile.studentcertificat && appData.profile.studentcertificat.file_unversitet) { appData.profile.studentcertificat.file_unversitet && saveAs( appData.profile.studentcertificat.file_unversitet, 'file_unversitet')
        }else{
            const message = t('snackbarDiplomeText');
            enqueueSnackbar(message, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right', }});
        } 
            
    }
    return (
        appData.loading ? <Loader/>
        :<Grid container direction="column" justifyContent="center" alignItems="center" className={classes.profileOuterGrid} >
        <Grid container item sm={8} xs={11} direction="row" justifyContent="flex-start" alignItems="center" className={classes.profileMenuGrid} >
            <Grid container item xs={3} direction="row" justifyContent="center" alignItems="center" className={ classes.profileMenuItem1Grid}>
                <Button onClick={()=> {setButtonsState({ button1: true, button2: false, button3: false})}} variant="contained" className={buttonsState.button1 ? classes.profileMenuGridButton : classes.profileMenuGridButton1} > <BsPerson className={classes.profilMenuGridButtonIcon} /> {t('profile.aboutTitle')}</Button>
            </Grid> 
            <Grid container item xs={3} direction="row" justifyContent="center" alignItems="center" className={classes.profileMenuItem1Grid}>
                <Button onClick={()=> {setButtonsState({ button1: false, button2: true, button3: false})}} variant="contained" className={buttonsState.button2 ? classes.profileMenuGridButton : classes.profileMenuGridButton1} > <BsBook className={classes.profilMenuGridButtonIcon} /> {t('profile.studyTitle')} </Button>
            </Grid> 
            <Grid container item xs={3} direction="row" justifyContent="center" alignItems="center" className={classes.profileMenuItem1Grid}>
                <Button onClick={()=> {setButtonsState({ button1: false, button2: false, button3: true})}} variant="contained" className={buttonsState.button3 ? classes.profileMenuGridButton : classes.profileMenuGridButton1} > <BsChatSquareQuote className={classes.profilMenuGridButtonIcon} /> {t('profile.appTitle')}</Button>
            </Grid>     
        </Grid>
        <Grid container item xs={11} direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.profileInfo} >
            <Grid container item xs={4} direction="row" justifyContent="center" alignItems="flex-start" style={{backgroundColor: "transparent", paddingRight: "2vw"}} >
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{backgroundColor: "white"}} >
                    <p className={classes.profileShortPersonalInfoBoldText} >{t('profile.personal')}</p>
                    {
                        aboutEdit ? 
                        <React.Fragment>
                            <img src={appData.profile.image? appData.profile.image :  avatar} id="avatarImage" 
                            className={classes.profileShortPersonalInfoImg}/>
                            <input type="file" accept="image/*" onChange={(e)=>{preview(e);  setProfileImage(e.target.value); }} style={{ overflow: "hidden", background: "rgba(0, 0, 0, 0.8)", width: "100%", color: "white", padding: "1vw 2vw", fontSize: "0.83vw"}} />
                            <br/>
                        </React.Fragment>
                        : <img src={ appData.profile.image? appData.profile.image :  avatar}  className={classes.profileShortPersonalInfoImg}/>
                    }
                    <div className={classes.profileShortPersonalInfoDiv} >
                        <table className="ProfileTableWrapper">
                            <tr>
                                <th>{t('profile.personalTable.name')}</th>
                                <td>{profile.full_name}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.personalTable.birthday')}</th>
                                <td>{profile.birth}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.personalTable.location')}</th>
                                <td>{profile.home_address}</td>
                            </tr>
                        </table>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={8} direction="row" justifyContent="flex-start" alignItems="center" className={buttonsState.button1 ? classes.profilePersonalInfoActive : classes.profilePersonalInfoNonActive} >
                <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.profilePersonalInfoHeader}>
                    <p className={classes.profileShortPersonalInfoBoldText} >{t('profile.aboutTitle')}</p>
                    {/* {
                        aboutEdit ? <Button onClick={()=>{setAboutEdit(!aboutEdit); 
                            let ImageData = new FormData(); 
                            ImageData.append('image', profileImage);
                            ProfileImageAddAction(enqueueSnackbar, ImageData)
                        }} style={{marginRight: "1vw", fontSize: "0.83vw", backgroundColor: "#3F51B5", color: "white", letterSpacing: "0.1vw", padding: "0.3vw 1vw" }} variant="contained"> Save <FiSave style={{marginLeft: "1vw"}} /> </Button>
                        : <Button onClick={()=>{setAboutEdit(!aboutEdit);}} style={{marginRight: "1vw", fontSize: "0.83vw", backgroundColor: "red", color: "white", letterSpacing: "0.1vw", padding: "0.3vw 1vw" }} variant="contained"> Edit <FiEdit style={{marginLeft: "1vw"}} /> </Button>
                    } */}
                    
                </Grid>
                
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.profilePersonalInfoBody}>
                    <div>
                        <table className="ProfileTableWrapper tableTd">
                            <tr>
                                <th>{t('profile.aboutTable.fullname')}</th>
                                <td>{profile.full_name}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.location1')}</th>
                                <td>{profile.home_address}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.location2')}</th>
                                <td>{profile.address}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.mail')}</th>
                                <td>{profile.email}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.passport')}</th>
                                <td>{profile.passport}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.birthday')}</th>
                                <td>{profile.birth}</td>
                            </tr>
                            <tr>
                                <th>{t('profile.aboutTable.phone')}</th>
                                <td>{profile.phone_number}</td>
                            </tr>
                        </table>
                    </div>
                </Grid>
            </Grid>
            {/* Study Info */}
            <Grid container item xs={8} direction="row" justifyContent="flex-start" alignItems="center" className={buttonsState.button2 ? classes.profilePersonalInfoActive : classes.profilePersonalInfoNonActive} >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.profilePersonalInfoHeader}>
                    <p className={classes.profileShortPersonalInfoBoldText} >{t('profile.studyTitle')}</p>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.profilePersonalInfoBody}>
                <div>
                    <table className="ProfileTableWrapper tableTd"  >
                        <tr>
                            <th>{t('profile.studyTable.faculty')}</th>
                            <td>{profile.faculty_title}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.major')}</th>
                            <td>{profile.major_title}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.degree')}</th>
                            <td>{profile.degree1}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.payment')}</th>
                            <td>{profile.contract1}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.group')}</th>
                            <td>{profile.group_title}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.educationType')}</th>
                            <td>{profile.type1}</td>
                        </tr>
                        <tr>
                            <th>{t('profile.studyTable.course')}</th>
                            <td>{profile.course1}</td>
                        </tr>
                    </table>
                </div>
                </Grid>
            </Grid>
            {/* Application */}
            <Grid container item xs={8} direction="row" justifyContent="flex-start" alignItems="center" className={buttonsState.button3 ? classes.profilePersonalInfoActive : classes.profilePersonalInfoNonActive} >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.profilePersonalInfoHeader}>
                    <p className={classes.profileShortPersonalInfoBoldText} >{t('profile.appTitle')}</p>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.profileApplicationInfoBody}>
                {/* <div className={classes.profileApplicationInfoBodyDataGrid} >
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        disableSelectionOnClick={true}
                    />
                </div> */}
                <TableContainer component={Paper} className={classes.tableContainer} >
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <TableCell align="left">&#8470;</TableCell>
                            <TableCell align="left">{t('profile.appTable.data')}</TableCell>
                            <TableCell align="left">{t('profile.appTable.subject')}</TableCell>
                            <TableCell align="left">{t('profile.appTable.status')}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.id}</TableCell>
                                <TableCell align="left">{row.data}</TableCell>
                                <TableCell align="left">{row.subject}</TableCell>
                                <TableCell align="left">{row.status}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ProfileAction: (enqueueSnackbar) => dispatch(ProfileAction(enqueueSnackbar)),
        ProfileImageAddAction: (enqueueSnackbar, ImageData) => dispatch(ProfileImageAddAction(enqueueSnackbar, ImageData))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)