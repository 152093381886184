import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import registrarBack from '../media/registrarBack.jpg'
import phone from '../media/phone.svg'
import Grid from '@material-ui/core/Grid';
import { BsPhone } from "react-icons/bs"
import { MdSupportAgent } from "react-icons/md"
import { useNavigate } from "react-router-dom";
import {useTranslation} from 'react-i18next'
import { ComplaintAction } from '../../redux';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
    registrarOuterGrid: {
        // backgroundColor: "red"
    },
    registrarHeaderGrid: {
        marginTop: "5.5vw",
        minHeight: "19vw",
        width: "100%",
        backgroundImage: `url(${registrarBack})`,
        backgroundSize: "100% auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0% 0%",
        overflow: "visible",
        color: "white",
        [theme.breakpoints.down('xs')]: {
            marginTop: "18vw",
            minHeight: "26vw",
        },
    },
    registrarHeaderGridBoldText: {
        lineHeight: 1.29,
        fontWeight: 700,
        margin: "0.5vw 0",
        fontSize: "2.2vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "5vw",
        },
    },
    registrarHeaderGridLightText: {
        fontSize: "1vw",
        fontWeight: 400,
        lineHeight: 1.4,
        margin: 0,
        [theme.breakpoints.down('xs')]: {
            fontSize: "2vw",
        },
    },
    registrarBodyGrid: {
        padding: "2.62vw 0",
        [theme.breakpoints.down('xs')]: {
            padding: "4vw 0 4vw 2vw",
        },
    },
    registrarBodyGridLeft: {
        padding: "0 2vw",
        [theme.breakpoints.down('xs')]: {
            padding: "0 0 0 2vw",
        },
    },
    registrarBodyGridLeftIcon: {
        fontSize: "3.5vw",
        color: "#2277B3",
        [theme.breakpoints.down('xs')]: {
            
        },
    },
    registrarBodyGridLeftBoldText: {
        fontSize: "1.46vw", 
        lineHeight: 1.4,
        margin :"0.5vw 0", 
        color: "#0b1320", 
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.5vw", 
            margin :"2vw 0 1vw 0", 
        },
    },
    registrarBodyGridLeftName: {
        fontWeight: 400, 
        lineHeight: 1.75, 
        color: "#3b3f47", 
        margin :"0.5vw 0", 
        fontSize: "0.83vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.2vw", 
            margin :"0", 
        },
    },
    registrarBodyGridLeftTexts: {
        fontWeight: 400, 
        lineHeight: 1.75, 
        color: "#3b3f47", 
        margin :"0", 
        fontSize: "0.83vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.2vw", 
        },
    },
    registrarBodyGridLeftUnderLine1: {
        borderBottom: "0.2vw solid #3c3f48", 
        margin: "1.57vw 0", 
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            height: "0.1vw",
        },
    },
    registrarBodyGridLeftUnderLine: {
        backgroundColor: "#e6e6e8", 
        height: "0.05vw",
        [theme.breakpoints.down('xs')]: {
            height: "0.1vw",
        },
    },
    registrarBodyGridRight: {
        padding: "0 2vw",
        [theme.breakpoints.down('xs')]: {
            padding: "0 4vw",
        },
    },
    registrarBodyGridRightCard: {
        width: "100%",
        // backgroundColor: "red",
        boxShadow: "0 0.1vw 0.52vw 0 rgb(0 0 0 / 10%)",
        borderRadius: "0.26vw",
        padding: "0.78vw", 
        fontSize: "0.83vw",
        [theme.breakpoints.down('xs')]: {
            boxShadow: "0 0.2vw 1vw 0 rgb(0 0 0 / 10%)",
            borderRadius: "1vw",
            padding: " 1.5vw 1vw", 
            fontSize: "0.83vw",
        },
    },
    registrarBodyGridRightCardBoldText: {
        fontSize: "1.46vw", 
        marginTop: 0, 
        marginBottom: "1vw", 
        lineHeight: 1.25, 
        color: "#0b1320", 
        fontWeight: 600,
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.46vw", 
            marginBottom: "3vw", 
        },
    },
    innerGridRightInputDiv: {
        marginTop: "1vw",
        width: "100%", 
        display: "flex", 
        justifyContent: "flex-start",  
        alignItems: "center",
    },
    innerGridRightInputDivIcon: {
        color: "#ADADAD", 
        marginRight: "0.7vw",
        fontSize: "1vw",
        [theme.breakpoints.down('xs')]: {
            marginRight: "1vw",
            fontSize: "4vw",
            padding: "0.5vw"
        },
    },
    innerGridRightInputDivDiv: {
        display: "flex", 
        justifyContent: "center", 
        alignItems: "flex-start", 
        flexDirection: "column"
    },
    innerGridRightInputDivDivLabel: {
        color: "#ADADAD", 
        fontSize: "0.65vw",
        marginLeft: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.5vw",
        },
    },
    innerGridRightInputDivDivInput: {
        width: "16vw", 
        padding: "0.5vw 1vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.9vw", 
        border: "0.1vw solid #ADADAD", 
        boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
        outline: "none", 
        borderRadius: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            width: "26vw", 
            padding: "1.5vw 2vw", 
            margin: "1vw 0", 
            fontSize: "2vw", 
            boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
            borderRadius: "1.5vw",
        },
    },
    innerGridRightInputDivDivInputError: {
        color: "red",
        fontSize: "0.65vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.2vw",
        },
    },
    innerGridRightInputDivDivInputText: {
        minHeight: "10vw",
        maxHeight: "10vw",
        minWidth: "20vw",
        maxWidth: "20vw",
        padding: "0.5vw 1vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.9vw", 
        border: "0.1vw solid #ADADAD", 
        boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
        outline: "none", 
        borderRadius: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "17vw",
            maxHeight: "17vw",
            minWidth: "33vw",
            maxWidth: "33vw",
            padding: "1.5vw 2vw", 
            margin: "1vw 0", 
            fontSize: "2vw", 
            boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
            borderRadius: "1.5vw",
        },
    },
    confirmAndSend: {
        backgroundColor: "#0b1320", 
        padding: "0.52vw 1.57vw", 
        fontWeight: 600, 
        fontSize: "0.83vw", 
        cursor: "pointer",
        width: "95%", 
        color: "white", 
        letterSpacing: "0.1vw", 
        marginTop: "1vw",
        "&:hover": {
            backgroundColor: "#0A1320"
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: "3vw",
            padding: "1w 1.57vw", 
            fontSize: "2.2vw",
        },
    }
}))

function ContactRegistrar({appData, ComplaintAction}) {

    const test = () => axios.get('/api/v1/student_data_to_excel')

    useEffect(() => {
        // const socket = new WebSocket("ws://10.10.115.102:8003/ws/chat/nimadir", {
        //   headers: {
        //     Authorization: `Bearer ${appData?.user?.access}`,
        //   },
        // });
        // fc10989f-afe8-4df0-abf6-92b376c89a81
        const socket = new WebSocket(`ws://registr.tsue.uz/ws/chat/room_?${appData?.user?.id}`);
        socket.onopen = () => {
            test()
        }
        socket.onerror = (error) => console.log('ERROR', error)
        socket.onclose = () => console.log('Close')
        socket.onmessage = (message) => {
          const data = JSON.parse(message?.data)
        }
      }, []);
    const {t}=useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
      const [complaintState, setComplaintState] = useState({
          comment: "",
          phone: "",
          to_whom: ""
      });
      const [text, setText] = useState('Enter your text')
      const [phoneError, setPhoneError] = useState("Enter your phone number");
      const [selectError, setSelectError] = useState(" Select type")
      const hendleOnChange = (e) => {
          setComplaintState({...complaintState, [e.target.name]: e.target.value})
      }
      const hendleOnPhoneChange = (e) => {
        setComplaintState({...complaintState, [e.target.name]: e.target.value.replace(/\D/g, "")})
    }
      const [currency, setCurrency] = React.useState('');

    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };
    
    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
        // I am lazy and don't like to type things more than once
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,19); // First ten digits of input only
        const start = input.substring(0,3);
        const code = input.substring(3,5);
        const middle = input.substring(5,8);
        const pair1 = input.substring(8,10);
        const pair2 = input.substring(10,12);
    
        if(input.length > 10){target.value = `+${start}(${code})${middle}-${pair1}-${pair2}`;}
        else if(input.length > 8){target.value = `+${start}(${code})${middle}-${pair1}`;}
        else if(input.length > 5){target.value = `+${start}(${code})${middle}`;}
        else if(input.length > 3){target.value = `+${start}(${code}`;}
        else if(input.length > 0){target.value = `+${start}`;}
    };
      const handleChange = (e) => {
        setComplaintState({...complaintState, [e.target.name]: e.target.value})
        setCurrency(e.target.value);
      };
      const checkErrors = () => {
        if (complaintState.to_whom) {
            setSelectError("");
        }else{
            setSelectError("Select type");
        }
        if (complaintState.phone.replace(/\D/g, "")) {
            if (complaintState.phone.replace(/\D/g, "").length < 12) {
                setPhoneError("Numbers should be 12 digits");
            }else{
                setPhoneError("");
            }
        }else{
            setPhoneError("Enter your phone number");
        }
        if (complaintState.comment) {
            if (complaintState.comment.length < 51) {
                setText("Text must be more than 50 letters");
            }else{
                setText("");
            }
        }else{
            setText("Enter your text");
        }
        
      }
      const { enqueueSnackbar } = useSnackbar();
      const requestSend = (complaintState, navigate, enqueueSnackbar) => {
          ComplaintAction("student_appeal", complaintState, navigate, enqueueSnackbar); setComplaintState({comment: "", phone: "",})
      }
      const requestConfirm = () => {
          checkErrors();
      }
    return (
        <React.Fragment>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.registrarOuterGrid}>
                <Grid item xs={12} container direction="column" justifyContent="center" alignItems="center" className={classes.registrarHeaderGrid}>
                    <p className={classes.registrarHeaderGridBoldText} >{t('registrar.headerBoldText')}</p>
                    <p className={classes.registrarHeaderGridLightText} >{t('registrar.headerLighttext1')}</p>
                    <p className={classes.registrarHeaderGridLightText}>{t('registrar.headerLighttext2')}</p>
                </Grid>
                <Grid item sm={8} xs={12} container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.registrarBodyGrid}>
                    <Grid item xs={6} container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.registrarBodyGridLeft}>
                        <img src={phone} className={classes.registrarBodyGridLeftIcon} />
                        <p className={classes.registrarBodyGridLeftBoldText} > {t('registrar.boldText1')}</p>
                        <p className={classes.registrarBodyGridLeftName} >{t('registrar.name')}</p>
                        {
                            t('registrar.contactInformation', {returnObjects: true}).map((contact) => {
                                const { boldText, lightText } = contact;
                                return(
                                    <p className={classes.registrarBodyGridLeftTexts} ><strong>{boldText}</strong>&nbsp;&nbsp;&nbsp;{lightText}</p>
                                )
                            })
                        }
                        <p className={classes.registrarBodyGridLeftTexts}>&nbsp;</p>
                        <p className={classes.registrarBodyGridLeftTexts}><strong>Email:</strong>&nbsp;info@tsue.uz</p>
                        <div className={classes.registrarBodyGridLeftUnderLine1} >
                            <div className={classes.registrarBodyGridLeftUnderLine} ></div>
                        </div>
                    </Grid>
                    <Grid item xs={6} container direction="column" justifyContent="flex-start" alignItems="center" className={classes.registrarBodyGridRight}>
                        <Grid item xs={11} container direction="column" justifyContent="flex-start" alignItems="center" className={classes.registrarBodyGridRightCard}>
                            <h3 className={classes.registrarBodyGridRightCardBoldText} >{t('registrar.boldText2')}</h3>
                            <div className={classes.innerGridRightInputDiv} >
                                <IconButton  className={classes.innerGridRightInputDivIcon}><MdSupportAgent /></IconButton> 
                                <div className={classes.innerGridRightInputDivDiv} >
                                    <label for="select" className={classes.innerGridRightInputDivDivLabel} >{t('registrar.selectLable')}: </label>
                                    <select type="text" id="select"  name="to_whom" className={classes.innerGridRightInputDivDivInput}  value={currency} onChange={(e)=>handleChange(e)}>
                                        <option value="">Select</option>
                                        <option value="Register office">Register office</option>
                                        <option value="Database office">Database office</option>
                                        <option value="Credit system office">Credit system office</option>
                                    </select>
                                <span className={classes.innerGridRightInputDivDivInputError} >{selectError}</span>
                                </div>
                            </div>
                            <div className={classes.innerGridRightInputDiv} >
                                <IconButton  className={classes.innerGridRightInputDivIcon}><BsPhone /></IconButton> 
                                <div className={classes.innerGridRightInputDivDiv} >
                                    <label for="phone" className={classes.innerGridRightInputDivDivLabel} >{t('registrar.phoneLable')}: </label>
                                    <input value={complaintState.phone} type="tel" id="phone"  name="phone" onKeyUp={(e)=> {formatToPhone(e)}} onKeyDown={(e)=> {enforceFormat(e)}} className={classes.innerGridRightInputDivDivInput} maxLength="17"  onChange={(e)=> {hendleOnPhoneChange(e)}} />
                                    <span className={classes.innerGridRightInputDivDivInputError} >{phoneError}</span>
                                </div>
                            </div>
                            <div className={classes.innerGridRightInputDivDiv} >
                                <label for="comment" className={classes.innerGridRightInputDivDivLabel} style={{marginTop: "2vw"}} >{t('registrar.messageLable')}: </label>
                                <textarea value={complaintState.comment} className={classes.innerGridRightInputDivDivInputText} onChange={(e)=> hendleOnChange(e)} name="comment" id="comment"/>
                                <span className={classes.innerGridRightInputDivDivInputError} >{text}</span>
                            </div> 
                            {(text==="" && phoneError==="" && selectError==="") ? 
                            <Button onClick={(e)=>requestSend(complaintState, navigate, enqueueSnackbar)} className={classes.confirmAndSend} >{t('registrar.sendButton')}</Button>
                            :<Button onClick={(e)=>requestConfirm()} className={classes.confirmAndSend} >{t('registrar.confirmButton')}</Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ComplaintAction: (Url, complaintState, navigate, enqueueSnackbar) => dispatch(ComplaintAction(Url, complaintState, navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactRegistrar)