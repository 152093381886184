import React from 'react'
import {useTranslation} from 'react-i18next'

function ExchangePrograms() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide mb-3'>
                {t('exchange_programs.name')}
            </p>
            <p className=' text-center xl:text-lg text-[#263d8a] sm:text-base text-sm font-semibold tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('dormitory.part1')}
            </p>
            <p className='xl:text-lg text-[#263d8a] sm:text-base text-sm font-semibold tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('exchange_programs.part1')}
            </p>
            <ul className='grid grid-cols-12 sm:text-base text-sm tracking-normal'>
                {
                    t('exchange_programs.part2', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='sm:col-span-6 col-span-11 flex items-center ml-6 border px-4 py-2 hover:bg-[#1cb5e0] hover:text-white ' >
                               {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-6' >
                {t('exchange_programs.part3')}
            </p>
            <ul className='list-inside text-[#1cb5e0] pl-6 pr-3 sm:text-base text-sm italic tracking-normal'>
                {
                    t('exchange_programs.part4', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                - {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-8' >
                {t('exchange_programs.part5')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-3 italic' >
                <span className='text-[#263d8a] font-semibold' >{t('exchange_programs.part6')}</span> {t('exchange_programs.part7')}
            </p>
        </div>
    </div>
  )
}

export default ExchangePrograms