import React, {useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { DiplomaPdfAction, PdfAction } from '../../redux';
import { connect } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom";
import { saveAs } from 'file-saver';
import Loader from '../Loader/Loader';
import indexbg from '../media/indexbg.svg'
import {useTranslation} from 'react-i18next'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    outerGrid: {
        height: "100vh",
        backgroundImage: `url(${indexbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-100% 20%",
        overflow: "visible",
    },
    grid: {
        backgroundColor: "white",
        padding: "4vw 1vw 4vw 1vw",
        width: "100%",
        boxShadow: "0.26vw 0.78vw 0.62vw 0.26vw rgb(14 36 129 / 8%)",
        minHeight: "20vw",
        maxHeight: "20vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "40vw",
            maxHeight: "40vw",
        },
    },
    outputText: {
        color: "#104279",
        fontWeight: "bold",
        fontSize: "1.2vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.2vw",
        },
    },
    submitButton: {
        fontSize: "0.73vw",
        lineHeight: 1.4,
        padding: "0.52vw 1.3vw",
        letterSpacing: "0.1vw",
        color: "white",
        backgroundImage: "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
        backgroundPositionX: "100%",
        backgroundPositionY: 0,
        backgroundSize: "200%",
        backgroundRepeat: "initial",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
        boxShadow: "0 0.4vw 0.63vw 0 rgb(14 36 129 / 8%)",
        borderRadius: "0.3vw",
        transition: "all 0.5s ease",
        marginTop: "0.78vw",
        "&:hover": {
            boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
            backgroundPosition: "0 0 !important",
            marginTop: "0.7vw",
            transition: "all 0.5s ease",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.5vw",
            padding: "1.5vw 2vw",
            marginTop: "4vw",
        },
    }
}))
function SimpleDownload({appData, PdfAction, DiplomaPdfAction}) {
    const navigate = useNavigate()
    const location = useLocation();
    const pathName = location.pathname
    const [pageURL, setPageURL] = useState(null)
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    useEffect(() => {
        switch (pathName) {
            case "/referencef26":
                setPageURL('f26')
                break;
            case "/offerdownload":
                setPageURL('offer')
                break;
            case "/contract":
                setPageURL('shartnoma')
                break;
            case "/referencef28":
                setPageURL('f28')
                break;
            case "/reference":
                setPageURL('malumotnoma')
                break;
            case "/diploma":
                    setPageURL('diploma')
                    break;
            case "/transcript":
                    setPageURL('transcript')
                    break;
            default:
                setPageURL(null)
                break;
        }
    })
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        (pageURL && pageURL!=="diploma") && PdfAction(pageURL, enqueueSnackbar)
        pageURL=="diploma" && DiplomaPdfAction(pageURL, enqueueSnackbar)
    }, [pageURL])
    const downloadContract = () =>{
        const downloadUrl = appData.downloadFileURL
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${pageURL}.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        navigate('/');
        const message = 'Thank you for using!'
        enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right', }})
    }
    const downloadContract1 = () =>{
        if (appData.downloadFileURL) {
            saveAs( appData.downloadFileURL, 'file_collage')
            navigate('/');
            const message = 'Thank you for using!'
            enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right', }})
        }else{
            const message = t('snackbarDiplomeText');
            enqueueSnackbar(message, { variant: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right', }});
        }
    }
    return (
        appData.loading ? (
            <Loader/>
        ):
        (
            <Grid container item xs={12} direction="column" justify="center" alignItems="center" className={classes.outerGrid} >
                <Grid container item sm={3} xs={9} direction="column" justify="flex-start" alignItems="center" className={classes.grid} >
                    <p className={classes.outputText} >{t('contractDownload.boldText')}</p>
                    <Button className={classes.submitButton} variant="contained" color="primary" onClick={()=>{ pageURL && pageURL!=="diploma" ? downloadContract() : downloadContract1() }}> {t('contractDownload.button')} </Button>
                </Grid>
            </Grid>
        )
        
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        PdfAction: (pageURL, enqueueSnackbar) => dispatch(PdfAction(pageURL, enqueueSnackbar)),
        DiplomaPdfAction: (pageURL, enqueueSnackbar) => dispatch(DiplomaPdfAction(pageURL, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SimpleDownload)