export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const LOGIN_USERS_REQUEST = 'LOGIN_USERS_REQUEST'
export const LOGIN_USERS_SUCCESS = 'LOGIN_USERS_SUCCESS'
export const LOGIN_USERS_FAILURE = 'LOGIN_USERS_FAILURE'
export const LOGOUT_USERS_SUCCESS = 'LOGOUT_USERS_SUCCESS'
export const PDF_USERS_REQUEST = 'PDF_USERS_REQUEST'
export const PDF_USERS_SUCCESS = 'PDF_USERS_SUCCESS'
export const PDF_USERS_FAILURE = 'PDF_USERS_FAILURE'
export const DIPLOMAPDF_USERS_REQUEST = 'DIPLOMAPDF_USERS_REQUEST'
export const DIPLOMAPDF_USERS_SUCCESS = 'DIPLOMAPDF_USERS_SUCCESS'
export const DIPLOMAPDF_USERS_FAILURE = 'DIPLOMAPDF_USERS_FAILURE'
export const COMPLAINT_USERS_REQUEST = 'COMPLAINT_USERS_REQUEST'
export const COMPLAINT_USERS_SUCCESS = 'COMPLAINT_USERS_SUCCESS'
export const COMPLAINT_USERS_FAILURE = 'COMPLAINT_USERS_FAILURE'
export const CONTRACT_TYPE_USERS_REQUEST = 'CONTRACT_TYPE_USERS_REQUEST'
export const CONTRACT_TYPE_USERS_SUCCESS = 'CONTRACT_TYPE_USERS_SUCCESS'
export const CONTRACT_TYPE_USERS_FAILURE = 'CONTRACT_TYPE_USERS_FAILURE'
export const PROFILE_USERS_REQUEST = 'PROFILE_USERS_REQUEST'
export const PROFILE_USERS_SUCCESS = 'PROFILE_USERS_SUCCESS'
export const PROFILE_USERS_FAILURE = 'PROFILE_USERS_FAILURE'
export const DEBT_USERS_REQUEST = 'DEBT_USERS_REQUEST'
export const DEBT_USERS_SUCCESS = 'DEBT_USERS_SUCCESS'
export const DEBT_USERS_FAILURE = 'DEBT_USERS_FAILURE'
export const PROFILE_USERS_IMAGEADD_REQUEST = 'PROFILE_USERS_IMAGEADD_REQUEST'
export const PROFILE_USERS_IMAGEADD_SUCCESS = 'PROFILE_USERS_IMAGEADD_SUCCESS'
export const PROFILE_USERS_IMAGEADD_FAILURE = 'PROFILE_USERS_IMAGEADD_FAILURE'
export const ORG_NAME_REQUEST = 'ORG_NAME_REQUEST'
export const ORG_NAME_SUCCESS = 'ORG_NAME_SUCCESS'
export const ORG_NAME_FAILURE = 'ORG_NAME_FAILURE'
export const PARENTAPPEAL_USERS_REQUEST = 'PARENTAPPEAL_USERS_REQUEST'
export const PARENTAPPEAL_USERS_SUCCESS = 'PARENTAPPEAL_USERS_SUCCESS'
export const PARENTAPPEAL_USERS_FAILURE = 'PARENTAPPEAL_USERS_FAILURE'