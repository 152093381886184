import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./Home.css";
import WelcomePage from "./WelcomePage/WelcomePage";
import InteractiveServices from "./InteractiveServices/InteractiveServices";
import Information from "./Information/Information";
import JointPrograms from "./JointPrograms/JointPrograms";
import FacultiesDepartments from "./FacultiesDepartments/FacultiesDepartments";
import AboutPage from "./AboutPage/AboutPage";
import ContactButton from "./ContactButton/ContactButton";
import UsefulLinks from "./UsefulLinks/UsefulLinks";
import ContactButton1 from "./ContactButton/ContactButton1";
import { connect } from "react-redux";
const useStyles = makeStyles((theme) => ({
  home4Line: {
    bottom: 0,
    left: 0,
    width: "100%",
    overflow: "hidden",
    lineHeight: 0,
    transform: "rotate(180deg)",
    marginBottom: "-0.2vw",
  },
  home4LineSvg: {
    position: "relative",
    display: "block",
    width: "calc(100% + 0.06vw)",
    height: "8.2vw",
    transform: "rotateY(180deg)",
  },
}));
function Home({ appData }) {
  const classes = useStyles();

  //   useEffect(() => {
  //     // const socket = new WebSocket("ws://10.10.115.102:8003/ws/chat/nimadir", {
  //     //   headers: {
  //     //     Authorization: `Bearer ${appData?.user?.access}`,
  //     //   },
  //     // });
  //     // fc10989f-afe8-4df0-abf6-92b376c89a81
  //     const socket = new WebSocket(`ws://registr.tsue.uz/ws/chat/room_?${appData?.user?.id}`);
  //     socket.onopen = () => {
  //         console.log("connected")
  //         // test()
  //     }
  //     socket.onerror = (error) => console.log('ERROR', error)
  //     socket.close()
  //     socket.onclose = () => console.log('Close')
  //     socket.onmessage = (message) => {
  //       console.log("message: ", message)
  //       const data = JSON.parse(message?.data)
  //       console.log(data)
  //     }
  //   }, [appData]);

  return (
    <>
      <WelcomePage />
      <InteractiveServices />
      <Information />
      <JointPrograms />
      <div className={classes.home4Line}>
        <svg
          className={classes.home4LineSvg}
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
            className="shape-fill"
            style={{ fill: "#ECF9FD" }}
          ></path>
        </svg>
      </div>
      <FacultiesDepartments />
      <UsefulLinks />
      <AboutPage />
      <ContactButton1 />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    appData: state.app,
  };
};

export default connect(mapStateToProps)(Home);
// export default Home
