import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
};

export default function DescModal({ values, callback }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const keys = Object.keys(values.desc);

  return (
    <div>
      <div
        onClick={handleOpen}
        className="w-full h-full absolute top-0 left-0"
      ></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IoCloseOutline
            className="absolute top-0 right-0 text-4xl cursor-pointer"
            onClick={handleClose}
          />
          <table className="w-full">
            <tbody>
              {values &&
                keys.map((item) => (
                  <tr key={item}>
                    <td className="min-w-[200px] px-2">
                      {values.desc[item].title}
                    </td>
                    <td>
                      <label
                        htmlFor={item.title}
                        className="absolute top-0 left-0 h-full"
                      ></label>
                      <input
                        onFocus={(e) => {
                          if (e.target.value == 0) {
                            e.target.value = "";
                          }
                        }}
                        onBlur={(e) => {
                          if (e.target.value === "") e.target.value = 0;
                        }}
                        onChange={(e) => {
                          const data = { ...values.desc };
                          data[item] = {
                            title: values.desc[item].title,
                            value: e.target.value,
                          };
                          let allSum = 0;
                          for (let key in data) {
                            if (key !== "noGraf" && key !== "prac") {
                              allSum += Number(data[key].value);
                            }
                          }
                          callback({
                            ...values,
                            desc: { ...data },
                            out: allSum,
                          });
                        }}
                        type="number"
                        id={item.title}
                        value={values.desc[item].value}
                        className="border-none"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
}
