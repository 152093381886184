import axios from "axios";
import {
  COMPLAINT_USERS_REQUEST,
  COMPLAINT_USERS_SUCCESS,
  COMPLAINT_USERS_FAILURE,
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
  LOGOUT_USERS_SUCCESS,
  PDF_USERS_REQUEST,
  PDF_USERS_SUCCESS,
  PDF_USERS_FAILURE,
  DIPLOMAPDF_USERS_REQUEST,
  DIPLOMAPDF_USERS_SUCCESS,
  DIPLOMAPDF_USERS_FAILURE,
  CONTRACT_TYPE_USERS_REQUEST,
  CONTRACT_TYPE_USERS_SUCCESS,
  CONTRACT_TYPE_USERS_FAILURE,
  ORG_NAME_REQUEST,
  ORG_NAME_SUCCESS,
  ORG_NAME_FAILURE,
  PROFILE_USERS_REQUEST,
  PROFILE_USERS_SUCCESS,
  PROFILE_USERS_FAILURE,
  DEBT_USERS_REQUEST,
  DEBT_USERS_SUCCESS,
  DEBT_USERS_FAILURE,
  PROFILE_USERS_IMAGEADD_REQUEST,
  PROFILE_USERS_IMAGEADD_SUCCESS,
  PROFILE_USERS_IMAGEADD_FAILURE,
  PARENTAPPEAL_USERS_REQUEST,
  PARENTAPPEAL_USERS_SUCCESS,
  PARENTAPPEAL_USERS_FAILURE
} from '../Types/appTypes'
const appState = {
  loading: false,
  complaint: "",
  isLoggedIn: false,
  downloadFileURL: null,
  downloadOfferURL: null,
  debt: "",
  user:  {

  },
  profile: {

  },
  errorMessage: ''
};
const initialState = {
  loading: false,
  users: [],
  errorMessage: ''
}
const AppState = () => {
  const app = localStorage.getItem("app");
  try {
    const appobj = JSON.parse(app);
    const { expired_at, access } = appobj.user;
    if (new Date(expired_at) > new Date()) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
      return appobj;
    }
    // localStorage.removeItem("app");
    return appState;
  } catch (error) {
    return appState;
  }
};
// getAppState()
const newAppState = AppState()
const reducer = (state = newAppState, action) => {
  switch (action.type) {
    case LOGIN_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        downloadFileURL: null,
        complaint: "",
        errorMessage: ''
      }
    case LOGIN_USERS_SUCCESS:
      const loginAppState = {
        loading: false,
        isLoggedIn: true,
        profile: {},
        downloadFileURL: null,
        downloadOfferURL: null,
        complaint: "",
        user: action.payload,
        errorMessage: '',
        debt: ""
      };
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${action.payload.access}`;
      localStorage.setItem("app", JSON.stringify(loginAppState));
      return loginAppState
    case LOGIN_USERS_FAILURE:
      return appState
      case COMPLAINT_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        complaint: "",
        errorMessage: ''
      }
    case COMPLAINT_USERS_SUCCESS:
      const complaintAppState = {
        ...state,
        loading: false,
        complaint: action.payload,
        errorMessage: ''
      };
      localStorage.setItem("app", JSON.stringify(complaintAppState));
      return complaintAppState
    case COMPLAINT_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        complaint: "",
        error: action.payload
      }
      case PARENTAPPEAL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      }
    case PARENTAPPEAL_USERS_SUCCESS:
      const ParentAppealAppState = {
        ...state,
        loading: false,
        errorMessage: ''
      };
      localStorage.setItem("app", JSON.stringify(ParentAppealAppState));
      return ParentAppealAppState
    case PARENTAPPEAL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case LOGOUT_USERS_SUCCESS:
      localStorage.removeItem("app");
      return appState
    case PDF_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        downloadFileURL: null,
        errorMessage: ''
      }
    case PDF_USERS_SUCCESS:
      const file = window.URL.createObjectURL(new Blob([action.payload]));
      const contractAppState = {
        ...state,
        loading: false,
        downloadFileURL: file,
        errorMessage: ''
      };
      localStorage.setItem("app", JSON.stringify(contractAppState));
      return contractAppState
    case PDF_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        downloadFileURL: null,
        errorMessage: action.payload
      }
    case DIPLOMAPDF_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        downloadFileURL: null,
        errorMessage: ''
      }
    case DIPLOMAPDF_USERS_SUCCESS:
      const diplomaAppState = {
        ...state,
        loading: false,
        downloadFileURL: action.payload,
        errorMessage: ''
      };
      localStorage.setItem("app", JSON.stringify(diplomaAppState));
      return diplomaAppState
    case DIPLOMAPDF_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        downloadFileURL: null,
        errorMessage: action.payload
      }
      case CONTRACT_TYPE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CONTRACT_TYPE_USERS_SUCCESS:
      state.user.payment_type = action.payload
      localStorage.setItem("app", JSON.stringify(state));
      return state
    case CONTRACT_TYPE_USERS_FAILURE :
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
      case ORG_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessage: ''
      }
    case ORG_NAME_SUCCESS:
      const offer = window.URL.createObjectURL(new Blob([action.payload]));
      const offeractAppState = {
        ...state,
        loading: false,
        downloadOfferURL: offer,
        errorMessage: ''
      };
      localStorage.setItem("app", JSON.stringify(offeractAppState));
      return offeractAppState
    case ORG_NAME_FAILURE :
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
      case PROFILE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROFILE_USERS_SUCCESS:
      const profileAppState = {
        ...state,
        loading: false,
        profile: action.payload[0]
      };
      localStorage.setItem("app", JSON.stringify(profileAppState));
      return profileAppState
    case PROFILE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
      case DEBT_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case DEBT_USERS_SUCCESS:
      const debtAppState = {
        ...state,
        loading: false,
        debt: action.payload
      };
      localStorage.setItem("app", JSON.stringify(debtAppState));
      return debtAppState
    case DEBT_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
      case PROFILE_USERS_IMAGEADD_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case PROFILE_USERS_IMAGEADD_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case PROFILE_USERS_IMAGEADD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      }
    default: return state
  }
}

export default reducer
