import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next'
import { IoMdFunnel } from "react-icons/io"
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    home5OuterGrid: {
        paddingTop: "3.67vw",
        paddingBottom: "3.67vw",
        backgroundColor: "#ECF9FD",
    },
    home5OuterGridTitle: {
        fontSize: "2vw", 
        fontFamily: "inherit", 
        margin: "1vw 0 3.4vw 0", 
        lineHeight: "1.42vw", 
        color: "#404040", 
        paddingBottom: "3.67vw",
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            fontSize: "6vw", 
            lineHeight: 1.5, 
        },
    },
    home5ServiceGridx: {
        padding: "0 1.57vw 1.57vw 0",
        [theme.breakpoints.down('xs')]: {
            padding: "0 3vw 6vw 3vw",
        },
    },
    home5ServiceGridx1: {
        padding: "0 0 1.57vw 0",
        [theme.breakpoints.down('xs')]: {
            padding: "0 3vw 6vw 3vw",
        },
    },
    home5ServiceGridxCon: {
        backgroundColor: "white",
        padding: "0 2vw",
        margin: 0,
        width: "100%",
        height: "12vw",
        display: "flex",
        cursor: "pointer",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        border: "1px solid white",
        color: "#404040",
        textTransform: "none",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
        transition: "all 0.5s ease",
        "&:hover": {
            transition: "all 0.5s ease",
            backgroundColor: "#2277B3",
            color: "white",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            "& $home5ServiceGridxConIcon": {
                color: "white"
            }
        },
        [theme.breakpoints.down('xs')]: {
            height: "38vw",
        },
    },
    home5ServiceGridxCon1: {
        marginTop: "2.57vw",
        width: "100%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            marginTop: "5vw",
        },
    },
    home5ServiceGridxConIcon: {
        fontSize: "1.84vw",
        color: "#0F5E96",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10vw",
        },
    },
    home5ServiceGridxConTitle: {
        fontSize: "1vw", 
        fontWeight: 400,
        margin: "2vw 0 0.52vw 0",
        lineHeight: 1.2,
        color: "inherit",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.5vw",
            marginTop: "5vw",
            padding: "0 3vw"
        },
    }
}))

function FacultiesDepartments() {
    const classes = useStyles();
    const {t}=useTranslation();
    const navigate = useNavigate();
    const handleButtonClick = (pageURL) => {
        if (pageURL.length<15) {
            navigate(pageURL);
            window.scrollTo(0, 0);
        }else{
            window.location.href=pageURL
        }
    };
    return (
        <Grid id="Faculties&departments" container direction="column" justifyContent="center" alignItems="center" className={classes.home5OuterGrid} >
            <Grid container item sm={8} xs={12} direction="column" justifyContent="center" alignItems="center" >
                <p className={classes.home5OuterGridTitle} >{t('home5.boldText')}</p>
                {/* <p className={classes.home5OuterGridDesc}>{t('home5.lightText')}</p> */}
                <Grid container item xs={12} direction="row" justifyContent="center" alignItems="flex-start" >
                {
                    t('home5.cards', {returnObjects: true}).map((card, index) => {
                        const { cardTitle, pageURL } = card;
                        return(
                            <Grid key={index} container item sm={4} xs={6} direction="column" justifyContent="center" alignItems="center" className={(index===2 || index===5 || index===8) ? classes.home5ServiceGridx1 : classes.home5ServiceGridx}  >
                                <Button className={classes.home5ServiceGridxCon} onClick={()=> handleButtonClick(pageURL)} >
                                    <div className={classes.home5ServiceGridxCon1} >
                                        <IoMdFunnel className={classes.home5ServiceGridxConIcon}/>
                                        <p className={classes.home5ServiceGridxConTitle}>{cardTitle}</p>
                                    </div>
                                </Button>
                            </Grid>
                        )
                    })
                }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FacultiesDepartments
