import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { LoginAction } from "../../redux";
import { FaUser } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import IconButton from "@material-ui/core/IconButton";
import loginleft from "../media/shukur2.png";
import indexbg from "../media/indexbg.svg";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "100vh",
    backgroundColor: "white",
    backgroundImage: `url(${indexbg})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-100% 20%",
    overflow: "visible",
    paddingTop: "10vw",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "auto 100% ",
      backgroundPosition: "center",
      paddingTop: "35vw",
    },
  },
  innerGrid: {
    boxShadow: "0.26vw 0.78vw 0.62vw 0.26vw rgb(14 36 129 / 8%)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  innerGridLeft: {
    width: "100%",
    height: "28vw",
    backgroundColor: "#1E3B83",
    backgroundImage: `url(${loginleft})`,
    backgroundSize: "100% auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 110%",
    overflow: "visible",
    [theme.breakpoints.down("sm")]: {
      height: "30vw",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "auto 125% ",
      backgroundPosition: "50% 2%",
      height: "30vw",
    },
  },
  innerGridRight: {
    width: "100%",
    height: "28vw",
    padding: "2vw 1.57vw",
    backgroundColor: "white ",
    [theme.breakpoints.down("sm")]: {
      height: "30vw",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      padding: "5vw",
      alignItems: "center",
    },
  },
  innerGridRightTitle: {
    marginBottom: 0,
    marginTop: 0,
    fontWeight: "bold",
    fontSize: "1.5vw",
    color: "#215FA3",
    [theme.breakpoints.down("xs")]: {
      fontSize: "7vw",
    },
  },
  innerGridRightDesc: {
    width: "70%",
    fontSize: "0.78vw",
    fontFamily: "inherit",
    fontWeight: 200,
    lineHeight: 1.3,
    marginLeft: 0,
    color: "#929292",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: "3.5vw",
    },
  },
  innerGridRightInputDiv: {
    marginTop: "1vw",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  innerGridRightInputDivIcon: {
    color: "#ADADAD",
    marginRight: "0.7vw",
    fontSize: "1vw",
    [theme.breakpoints.down("xs")]: {
      marginRight: "2.5vw",
      marginTop: "3vw",
      fontSize: "4.5vw",
      padding: "1vw",
    },
  },
  innerGridRightInputDivDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  innerGridRightInputDivDivLabel: {
    color: "#ADADAD",
    fontSize: "0.65vw",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.8vw",
    },
  },
  innerGridRightInputDivDivInput: {
    width: "12vw",
    padding: "0.5vw 1vw",
    boxSizing: "border-box",
    margin: "0.5vw 0",
    fontSize: "0.9vw",
    border: "none",
    boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)",
    outline: "none",
    borderRadius: "1vw",
    [theme.breakpoints.down("xs")]: {
      width: "55vw",
      padding: "2vw 2.5vw",
      margin: "2vw 0",
      fontSize: "3.5vw",
      boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)",
      borderRadius: "2.5vw",
    },
  },
  innerGridRightInputDivDivInputError: {
    color: "red",
    fontSize: "0.65vw",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2vw",
    },
  },
  submitButton: {
    fontSize: "0.73vw",
    lineHeight: 1.4,
    padding: "0.52vw 1.3vw",
    letterSpacing: "0.1vw",
    color: "white",
    backgroundImage:
      "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
    backgroundPositionX: "100%",
    backgroundPositionY: 0,
    backgroundSize: "200%",
    backgroundRepeat: "initial",
    backgroundAttachment: "initial",
    backgroundOrigin: "initial",
    backgroundClip: "initial",
    backgroundColor: "initial",
    boxShadow: "0 0.4vw 0.63vw 0 rgb(14 36 129 / 8%)",
    borderRadius: "0.3vw",
    transition: "all 0.5s ease",
    marginRight: "12%",
    marginTop: "0.78vw",
    "&:hover": {
      boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
      backgroundPosition: "0 0 !important",
      marginTop: "0.7vw",
      transition: "all 0.5s ease",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "3.5vw",
      padding: "2vw 3vw",
      borderRadius: "1vw",
      margin: "4vw 0",
    },
  },
}));
function Login({ appData, LoginAction }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loginState, setLoginState] = useState({
    passport: "",
    password: "",
  });
  const [inputType, setInputType] = useState(false);
  const [userNameError, setUserNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();
  const checkErrors = () => {
    if (loginState.passport) {
      if (loginState.passport.length < 8) {
        setUserNameError("User name should be 8 letters");
        setConfirm(false);
      } else {
        setUserNameError("");
        setConfirm(true);
      }
    } else {
      setUserNameError("Enter your user name");
      setConfirm(false);
    }
    if (loginState.password) {
      if (loginState.password.length < 8) {
        setPasswordError("Passwor should be 8 letters");
        setConfirm(false);
      } else {
        setPasswordError("");
        setConfirm(true);
      }
    } else {
      setPasswordError("Enter your password");
      setConfirm(false);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  // appData.isLoggedIn && enqueueSnackbar('This is a success message!', {   variant: 'success', } );
  const requestSend = (loginState, navigate, enqueueSnackbar) => {
    LoginAction(loginState, navigate, enqueueSnackbar);
    setConfirm(!confirm);
    setLoginState({ passport: "", password: "" });
  };
  const requestConfirm = () => {
    checkErrors();
  };
  const passwordIconClick = () => {
    setInputType(!inputType);
  };
  return appData.loading ? (
    <Loader />
  ) : (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.grid}
    >
      <Grid
        container
        item
        sm={6}
        xs={11}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.innerGrid}
      >
        <Grid
          container
          item
          sm={6}
          xs={12}
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.innerGridLeft}
        ></Grid>
        <Grid
          container
          item
          sm={6}
          xs={12}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.innerGridRight}
        >
          <form onSubmit={(e) => e.preventDefault()}>
            <p className={classes.innerGridRightTitle}>{t("login.boldText")}</p>
            <p className={classes.innerGridRightDesc}>{t("login.lightText")}</p>
            <div className={classes.innerGridRightInputDiv}>
              <IconButton className={classes.innerGridRightInputDivIcon}>
                <FaUser />
              </IconButton>
              <div className={classes.innerGridRightInputDivDiv}>
                <label
                  for="passport"
                  className={classes.innerGridRightInputDivDivLabel}
                >
                  Passport*:{" "}
                </label>
                <input
                  type="text"
                  id="passport"
                  name="passport"
                  placeholder="Ex: AB7654321"
                  // maxLength="9"
                  className={classes.innerGridRightInputDivDivInput}
                  onChange={(event) => {
                    const passport = event.target.value;
                    setLoginState({ ...loginState, ...{ passport } });
                  }}
                />
                <span className={classes.innerGridRightInputDivDivInputError}>
                  {userNameError}
                </span>
              </div>
            </div>
            <div className={classes.innerGridRightInputDiv}>
              {inputType ? (
                <IconButton
                  onClick={() => passwordIconClick()}
                  className={classes.innerGridRightInputDivIcon}
                >
                  <AiFillEye />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => passwordIconClick()}
                  className={classes.innerGridRightInputDivIcon}
                >
                  <AiFillEyeInvisible />
                </IconButton>
              )}
              <div className={classes.innerGridRightInputDivDiv}>
                <label
                  for="password"
                  className={classes.innerGridRightInputDivDivLabel}
                >
                  Password*:{" "}
                </label>
                <input
                  type={inputType ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Ex: 2001-02-13"
                  // maxLength="10"
                  className={classes.innerGridRightInputDivDivInput}
                  onChange={(event) => {
                    const password = event.target.value;
                    setLoginState({ ...loginState, ...{ password } });
                  }}
                />
                {/* <span className={classes.innerGridRightInputDivDivInputError} >{passwordError}</span> */}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="submit"
                className={classes.submitButton}
                variant="contained"
                color="inherit"
                onClick={(e) =>
                  requestSend(loginState, navigate, enqueueSnackbar)
                }
              >
                {" "}
                {t("login.button")}{" "}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    appData: state.app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    LoginAction: (loginState, navigate, enqueueSnackbar) =>
      dispatch(LoginAction(loginState, navigate, enqueueSnackbar)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
