import React from 'react'
import {useTranslation} from 'react-i18next'

function Dormitory() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide mb-3'>
                {t('dormitory.name')}
            </p>
            <p className=' text-center xl:text-lg text-[#263d8a] sm:text-base text-sm font-semibold tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('dormitory.part1')}
            </p>
            <p className='xl:text-lg text-[#263d8a] sm:text-base text-sm font-semibold tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('dormitory.part2')}
            </p>
            <ul className='list-disc pl-6 pr-3 sm:text-base text-sm tracking-normal'>
                {
                    t('dormitory.part3', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-3' >
                {t('dormitory.part5')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-3' >
                {t('dormitory.part6')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-3' >
                {t('dormitory.part7')}
            </p>
            <ul className='list-inside text-[#1cb5e0] pl-6 pr-3 sm:text-base text-sm italic tracking-normal'>
                {
                    t('dormitory.part8', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                - {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-3' >
                {t('dormitory.part9')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-normal sm:mb-3 mb-1 lg:px-0 px-3 mt-5 italic' >
                <span className='text-[#263d8a] font-semibold' >{t('dormitory.part10')}</span> {t('dormitory.part11')}
            </p>
            <ul className='list-inside pl-6 pr-3 sm:text-base text-sm italic tracking-normal'>
                {
                    t('dormitory.part12', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                - {text}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
  )
}

export default Dormitory