import axios from "axios";
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  LOGIN_USERS_REQUEST,
  LOGIN_USERS_SUCCESS,
  LOGIN_USERS_FAILURE,
  LOGOUT_USERS_SUCCESS,
  PDF_USERS_REQUEST,
  PDF_USERS_SUCCESS,
  PDF_USERS_FAILURE,
  DIPLOMAPDF_USERS_REQUEST,
  DIPLOMAPDF_USERS_SUCCESS,
  DIPLOMAPDF_USERS_FAILURE,
  COMPLAINT_USERS_REQUEST,
  COMPLAINT_USERS_SUCCESS,
  COMPLAINT_USERS_FAILURE,
  CONTRACT_TYPE_USERS_REQUEST,
  CONTRACT_TYPE_USERS_SUCCESS,
  CONTRACT_TYPE_USERS_FAILURE,
  ORG_NAME_REQUEST,
  ORG_NAME_SUCCESS,
  ORG_NAME_FAILURE,
  PROFILE_USERS_REQUEST,
  PROFILE_USERS_SUCCESS,
  PROFILE_USERS_FAILURE,
  DEBT_USERS_REQUEST,
  DEBT_USERS_SUCCESS,
  DEBT_USERS_FAILURE,
  PROFILE_USERS_IMAGEADD_REQUEST,
  PROFILE_USERS_IMAGEADD_SUCCESS,
  PROFILE_USERS_IMAGEADD_FAILURE,
  PARENTAPPEAL_USERS_REQUEST,
  PARENTAPPEAL_USERS_SUCCESS,
  PARENTAPPEAL_USERS_FAILURE,
} from "../Types/appTypes";

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch(fetchUsersRequest());
    axios
      .get("/users")
      .then((response) => {
        // response.data is the users
        const users = response.data;
        dispatch(fetchUsersSuccess(users));
      })
      .catch((error) => {
        // error.message is the error message
        dispatch(fetchUsersFailure(error.message));
      });
  };
};

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

export const fetchUsersSuccess = (users) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};

export const LoginAction = (loginState, navigate, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchLoginRequest());
    axios
      .post("/api/login/v2/", loginState)
      .then((response) => {
        const users = response.data;
        dispatch(fetchLoginSuccess(users));
        navigate("/");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        dispatch(fetchLoginFailure(error.response?.data?.detail));
        enqueueSnackbar(error.response?.data?.detail, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};
export const fetchLoginRequest = () => {
  return {
    type: LOGIN_USERS_REQUEST,
  };
};

export const fetchLoginSuccess = (users) => {
  return {
    type: LOGIN_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchLoginFailure = (error) => {
  return {
    type: LOGIN_USERS_FAILURE,
    payload: error,
  };
};
// LOGOUT
export const LogoutAction = (navigate, enqueueSnackbar) => {
  navigate("/");
  const message = "The operation was completed successfully!";
  enqueueSnackbar(message, {
    variant: "success",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
    // TransitionComponent: Slide,
  });
  return (dispatch) => {
    dispatch(fetchLogoutSuccess());
  };
};

export const fetchLogoutSuccess = () => {
  return {
    type: LOGOUT_USERS_SUCCESS,
  };
};
// PDF ACTION
export const PdfAction = (pageURL, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchPdfRequest());
    axios
      .get(`/api/v1/${pageURL}/`, { responseType: "blob" })
      .then((res) => {
        const data = res.data;
        dispatch(fetchPdfSuccess(data));
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchPdfFailure(error));
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};
export const fetchPdfRequest = () => {
  return {
    type: PDF_USERS_REQUEST,
  };
};

export const fetchPdfSuccess = (users) => {
  return {
    type: PDF_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchPdfFailure = (error) => {
  return {
    type: PDF_USERS_FAILURE,
    payload: error,
  };
};

// DiplomPDF ACTION
export const DiplomaPdfAction = (pageURL, enqueueSnackbar, errorText) => {
  return (dispatch) => {
    dispatch(fetchDiplomaPdfRequest());
    axios
      .get(`/api2/v2/${pageURL}/`)
      .then((res) => {
        if (res.data[0]) {
          const data = res.data[0].file_collage;
          dispatch(fetchDiplomaPdfSuccess(data));
          const message = "The operation was completed successfully!";
          enqueueSnackbar(message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        } else {
          dispatch(fetchDiplomaPdfFailure(errorText));
        }
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchDiplomaPdfFailure(error));
        // enqueueSnackbar(errorMsg, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right', }})
      });
  };
};
export const fetchDiplomaPdfRequest = () => {
  return {
    type: DIPLOMAPDF_USERS_REQUEST,
  };
};

export const fetchDiplomaPdfSuccess = (users) => {
  return {
    type: DIPLOMAPDF_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchDiplomaPdfFailure = (error) => {
  return {
    type: DIPLOMAPDF_USERS_FAILURE,
    payload: error,
  };
};

// COMPLAINT action
export const ComplaintAction = (
  Url,
  complaintState,
  navigate,
  enqueueSnackbar
) => {
  return (dispatch) => {
    dispatch(fetchComplaintRequest());
    axios
      .post(`/api2/v2/${Url}/`, complaintState)
      .then((response) => {
        const res = response.data;
        dispatch(fetchComplaintSuccess(res));
        navigate("/");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        dispatch(fetchPdfFailure(error.message));
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};

export const fetchComplaintRequest = () => {
  return {
    type: COMPLAINT_USERS_REQUEST,
  };
};

export const fetchComplaintSuccess = (users) => {
  return {
    type: COMPLAINT_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchComplaintFailure = (error) => {
  return {
    type: COMPLAINT_USERS_FAILURE,
    payload: error,
  };
};
// ParentAppeal
export const ParentAppealAction = (
  ParentAppealState,
  navigate,
  enqueueSnackbar
) => {
  return (dispatch) => {
    dispatch(fetchParentAppealRequest());
    axios
      .post('/api/v1/parent/appeal/create/', ParentAppealState)
      .then((response) => {
        const res = response.data;
        dispatch(fetchParentAppealSuccess(res));
        navigate("/");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        dispatch(fetchPdfFailure(error.message));
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};

export const fetchParentAppealRequest = () => {
  return {
    type: PARENTAPPEAL_USERS_REQUEST,
  };
};

export const fetchParentAppealSuccess = (users) => {
  return {
    type: PARENTAPPEAL_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchParentAppealFailure = (error) => {
  return {
    type: PARENTAPPEAL_USERS_FAILURE,
    payload: error,
  };
};
// CONTRACT_TYPE action
export const ContractTypeAction = (contractType, navigate, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchContractTypeRequest());
    axios
      .post("/api/v1/payment-type/", contractType)
      .then((response) => {
        const res = response.data;
        dispatch(fetchContractTypeSuccess(contractType.payment_type));
        navigate("/");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        dispatch(fetchContractTypeFailure(error.message));
        // dispatch(fetchPdfFailure(error.message))
        enqueueSnackbar(error.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};

export const fetchContractTypeRequest = () => {
  return {
    type: CONTRACT_TYPE_USERS_REQUEST,
  };
};

export const fetchContractTypeSuccess = (res) => {
  return {
    type: CONTRACT_TYPE_USERS_SUCCESS,
    payload: res,
  };
};

export const fetchContractTypeFailure = (error) => {
  return {
    type: CONTRACT_TYPE_USERS_FAILURE,
    payload: error,
  };
};
// offer name
export const OrgNameAction = (orgName, navigate, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchOrgNameRequest());
    axios
      .post("/api/v1/external_report/", orgName, { responseType: "blob" })
      .then((response) => {
        const res = response.data;
        dispatch(fetchOrgNameSuccess(res));
        // navigate("/offerdownload");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })

      // Done
    .catch(async (e) => {
      const errMSG = await e.response.data.text()
      const {error} = JSON.parse(errMSG)
      dispatch(fetchOrgNameFailure(error));
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
    });
  };
};

// offer name
export const OrgNameAction2 = (orgName, navigate, enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchOrgNameRequest());
    axios
      .post("/api/v1/external_online_report/", orgName, { responseType: "blob" })
      .then((response) => {
        const res = response.data;
        dispatch(fetchOrgNameSuccess(res));
        // navigate("/offerdownload");
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })

      // Done
    .catch(async (e) => {
      const errMSG = await e.response.data.text()
      const {error} = JSON.parse(errMSG)
      dispatch(fetchOrgNameFailure(error));
        enqueueSnackbar(error, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
    });
  };
};

export const fetchOrgNameRequest = () => {
  return {
    type: ORG_NAME_REQUEST,
  };
};

export const fetchOrgNameSuccess = (res) => {
  return {
    type: ORG_NAME_SUCCESS,
    payload: res,
  };
};

export const fetchOrgNameFailure = (error) => {
  return {
    type: ORG_NAME_FAILURE,
    payload: error,
  };
};
// PROFILE ACTION
export const ProfileAction = (enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchProfileRequest());
    axios
      .get("/api2/v2/studentprofile/")
      .then((res) => {
        const data = res.data;
        dispatch(fetchProfileSuccess(data));
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchProfileFailure(error));
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};
export const fetchProfileRequest = () => {
  return {
    type: PROFILE_USERS_REQUEST,
  };
};

export const fetchProfileSuccess = (info) => {
  return {
    type: PROFILE_USERS_SUCCESS,
    payload: info,
  };
};

export const fetchProfileFailure = (error) => {
  return {
    type: PROFILE_USERS_FAILURE,
    payload: error,
  };
};

// Debt ACTION
export const DebtAction = (enqueueSnackbar) => {
  return (dispatch) => {
    dispatch(fetchDebtRequest());
    axios
      .get("/api2/v2/student/debtor/info/")
      .then((res) => {
        const data = res.data[0];
        dispatch(fetchDebtSuccess(data));
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(fetchDebtFailure(error));
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};
export const fetchDebtRequest = () => {
  return {
    type: DEBT_USERS_REQUEST,
  };
};

export const fetchDebtSuccess = (debt) => {
  return {
    type: DEBT_USERS_SUCCESS,
    payload: debt,
  };
};

export const fetchDebtFailure = (error) => {
  return {
    type: DEBT_USERS_FAILURE,
    payload: error,
  };
};

// Profile Image
export const ProfileImageAddAction = (enqueueSnackbar, ImageData) => {
  return (dispatch) => {
    dispatch(fetchProfileImageAddRequest());
    axios
      .put("/api2/v2/update_image/", ImageData)
      .then((res) => {
        ProfileAction(enqueueSnackbar);
        const data = res.data;
        dispatch(fetchProfileImageAddSuccess(data));
        const message = "The operation was completed successfully!";
        enqueueSnackbar(message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      })
      .catch((error) => {
        ProfileAction(enqueueSnackbar);
        const errorMsg = error.message;
        dispatch(fetchProfileImageAddFailure(error));
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      });
  };
};
export const fetchProfileImageAddRequest = () => {
  return {
    type: PROFILE_USERS_IMAGEADD_REQUEST,
  };
};

export const fetchProfileImageAddSuccess = (info) => {
  return {
    type: PROFILE_USERS_IMAGEADD_SUCCESS,
    payload: info,
  };
};

export const fetchProfileImageAddFailure = (error) => {
  return {
    type: PROFILE_USERS_IMAGEADD_FAILURE,
    payload: error,
  };
};
