import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import { BsPerson, BsPhone } from "react-icons/bs"
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next'
import contactUsBack from '../../media/contactUsBack.png'
import audio from '../../media/pop.mp3'
import { MdWavingHand } from "react-icons/md"
import { RiChatSmileFill, RiCloseFill } from "react-icons/ri"
import { useNavigate } from "react-router-dom";
import { ComplaintAction } from '../../../redux';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux'
const useStyles = makeStyles((theme) => ({
    contactUsCard: {
        position: "fixed",
        width: "20vw",
        height: "36vw",
        bottom: "3.7vw",
        right: "1vw",
        borderRadius: "0.5vw",
        backgroundColor: "white",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 0.26vw 0.78vw",
        opacity: 1,
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            right: "2.5vw",
            width: "95vw",
            height: "90%",
            bottom: "2%",
            zIndex: 100,
            borderRadius: "2vw",
            overflowY: "scroll"
        },
    },
    contactUsCard1: {
        position: "fixed",
        width: "20vw",
        height: "36vw",
        bottom: "3.7vw",
        right: "-41vw",
        borderRadius: "0.5vw",
        backgroundColor: "white",
        overflow: "hidden",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 0.26vw 0.78vw",
        opacity: 0,
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            right: "-100vw",
            width: "95vw",
            height: "90%",
            bottom: "2%",
            zIndex: 100,
        },
    },
    contactUsCardTop: {
        position: "relative",
        height: "4vw",
        backgroundImage: `url(${contactUsBack})`,
        backgroundSize: " 100% auto",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0% 0%",
        backgroundColor: "#88BDB7",
        padding: "0.5vw 1vw",
        color: "white",
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            height: "20vw",
            padding: "3vw 3vw",
        },
    },
    contactUsCardTopTitle: {
        margin: 0,
        fontSize: "1.5vw",
        fontWeight: 300,
        display: "flex", 
        justifyContent: "flex-start",
        alignItems: "center",
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            fontSize: "6vw",
        },
    },
    contactUsCardTopIcon: {
        transform: "scale(-1, 1)",
        color: "#FFBE12",
        marginLeft: "1vw",
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "2vw",
        },
    },
    contactUsCardTopDesc: {
        fontSize: "0.8vw",
        lineHeight: 1.5,
        color: "rgba(255, 255, 255, 0.7)",
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3vw",
        },
    },
    contactUsCardBody: {
        backgroundColor: "white", 
        overflow: "hidden", 
        padding: "2vw 1.3vw 2.8vw",
        textAlign: "center",
        [theme.breakpoints.down('xs')]: {
            padding: "4vw 4vw",
        },
    },
    contactUsCardBodyBoldText: {
        fontSize: "1vw", 
        lineHeight: 1.5, 
        color: "#404040",
        [theme.breakpoints.down('xs')]: {
            fontSize: "4.5vw", 
        },
    },
    contactUsCardBodyLightText: {
        fontSize: "0.8vw", 
        lineHeight: 1.5,
         color: "#5c5c5c",
         [theme.breakpoints.down('xs')]: {
             fontSize: "4vw", 
         },
    },
    inputNew: {
        width: "12vw", 
        padding: "0.5vw 1vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.9vw", 
        border: "none", 
        boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
        outline: "none", 
        borderRadius: "1vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "4vw", 
            width: "59vw", 
            padding: "2vw 2vw", 
            borderRadius: "1vw",
        },
    },
    inputNewError: {
        color: "red",
        fontWeight: 400,
        fontSize: "0.5vw",
        marginLeft: "1vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "1.8vw",
        },
    },
    contactUsButton: {
        position: "fixed",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        bottom: "0.4vw",
        right: "0.4vw",
        width: "3.1vw",
        height: "3.1vw",
        borderRadius: "50%",
        cursor: "pointer",
        backgroundColor: "#8BC0BA",
        boxShadow: "rgb(0 0 0 / 10%) 0px 1px 6px, rgb(0 0 0 / 20%) 0px 2px 24px",
        transition: "all 1s ease",
        [theme.breakpoints.down('xs')]: {
            bottom: "3vw",
            right: "3vw",
            width: "10vw",
            height: "10vw",
            zIndex: 101
        },
    },
    contactUsButtonIcon: {
        color: "white",
        fontSize: "2vw",
        transition: "all 1s ease",
        opacity:1,
        [theme.breakpoints.down('xs')]: {
            fontSize: "7vw",
        },
    },
    contactUsSendButton: {
        fontSize: "0.73vw",
        marginTop: "0.5vw",
        marginLeft: "45%",
        lineHeight: 1.4,
        padding: "0.52vw 1.3vw",
        letterSpacing: "0.1vw",
        color: "white",
        backgroundImage: "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
        backgroundPositionX: "100%",
        backgroundPositionY: 0,
        backgroundSize: "200%",
        backgroundRepeat: "initial",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
        boxShadow: "0 0.42vw 0.63vw 0 rgb(14 36 129 / 8%)",
        borderRadius: "0.3vw",
        transition: "all 1s ease",
        "&:hover": {
            boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
            backgroundPosition: "0 0 !important"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "3vw",
            marginTop: "5vw",
            marginLeft: "45%",
            lineHeight: 1.4,
            padding: "2vw 3vw",
            borderRadius: "3vw",
        },
    },
    innerGridRightInputDiv: {
        marginTop: "1vw",
        width: "100%", 
        display: "flex", 
        justifyContent: "flex-start",  
        alignItems: "center",
    },
    innerGridRightInputDivIcon: {
        color: "#ADADAD", 
        marginRight: "0.7vw",
        fontSize: "1vw",
        [theme.breakpoints.down('xs')]: {
            marginRight: "2vw",
            marginTop: "10vw",
            fontSize: "5vw",
            padding: "0.5vw"
        },
    },
    innerGridRightInputDivDiv: {
        display: "flex", 
        justifyContent: "center", 
        alignItems: "flex-start", 
        flexDirection: "column",
        [theme.breakpoints.down('xs')]: {
            marginTop: "6vw",
        },
    },
    innerGridRightInputDivDivLabel: {
        color: "#ADADAD", 
        fontSize: "0.7vw",
        marginLeft: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.3vw", 
            margin: "1vw", 
        },
    },
    innerGridRightInputDivDivInput: {
        width: "14vw", 
        padding: "0.5vw 1vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.9vw", 
        border: "0.1vw solid #ADADAD", 
        boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
        outline: "none", 
        borderRadius: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            width: "50vw", 
            padding: "1.5vw 2vw", 
            margin: "1vw", 
            fontSize: "3vw", 
            boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
            borderRadius: "1.5vw",
        },
    },
    innerGridRightInputDivDivInputError: {
        color: "red",
        fontSize: "0.65vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2vw",
        },
    },
    innerGridRightInputDivDivInputText: {
        minHeight: "9vw",
        maxHeight: "9vw",
        minWidth: "17.5vw",
        maxWidth: "17.5vw",
        padding: "0.5vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.8vw",
        letterSpacing: "0.05vw", 
        border: "0.1vw solid #ADADAD", 
        boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
        outline: "none", 
        borderRadius: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "85vw",
            maxHeight: "85vw",
            minWidth: "85vw",
            maxWidth: "85vw",
            padding: "1.5vw", 
            margin: "1vw 0", 
            fontSize: "3vw", 
            boxShadow: "0 0 15px 4px rgba(0,0,0,0.06)", 
            borderRadius: "1.5vw",
        },
    },
}))

function ContactButton({appData, ComplaintAction}) {
    const [complaintState, setComplaintState] = useState({
        comment: "",
        phone: "",
        full_name	: "",
    });
    const [nameError, setNameError] = useState("Enter your name")
    const [phoneError, setPhoneError] = useState("Enter your phone number");
    const [text, setText] = useState('Enter your text')
    const [contactIcon, setContactIcon] = useState(false)
    const navigate = useNavigate();
    const {t}=useTranslation();
    const classes = useStyles();
    
    const iconClick = () => {
        new Audio(audio).play();
        setContactIcon(!contactIcon);
        setPhoneError("");
        setText("");
    }
    const hendleOnChange = (e) => {
        setComplaintState({...complaintState, [e.target.name]: e.target.value})

    }
    const checkErrors = () => {
        if (complaintState.full_name) {
            setNameError("");
        }else{
            setNameError("Enter your name");
        }
        if (complaintState.phone.replace(/\D/g, "")) {
            if (complaintState.phone.replace(/\D/g, "").length < 12) {
                setPhoneError("Numbers should be 12 digits");
            }else{
                setPhoneError("");
            }
        }else{
            setPhoneError("Enter your phone number");
        }
        if (complaintState.comment) {
            if (complaintState.comment.length < 51) {
                setText("Text must be more than 50 letters");
            }else{
                setText("");
            }
        }else{
            setText("Enter your text");
        }
    }
    const { enqueueSnackbar } = useSnackbar();
    const requestSend = (complaintState, navigate, enqueueSnackbar) => {
        ComplaintAction('appeal', complaintState, navigate, enqueueSnackbar); 
        setContactIcon(!contactIcon); 
        setComplaintState({comment: "", phone: "",full_name: ""})
        setNameError("Enter your name")
        setPhoneError("Enter your phone number")
        setText('Enter your text')
    }
    const requestConfirm = () => {
        checkErrors();
    }

    const isNumericInput = (event) => {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    };
    
    const isModifierKey = (event) => {
        const key = event.keyCode;
        return (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };
    
    const enforceFormat = (event) => {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!isNumericInput(event) && !isModifierKey(event)){
            event.preventDefault();
        }
    };
    
    const formatToPhone = (event) => {
        if(isModifierKey(event)) {return;}
        // I am lazy and don't like to type things more than once
        const target = event.target;
        const input = event.target.value.replace(/\D/g,'').substring(0,19); // First ten digits of input only
        const start = input.substring(0,3);
        const code = input.substring(3,5);
        const middle = input.substring(5,8);
        const pair1 = input.substring(8,10);
        const pair2 = input.substring(10,12);
    
        if(input.length > 10){target.value = `+${start}(${code})${middle}-${pair1}-${pair2}`;}
        else if(input.length > 8){target.value = `+${start}(${code})${middle}-${pair1}`;}
        else if(input.length > 5){target.value = `+${start}(${code})${middle}`;}
        else if(input.length > 3){target.value = `+${start}(${code}`;}
        else if(input.length > 0){target.value = `+${start}`;}
    };
    return (
        <>
            <div className={contactIcon ? classes.contactUsCard : classes.contactUsCard1}>
                <div className={classes.contactUsCardTop} >
                    <span className={classes.contactUsCardTopTitle}>Hi there <MdWavingHand className={classes.contactUsCardTopIcon} />
                    </span>
                    <span className={classes.contactUsCardTopDesc} >Ask us anything, we are here to help you build products without code </span>
                </div>
                <div className={classes.contactUsCardBody} >
                    <div className={classes.innerGridRightInputDiv} >
                        <IconButton  className={classes.innerGridRightInputDivIcon}><BsPerson /></IconButton> 
                        <div className={classes.innerGridRightInputDivDiv} >
                            <label for="full_name" className={classes.innerGridRightInputDivDivLabel} >{t('registrar.nameLable')}: </label>
                            <input value={complaintState.full_name} type="text" id="full_name" name="full_name" className={classes.innerGridRightInputDivDivInput} onChange={(e)=> hendleOnChange(e)} />
                            <span className={classes.innerGridRightInputDivDivInputError} >{nameError}</span>
                        </div>
                    </div>
                    <div className={classes.innerGridRightInputDiv} >
                        <IconButton  className={classes.innerGridRightInputDivIcon}><BsPhone /></IconButton> 
                        <div className={classes.innerGridRightInputDivDiv} >
                            <label for="phone" className={classes.innerGridRightInputDivDivLabel} >{t('registrar.phoneLable')}: </label>
                            <input value={complaintState.phone} type="tel" id="phone"  name="phone" onKeyUp={(e)=> {formatToPhone(e)}} onKeyDown={(e)=> {enforceFormat(e)}} className={classes.innerGridRightInputDivDivInput} maxLength="17"  onChange={(e)=> hendleOnChange(e)} />
                            <span className={classes.innerGridRightInputDivDivInputError} >{phoneError}</span>
                        </div>
                    </div>
                    <div className={classes.innerGridRightInputDivDiv} >
                        <label for="comment" className={classes.innerGridRightInputDivDivLabel} style={{marginTop: "1vw"}} >{t('registrar.messageLable')}: </label>
                        <textarea value={complaintState.comment} className={classes.innerGridRightInputDivDivInputText} onChange={(e)=> hendleOnChange(e)} name="comment" id="comment"/>
                        <span className={classes.innerGridRightInputDivDivInputError} >{text}</span>
                    </div> 
                    {(text==="" && phoneError==="" && nameError==="") ? 
                        <Button onClick={(e)=>requestSend(complaintState, navigate, enqueueSnackbar)} className={classes.contactUsSendButton} >{t('registrar.sendButton')}</Button>
                        :<Button onClick={(e)=>requestConfirm()} className={classes.contactUsSendButton} >{t('registrar.confirmButton')}</Button>
                        }
                </div>
            </div>
            <div className={classes.contactUsButton} onClick={()=>{iconClick(); }} >
                {contactIcon ? 
                <RiCloseFill className={classes.contactUsButtonIcon } />
                :<RiChatSmileFill className={classes.contactUsButtonIcon } />
                }
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ComplaintAction: (Url, complaintState, navigate, enqueueSnackbar) => dispatch(ComplaintAction(Url, complaintState, navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactButton)