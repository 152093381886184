import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import axios from "axios";
import { Provider } from "react-redux";
import store from './redux/store';
import '../src/i18n'

// axios.defaults.baseURL = "https://cors-anywhere.herokuapp.com/https://registr.tsue.uz";
axios.defaults.baseURL = "https://registr.tsue.uz";
// axios.defaults.baseURL = "http://10.10.115.210:8000"


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
