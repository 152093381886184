import { Field, Form, Formik, useFormikContext   } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import React, { useState } from "react";
import {useTranslation} from 'react-i18next'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { ParentAppealAction } from '../../redux';

const ParentAppeal = ({ParentAppealAction}) => {
  const [selectedOption, setSelectedOption] = useState("abiturent");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
  function SendApply(complaintState) {
    ParentAppealAction( complaintState, navigate, enqueueSnackbar);
  }
  const StudentTypeField = () => {
    const formik = useFormikContext();
    return (
      <Field
        as="select"
        name="student_type"
        id="student_type"
        placeholder="Tanlang"
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value);
          formik.setFieldValue("student_type", e.target.value); // Synchronize with Formik state
        }}
        className="w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] "
      >
        <option value="abiturent">{t('parentAppeal.student_type_option1')}</option>
        <option value="student">{t('parentAppeal.student_type_option2')}</option>
      </Field>
    );
  };

  return (
      <div className="w-full grid grid-cols-12 xl:my-36 my-28" >
        <div className="md:col-span-8 col-span-12 md:col-start-3 md:px-0 px-[4%]">
          <h1 className="text-2xl text-[#263d8a] font-bold ">
              {t('registrar.headerBoldText')}
          </h1>
          <div className="grid gap-[15px] my-[15px]">
            <div className="col-span-12">
              <Formik
                initialValues={
                  selectedOption === "abiturent" ?
                  {
                      parent_fullname: "",
                      student_fullname: "",
                      phone_number: "",
                      appeal: "",
                      student_type: selectedOption
                  } :
                  {
                      parent_fullname: "",
                      student_fullname: "",
                      phone_number: "",
                      group: "",
                      passport: "",
                      appeal: "",
                      student_type: selectedOption
                  }
              }
              validationSchema={Yup.object().shape(
                  selectedOption === "abiturent" ?
                  {
                      parent_fullname: Yup.string().required(t('parentAppeal.parent_fullname_error')),
                      student_fullname: Yup.string().required(t('parentAppeal.abiturentName_error')),
                      phone_number: Yup.string().required(t('parentAppeal.phone_error')),
                      appeal: Yup.string().required(t('parentAppeal.comment_error')),
                      student_type: Yup.string().required(t('parentAppeal.comment_error'))
                  }:
                  {
                      parent_fullname: Yup.string().required(t('parentAppeal.parent_fullname_error')),
                      student_fullname: Yup.string().required(t('parentAppeal.studentName_error')),
                      phone_number: Yup.string().required(t('parentAppeal.phone_error')),
                      group: Yup.string().required(t('parentAppeal.studentGroup_error')),
                      appeal: Yup.string().required(t('parentAppeal.comment_error')),
                      student_type: Yup.string().required(t('parentAppeal.comment_error'))
                  }
              )}
              onSubmit={(complaintState) => {
                  SendApply(complaintState);
                  complaintState.appeal = ""
                  complaintState.parent_fullname = ""
                  complaintState.phone_number = ""
                  complaintState.student_fullname = ""
                  complaintState.student_fullname = ""
                  complaintState.group = ""
                  complaintState.passport = ""
                  complaintState.student_type = ""
              }}
              >
                {({ errors, touched, handleBlur, handleChange }) => (
                  <Form className="grid grid-cols-12 gap-[15px]">
                    <div className="col-span-12 sm:col-span-6">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="parent_fullname" >
                        {t('parentAppeal.parent_fullname')}
                      </label>
                      <Field
                        name="parent_fullname"
                        type="text"
                        placeholder="F.I.SH"
                        className={
                          "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] " +
                          (errors.parent_fullname && touched.parent_fullname
                            ? "border-red-500"
                            : "")
                        }
                      />
                      {errors.parent_fullname && <span className="text-red-500 text-xs ml-2" >*{errors.parent_fullname}</span>}
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="student_type" >
                        {t('parentAppeal.student_type')}
                      </label>
                      <StudentTypeField />
                    </div>
                    <div className="col-span-12 sm:col-span-6">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="phone_number" >
                        {t('parentAppeal.phone')}
                      </label>
                      <Field name="phone_number" >
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            name="phone_number"
                            id="phone_number"
                            placeholder="(99)999-77-66"
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px]" +
                              (errors.phone_number && touched.phone_number
                                ? " border-red-500"
                                : "")
                            }
                          />
                        )}
                      </Field>
                      {errors.phone_number && <span className="text-red-500 text-xs ml-2" >*{errors.phone_number}</span>}
                    </div>
                    {
                        selectedOption === "abiturent" ? (
                            <div className="col-span-12 sm:col-span-6">
                                <label className="text-[#8C97AB] font-semibold" htmlFor="student_fullname" >
                                    {t('parentAppeal.abiturentName')}
                                </label>
                                <Field
                                    name="student_fullname"
                                    type="text"
                                    placeholder="F.I.SH"
                                    className={
                                    "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] " +
                                    (errors.student_fullname && touched.student_fullname
                                        ? "border-red-500"
                                        : "")
                                    }
                                />
                                {errors.student_fullname && <span className="text-red-500 text-xs ml-2" >*{errors.student_fullname}</span>}
                            </div>
                        )
                        :(
                            <>
                                <div className="col-span-12 sm:col-span-6">
                                    <label className="text-[#8C97AB] font-semibold" htmlFor="student_fullname" >
                                        {t('parentAppeal.studentName')}
                                    </label>
                                    <Field
                                        name="student_fullname"
                                        type="text"
                                        placeholder="F.I.SH"
                                        className={
                                        "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] " +
                                        (errors.student_fullname && touched.student_fullname
                                            ? "border-red-500"
                                            : "")
                                        }
                                    />
                                    {errors.student_fullname && <span className="text-red-500 text-xs ml-2" >*{errors.student_fullname}</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                    <label className="text-[#8C97AB] font-semibold" htmlFor="group" >
                                        {t('parentAppeal.studentGroup')}
                                    </label>
                                    <Field
                                        name="group"
                                        type="text"
                                        placeholder="IRB-75"
                                        className={
                                        "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] " +
                                        (errors.group && touched.group
                                            ? "border-red-500"
                                            : "")
                                        }
                                    />
                                    {errors.group && <span className="text-red-500 text-xs ml-2" >*{errors.group}</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-6">
                                    <label className="text-[#8C97AB] font-semibold" htmlFor="passport" >
                                        {t('parentAppeal.studentPassportNumber')}
                                    </label>
                                    <Field
                                        name="passport"
                                        type="text"
                                        placeholder="AA1234567"
                                        className={
                                        "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] "
                                        }
                                    />
                                </div>
                            </>
                        )
                    }
                    <div className="col-span-12">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="appeal" >
                      {t('parentAppeal.comment')}
                      </label>
                      <Field
                        name="appeal"
                        type="text"
                        rows="6"
                        as="textarea"
                        placeholder={t('parentAppeal.comment')}
                        className={
                          "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px]" +
                          (errors.appeal && touched.appeal
                            ? " border-red-500"
                            : "")
                        }
                      />
                      {errors.appeal && <span className="text-red-500 text-xs ml-2" >*{errors.appeal}</span>}
                    </div>
                    {/* submit */}
                    <div className="col-span-12 text-right">
                      <button
                        type="submit"
                        className="bg-[#1cb5e0] hover:bg-[#263d8a] text-white border mr-[15px] border-[#E2E5E9] py-[5px] px-[10px] rounded-sm transition"
                      >
                        {t('parentAppeal.sendButton')}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = state => {
  return {
    appData: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
      ParentAppealAction: (Url, complaintState, navigate, enqueueSnackbar) => dispatch(ParentAppealAction(Url, complaintState, navigate, enqueueSnackbar))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentAppeal)
