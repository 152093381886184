import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import lse from '../../media/isef.svg'
import turku from '../../media/turku.jpg'
import { makeStyles } from '@material-ui/core/styles';
import krems from '../../media/krems.png'
import ural from '../../media/ural.jpg'
import upi from '../../media/upi.png'
import mephi from '../../media/mephi.png'
import moscowAcademy from '../../media/moscowAcademy.jpeg'
import ufa from '../../media/ufa.jpg'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({

  home2OuterGrid: {
    paddingTop: "4.67vw", 
    paddingBottom: "3.67vw",
    [theme.breakpoints.down('xs')]: {
        paddingTop: "2vw",
        paddingBottom: "25vw",
    },
  },
  home2OuterGridTitle: {
      top: "-4vw",
      position: "relative",
      fontSize: "2vw", 
      fontFamily: "inherit", 
      lineHeight: "1.42vw", 
      color: "#404040", 
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
          fontSize: "6vw", 
          lineHeight: 1.5, 
      },
  },
  itemImageDiv: {
      width: "12vw", 
      display: "flex", 
      flexDirection: "row", 
      justifyContent: "center", 
      alignItems: "center", 
      margin: "auto", 
      height: "16vw",
      // backgroundColor: "blue",
      [theme.breakpoints.down('xs')]: {
        width: "26vw", 
        height: "35vw",
      },
    },
  carouselDiv: {
      width: "90vw", 
      height: "17vw", 
      margin: "auto",
      display: "flex", 
      flexDirection: "row", 
      justifyContent: "center", 
      alignItems: "center",
      // backgroundColor: "red",
      [theme.breakpoints.down('xs')]: {
        width: "100vw", 
        height: "36vw", 
      },
  }
}))

function JointPrograms() {
    const classes = useStyles();
    const {t}=useTranslation();
    const responsive = {
        0: {items: 3},
        600: { items: 3 },
        1024: { items: 4 },
      }
    const items = [
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://isef-lseuol.ac/en"><img width="100%"  alt="Text"  src={lse}/></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://www.tuas.fi/en/"><img width="100%"  alt="Text"  src={turku}/></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://www.fh-krems.ac.at/en/"><img width="100%"  alt="Text"  src={krems}/></a></div>,
        // <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://timeedu.uz/"><img width="100%"  alt="Text" style={{backgroundColor: "#394A71"}} src={timeedu}/></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://www.upi.edu/"><img width="100%"  alt="Text" style={{backgroundColor: "#DD0000"}} src={upi} /></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://www.usue.ru/"><img width="100%"  alt="Text"  src={ural}/></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://mosap.ru/"><img width="100%"  alt="Text"  src={moscowAcademy} /></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://mephi.ru/"><img width="100%"  alt="Text"  src={mephi} /></a></div>,
        <div className={classes.itemImageDiv} ><a style={{width: "100%"}} target="_blank" rel="noreferrer" href="https://rusoil.net/" ><img width="100%"  alt="Text"src={ufa} /></a></div>,
      ];
    return (
      <Grid id="JointPrograms" container direction="column" justifyContent="center" alignItems="center" className={classes.home2OuterGrid} >
        <p className={classes.home2OuterGridTitle} >{t('header.menuItems', {returnObjects: true})[2].menuTitle}</p>
        <div className={classes.carouselDiv} >
          <AliceCarousel
          items={items}
          responsive={responsive}
          autoPlay
          autoPlayControls
          autoPlayStrategy="none"
          autoPlayInterval={500}
          duration={2000}
          animationType="fadeout"
          infinite
          touchTracking={false}
          disableDotsControls
          disableButtonsControls
          buttonsDisabled
          dotsDisabled
          />
        </div>
      </Grid>
        
    )
}

export default JointPrograms
