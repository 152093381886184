import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import _uniqueId from "lodash/uniqueId";
import Loader from "../Loader/Loader.js";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment/moment.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Certificate = () => {
  const notify = (message) => toast.error(message);
  const [pageLoading, setPageLoading] = useState(true);
  const [errorIndexs, setErrorIndexs] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedGeneratedCertificates, setSelectedGeneratedCertificates] =
    useState([]);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [generatedCertificatesList, setGeneratedCertificatesList] = useState(
    {}
  );
  const [searchParams, setSearchParamas] = useState("");
  const [editCurrentCertificate, setEditCurrentCertificate] = useState({});
  const [editModeOn, setEditModeOn] = useState("");
  const [rectorDrop, setRectorDrop] = useState(false);
  const [rectorOrList, setRectorOrList] = useState([]);
  const [rectorOr, setRectorOr] = useState({
    id: null,
    full_name: "Tasdiqlovchi",
  });

  function getRectorOrList() {
    // axios.get('/api/v1/license/').then(res => setRectorOrList(res.data))
    axios
      .get("/api/v1/license/affirmative-person/")
      .then((res) => setRectorOrList(res.data));
  }

  useEffect(() => {
    getRectorOrList();
  }, []);

  function postCertificates(postData) {
    if (rectorOr.id == null) {
      toast("Tasdiqlovchini tanlang");
      return null;
    }
    setLoading(true);
    let data = [];
    if (postData === "excel") {
      data = selectedCertificates.map((certificate) => {
        let new_data = {
          authors: certificate["Muallif (lar)"],
          literature: certificate["O’quv adabiyotining nomi"],
          type: certificate["Turi"],
          major: certificate["Yo’nalish (mutaxassislik) shifri va nomi"],
          publishing_amount: certificate["Qo’lyozma hajmi (bet)"],
          registring_number: certificate["Ro’yxatga olish raqami"],
          order_number: certificate["Buyuruq raqami"],
          order_date:
            typeof certificate["Buyuruq sanasi"] === "string"
              ? moment(certificate["Buyuruq sanasi"], "DD-MM-YYYY").format(
                  "YYYY-MM-DD"
                )
              : moment(certificate["Buyuruq sanasi"]).format("YYYY-MM-DD"),
        };
        return new_data;
      });
    } else {
      data = selectedGeneratedCertificates;
    }
    let post_data = {
      affirmative_person: rectorOr.id,
      license_list: data,
    };
    axios
      .post("/api/v1/license/", post_data, {
        responseType: "blob",
      })
      .then((res) => {
        const href = URL.createObjectURL(res.data);
        console.log("res", res);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "certificate.zip");
        document.body.appendChild(link);
        document.body.removeChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(href);
        setLoading(false);
        getGeneratedCertificatesList();
        setAllSelectedCertificates([]);
        setAllSelectedGeneratedCertificates([]);
        setRectorOr({
          id: null,
          full_name: "Tasdiqlovchi",
        });
      })
      .catch((err) => {
        notify("Malumotda hatolik bor");
        setLoading(false);
      });
  }

  function loadCertificates(file) {
    // setCertificates([]);

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      const errors = [];
      const errorIndexs = [];
      data?.forEach((certificate, index) => {
        if (!certificate["Buyuruq raqami"]) {
          errors.push({ order: index + 1, message: "Buyuruq raqami yo'q" });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["Buyuruq sanasi"]) {
          errors.push({ order: index + 1, message: "Buyuruq sanasi yo'q" });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["Muallif (lar)"]) {
          errors.push({ order: index + 1, message: "Muallif (lar) yo'q" });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["O’quv adabiyotining nomi"]) {
          errors.push({
            order: index + 1,
            message: "O’quv adabiyotining nomi yo'q",
          });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["Ro’yxatga olish raqami"]) {
          errors.push({
            order: index + 1,
            message: "Ro’yxatga olish raqami yo'q",
          });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["Turi"]) {
          errors.push({ order: index + 1, message: "Turi yo'q" });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
        if (!certificate["Yo’nalish (mutaxassislik) shifri va nomi"]) {
          errors.push({
            order: index + 1,
            message: "Yo’nalish (mutaxassislik) shifri va nomi yo'q",
          });
          if (!errorIndexs.includes(index + 1)) {
            errorIndexs.push(index + 1);
          }
        }
      });
      setCertificates(data);
      setErrorIndexs(errorIndexs);
    });
  }

  function setAllSelectedCertificates(check) {
    if (check) {
      setSelectedCertificates(certificates);
    } else {
      setSelectedCertificates([]);
    }
  }

  function setSelectedCertificate(check, selectedCertificate) {
    let new_data = [];
    if (check) {
      new_data = [...selectedCertificates, selectedCertificate];
    } else {
      new_data = selectedCertificates.filter(
        (certificate) => certificate !== selectedCertificate
      );
    }
    setSelectedCertificates(new_data);
  }

  function removeSelectedCertificates() {
    let new_data = certificates.filter(
      (certificate) => !selectedCertificates.includes(certificate)
    );
    setCertificates(new_data);
    setSelectedCertificates([]);
  }

  function editGeneratedCertificate(certificateId) {
    axios
      .patch(`/api/v1/license/update/${certificateId}/`, {
        ...editCurrentCertificate,
      })
      .then(() => {
        getGeneratedCertificatesList();
        const new_data = selectedGeneratedCertificates?.filter(
          (certificate) => certificate.id !== certificateId
        );
        setSelectedGeneratedCertificates(new_data);
      });
    setEditModeOn("");
  }

  function getGeneratedCertificatesList() {
    axios
      .get("/api/v1/license/list/", {
        params: {
          authors: searchParams.authors,
          literature: searchParams.literature,
          page_size: 50,
        },
      })
      .then((res) => {
        setGeneratedCertificatesList(res.data);
        if (res.status === 200) {
          setPageLoading(false);
        }
      });
  }

  function searchAction(e) {
    setSearchParamas({ ...searchParams, authors: e.target.value });
  }

  function searchActionByLiterature(e) {
    setSearchParamas({ ...searchParams, literature: e.target.value });
  }

  useEffect(() => {
    function getGeneratedCertificatesList() {
      axios
        .get("/api/v1/license/list/", {
          params: {
            authors: searchParams.authors,
            literature: searchParams.literature,
            page_size: 50,
          },
        })
        .then((res) => {
          setGeneratedCertificatesList(res.data);
          if (res.status === 200) {
            setPageLoading(false);
          }
        });
    }
    getGeneratedCertificatesList();
  }, [searchParams]);

  function setAllSelectedGeneratedCertificates(check) {
    if (check) {
      setSelectedGeneratedCertificates(generatedCertificatesList?.results);
    } else {
      setSelectedGeneratedCertificates([]);
    }
  }

  function setSelectedGeneratedCertificate(check, selectedCertificate) {
    let new_data = [];
    if (check) {
      new_data = [...selectedGeneratedCertificates, selectedCertificate];
    } else {
      new_data = selectedGeneratedCertificates.filter(
        (certificate) => certificate !== selectedCertificate
      );
    }
    setSelectedGeneratedCertificates(new_data);
  }

  function removeSelectedGeneratedCertificates() {
    let to_remove = selectedGeneratedCertificates.map((certificate) => {
      return certificate.id;
    });
    axios
      .post(`/api/v1/license/delete/`, {
        license_ids: to_remove,
      })
      .then(() => {
        getGeneratedCertificatesList();
        setSelectedGeneratedCertificates([]);
      });
  }

  if (pageLoading) {
    return <Loader />;
  }

  return (
    <div className="relative w-full my-[150px] px-[25px]">
      <ToastContainer />
      <div className="w-full">
        {/* drag and drop */}
        <h1 className="uppercase mb-[15px] mt-[50px] text-center text-[24px] font-bold text-[#1E3B83]">
          Guvohnoma
        </h1>
        <div className="flex justify-center items-center w-full">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col justify-center items-center w-full h-46 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
          >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              <svg
                aria-hidden="true"
                className="mb-3 w-10 h-10 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">
                  {file ? file : "Faylni yuklang"}
                </span>
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">XLSX</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onClick={(e) => {
                e.target.value = null;
                setCertificates([]);
                // loadCertificates([]);
              }}
              onChange={(e) => {
                setFile(e.target.files[0].name);
                loadCertificates(e.target.files[0]);
              }}
            />
          </label>
        </div>

        {/* table action buttons from Excel data */}
        <OutsideClickHandler onOutsideClick={() => setRectorDrop(false)}>
          <div
            className={`${
              !certificates.length && "hidden"
            } relative mt-[15px] min-w-[150px] w-fit z-10 select-none`}
          >
            <div
              onClick={() => setRectorDrop(!rectorDrop)}
              className="flex justify-between items-center border rounded-md text-center px-[10px] py-[10px] cursor-pointer hover:shadow-md transition-all"
            >
              {rectorOr.full_name}
              <svg
                className="w-5"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </div>
            <div
              className={`${
                !rectorDrop && "hidden"
              } absolute min-w-full w-max border rounded-md bg-white`}
            >
              {rectorOrList?.map((item) => (
                <div
                  onClick={() => {
                    setRectorOr(item);
                    setRectorDrop(false);
                  }}
                  key={item.id}
                  className="text-center px-[10px] py-[5px] border-t border-slate-200 hover:bg-slate-100 cursor-pointer"
                >
                  {item.full_name}
                </div>
              ))}
            </div>
          </div>
        </OutsideClickHandler>
        <div
          className={`${
            !certificates?.length && "hidden"
          } flex justify-between my-[15px]`}
        >
          <button
            type="button"
            disabled={loading}
            className="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800"
            onClick={() => postCertificates("excel")}
          >
            {loading ? "Yuklanmoqda" : "Yuklab olish"}
            {loading ? (
              <svg
                aria-hidden="true"
                role="status"
                className="inline ml-[5px] w-5 h-5 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5 ml-[5px]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1.586l-.293-.293a.999.999 0 10-1.414 1.414l2 2a.999.999 0 001.414 0l2-2a.999.999 0 10-1.414-1.414l-.293.293V9z"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
          <button
            type="button"
            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => removeSelectedCertificates()}
          >
            <div className="flex flex-col ">
              <div>Tanlanganlarni</div>
              <div>o'chirish</div>
            </div>
            <svg
              className="w-5 h-5 ml-[10px]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </button>
        </div>

        {/* Table from Excel data */}
        <div
          className={`${
            !certificates?.length && "hidden"
          } overflow-x-auto h-[700px] relative shadow-md sm:rounded-lg`}
        >
          <table className="w-full text-[0.65vw] text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  <input
                    id="checkbox-all"
                    type="checkbox"
                    checked={
                      selectedCertificates?.length === certificates?.length
                    }
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) =>
                      setAllSelectedCertificates(e.target.checked)
                    }
                  />
                </th>
                <th scope="col" className="py-3 px-6">
                  N
                </th>
                <th scope="col" className="py-3 px-6">
                  Muallif (lar)
                </th>
                <th scope="col" className="py-3 px-6">
                  O’quv adabiyotining nomi
                </th>
                <th scope="col" className="py-3 px-6">
                  Turi
                </th>
                <th scope="col" className="py-3 px-6">
                  Yo’nalish (mutaxassislik) shifri va nomi
                </th>
                <th scope="col" className="py-3 px-6">
                  Ro’yxatga olish raqami
                </th>
                <th scope="col" className="py-3 px-6">
                  Buyuruq raqami
                </th>
                <th scope="col" className="py-3 px-6">
                  Buyuruq sanasi
                </th>
              </tr>
            </thead>
            <tbody>
              {certificates?.map((certificate, index) => (
                <tr
                  key={_uniqueId("certificate")}
                  className={`${
                    errorIndexs.includes(index + 1) &&
                    "hover:bg-red-300 bg-red-300"
                  } text-[0.65vw] bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`}
                >
                  <td className="p-4 w-4">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      defaultChecked={selectedCertificates.includes(
                        certificate
                      )}
                      onClick={(e) =>
                        setSelectedCertificate(e.target.checked, certificate)
                      }
                    />
                  </td>
                  <th
                    scope="row"
                    className="py-4 px-6 text-[0.65vw] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Muallif (lar)"]}
                    {/* <input type="text" className='border-none' value={certificate['Muallif (lar)'] ? certificate['Muallif (lar)'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Muallif (lar)') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["O’quv adabiyotining nomi"]}
                    {/* <input type="text" className='border-none' value={certificate['O’quv adabiyotining nomi'] ? certificate['O’quv adabiyotining nomi'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'O’quv adabiyotining nomi') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Turi"]}
                    {/* <input type="text" className='border-none' value={certificate['Turi'] ? certificate['Turi'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Turi') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Yo’nalish (mutaxassislik) shifri va nomi"]}
                    {/* <input type="text" className='border-none' value={certificate['Yo’nalish (mutaxassislik) shifri va nomi'] ? certificate['Yo’nalish (mutaxassislik) shifri va nomi'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Yo’nalish (mutaxassislik) shifri va nomi') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Ro’yxatga olish raqami"]}
                    {/* <input type="text" className='border-none' value={certificate['Ro’yxatga olish raqami'] ? certificate['Ro’yxatga olish raqami'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Ro’yxatga olish raqami') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Buyuruq raqami"]}
                    {/* <input type="text" className='border-none' value={certificate['Buyuruq raqami'] ? certificate['Buyuruq raqami'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Buyuruq raqami') }} /> */}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    {certificate?.["Buyuruq sanasi"]}
                    {/* <input type="text" className='border-none' value={certificate['Buyuruq sanasi'] ? certificate['Buyuruq sanasi'] : ""} onChange={(e) => { editCertificate(certificate, e.target.value, 'Buyuruq sanasi') }} /> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

        {/* GENERATED CERTIFICATES */}
        <h1 className="uppercase mb-[15px] text-center text-[24px] font-bold text-[#1E3B83]">
          Yuklangan Guvohnomalar
        </h1>
        {/* table action buttons from Generated Certificates data */}
        <OutsideClickHandler onOutsideClick={() => setRectorDrop(false)}>
          <div
            className={`relative mt-[15px] min-w-[150px] w-fit z-10 select-none`}
          >
            <div
              onClick={() => setRectorDrop(!rectorDrop)}
              className="flex justify-between items-center border rounded-md text-center px-[10px] py-[10px] cursor-pointer hover:shadow-md transition-all"
            >
              {rectorOr.full_name}
              <svg
                className="w-5"
                fill="none"
                stroke="currentColor"
                stroke-width="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                ></path>
              </svg>
            </div>
            <div
              className={`${
                !rectorDrop && "hidden"
              } absolute min-w-full w-max border rounded-md bg-white`}
            >
              {rectorOrList?.map((item) => (
                <div
                  onClick={() => {
                    setRectorOr(item);
                    setRectorDrop(false);
                  }}
                  key={item.id}
                  className="text-center px-[10px] py-[5px] border-t border-slate-200 hover:bg-slate-100 cursor-pointer"
                >
                  {item.full_name}
                </div>
              ))}
            </div>
          </div>
        </OutsideClickHandler>
        <div
          className={`w-full flex flex-col lg:flex-row justify-between mb-[15px] mt-[15px]`}
        >
          <button
            type="button"
            disabled={loading}
            className="flex justify-between text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 lg:mr-[15px] mb-[15px] lg:mb-0 text-center items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800"
            onClick={postCertificates}
          >
            {loading ? "Yuklanmoqda" : "Yuklab olish"}
            {loading ? (
              <svg
                aria-hidden="true"
                role="status"
                className="inline ml-[5px] w-5 h-5 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5 ml-[5px]"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1.586l-.293-.293a.999.999 0 10-1.414 1.414l2 2a.999.999 0 001.414 0l2-2a.999.999 0 10-1.414-1.414l-.293.293V9z"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
          {/* search */}
          <div className="order-last lg:order-none flex justify-center gap-[10px] w-full">
            <div onChange={(e) => searchAction(e)} className="w-full">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
              >
                Search
              </label>
              <div className="relative w-full">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Mualif (lar)..."
                  required=""
                />
              </div>
            </div>
            <div
              onChange={(e) => searchActionByLiterature(e)}
              className="w-full"
            >
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
              >
                Search
              </label>
              <div className="relative w-full">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="O’quv adabiyoti..."
                  required=""
                />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="flex justify-between text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 lg:ml-[15px] mb-[15px] lg:mb-0 text-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={removeSelectedGeneratedCertificates}
          >
            <div className="flex lg:flex-col">
              <div>Tanlanganlarni</div>
              <div className="ml-[5px] lg:ml-0">o'chirish</div>
            </div>
            <svg
              className="w-5 h-5 ml-[10px]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </button>
        </div>

        {/* Generated Table */}
        <div
          className={`overflow-x-auto h-[700px] relative shadow-md sm:rounded-lg`}
        >
          <table className="w-full text-[0.65vw] text-left text-gray-500 dark:text-gray-400">
            <thead className="sticky top-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-4">
                  <input
                    id="checkbox-all"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={
                      generatedCertificatesList?.results.length ===
                        selectedGeneratedCertificates.length &&
                      generatedCertificatesList?.results.length !== 0
                    }
                    onChange={(e) =>
                      setAllSelectedGeneratedCertificates(e.target.checked)
                    }
                  />
                </th>
                <th scope="col" className="py-3 px-6">
                  N
                </th>
                <th scope="col" className="py-3 px-6">
                  Muallif (lar)
                </th>
                <th scope="col" className="py-3 px-6">
                  O’quv adabiyotining nomi
                </th>
                <th scope="col" className="py-3 px-6">
                  Turi
                </th>
                <th scope="col" className="py-3 px-6">
                  Yo’nalish (mutaxassislik) shifri va nomi
                </th>
                <th scope="col" className="py-3 px-6">
                  Ro’yxatga olish raqami
                </th>
                <th scope="col" className="py-3 px-6">
                  Buyuruq raqami
                </th>
                <th scope="col" className="py-3 px-6">
                  Buyuruq sanasi
                </th>
                <th scope="col" className="py-3 px-6"></th>
              </tr>
            </thead>
            <tbody id="scroll_component" className="relative">
              {generatedCertificatesList?.results?.map((certificate, index) => (
                <tr
                  key={_uniqueId("certificate")}
                  className="text-[0.65vw] bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="p-4 w-4">
                    <input
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      defaultChecked={selectedGeneratedCertificates.includes(
                        certificate
                      )}
                      onClick={(e) =>
                        setSelectedGeneratedCertificate(
                          e.target.checked,
                          certificate
                        )
                      }
                    />
                  </td>
                  <th
                    scope="row"
                    className="py-4 px-6 text-[0.65vw] font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.authors}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.authors
                          ? editCurrentCertificate.authors
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          authors: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.literature}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.literature
                          ? editCurrentCertificate.literature
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          literature: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.type}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.type
                          ? editCurrentCertificate.type
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          type: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.major}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.major
                          ? editCurrentCertificate.major
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          major: e.target.value,
                        });
                      }}
                    />{" "}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.registring_number}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.registring_number
                          ? editCurrentCertificate.registring_number
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          registring_number: e.target.value,
                        });
                      }}
                    />{" "}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.order_number}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.order_number
                          ? editCurrentCertificate.order_number
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          order_number: e.target.value,
                        });
                      }}
                    />{" "}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${editModeOn === certificate.id && "hidden"}`}
                    >
                      {certificate.order_date}
                    </div>
                    <input
                      type="text"
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } text-[0.65vw] block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      defaultValue={
                        editCurrentCertificate.order_date
                          ? editCurrentCertificate.order_date
                          : ""
                      }
                      onBlur={(e) => {
                        setEditCurrentCertificate({
                          ...editCurrentCertificate,
                          order_date: e.target.value,
                        });
                      }}
                    />{" "}
                  </td>
                  <td className="py-4 px-6 text-[0.65vw]">
                    <div
                      className={`${
                        editModeOn === certificate.id && "hidden"
                      } flex gap-1`}
                    >
                      <button
                        type="button"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        onClick={() => {
                          setEditModeOn(certificate.id);
                          setEditCurrentCertificate(certificate);
                        }}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    On edit mode 
                    <div
                      className={`${
                        editModeOn !== certificate.id && "hidden"
                      } flex gap-1`}
                    >
                      <button
                        type="button"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        onClick={() => editGeneratedCertificate(certificate.id)}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </button>
                      <button
                        type="button"
                        className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() => setEditModeOn("")}
                      >
                        <svg
                          className="w-4 h-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
