import React, {useEffect, useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { ContractTypeAction } from '../../redux';
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';
import indexbg from '../media/indexbg.svg'
import {useTranslation} from 'react-i18next'
import { useSnackbar } from 'notistack';
import SimpleDownload from '../SimpleDownload/SimpleDownload'

const useStyles = makeStyles((theme) => ({
    outerGrid: {
        height: "100vh",
        backgroundImage: `url(${indexbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-100% 20%",
        overflow: "visible",
    },
    grid: {
        backgroundColor: "white",
        padding: "4vw 1vw 4vw 1vw",
        width: "100%",
        boxShadow: "0.26vw 0.78vw 0.62vw 0.26vw rgb(14 36 129 / 8%)",
        minHeight: "20vw",
        maxHeight: "20vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "40vw",
            maxHeight: "40vw",
        },
    },
    outputText: {
        color: "#104279",
        fontWeight: "bold",
        fontSize: "1.2vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.2vw",
        },
    },
    selectInput: {
        width: "8vw", 
        outline: "none" ,
        fontSize: "0.83vw", 
        padding: "0.5vw 0 0.5vw 1vw", 
        border: "0.1vw solid black", 
        borderRadius: "0.2vw", 
        color: "black", 
        backgroundColor: "white", 
        MozAppearance: "none", 
        WebkitAppearance: "none",
        [theme.breakpoints.down('xs')]: {
            width: "24vw", 
            fontSize: "2.5vw",
            padding: "1.5vw 2vw",
            marginTop: "3vw",
        },
    },
    submitButton: {
        fontSize: "0.73vw",
        lineHeight: 1.4,
        padding: "0.52vw 1.3vw",
        letterSpacing: "0.1vw",
        color: "white",
        backgroundImage: "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
        backgroundPositionX: "100%",
        backgroundPositionY: 0,
        backgroundSize: "200%",
        backgroundRepeat: "initial",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
        boxShadow: "0 0.4vw 0.63vw 0 rgb(14 36 129 / 8%)",
        borderRadius: "0.3vw",
        transition: "all 0.5s ease",
        marginTop: "0.78vw",
        "&:hover": {
            boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
            backgroundPosition: "0 0 !important",
            transition: "all 0.5s ease",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.5vw",
            padding: "1.5vw 2vw",
            marginTop: "4vw",
        },
    }
}))
function ContractDownload({appData, ContractTypeAction}) {
    const { enqueueSnackbar } = useSnackbar();
    const {t}=useTranslation();
    const navigate = useNavigate()
    const classes = useStyles();
    const [contractType, setContractType] = useState({
        payment_type: ""
    })
    return (
        appData.user.payment_type ? <SimpleDownload/> 
        : appData.loading ?  <Loader/>
            :<Grid container item xs={12} direction="column" justify="center" alignItems="center" className={classes.outerGrid} >
                <Grid container item sm={3} xs={9} direction="column" justify="flex-start" alignItems="center" className={classes.grid} >
                    <p className={classes.outputText} >{t('paymentType.boldText')} </p>
                    <select name="cars" id="cars" value={contractType.payment_type} onChange={(e)=>setContractType({payment_type: e.target.value})} className={classes.selectInput} >
                        <option value="">{t('paymentType.option1')}</option>
                        <option value="stipendiyali">{t('paymentType.option2')}</option>
                        <option value="stipendiyasiz">{t('paymentType.option3')}</option>
                    </select>
                    <Button disabled={contractType.payment_type ? false : true} onClick={()=> {ContractTypeAction(contractType, navigate, enqueueSnackbar)}} className={classes.submitButton} variant="contained" color="primary"> {t('paymentType.buttonText')} </Button>
                </Grid>
            </Grid>
        
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ContractTypeAction: (contractType, navigate, enqueueSnackbar) => dispatch(ContractTypeAction(contractType, navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContractDownload)