import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DescModal from "./DescModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import axios from "axios";
import { isClear } from "./helpers";
import moment from "moment";

const getFaculty = () => {
  return axios.get("/api2/v2/studentprofile/").then((res) => res.data);
};

const bakalavrKunState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: "",
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: "",
    },
  },
};

const bakalavrSrtState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: "",
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: "",
    },
  },
};

const magistrState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: "",
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: "",
    },
  },
};

const profState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "Ректор буйруғига асосан чет давлатга юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
  },
};

const TeachersStudents = () => {
  const [data, setData] = useState(null);

  const getData = () => {
    axios
      .get(
        `/api/v1/attendance-control/list/?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getData();
  }, []);

  const [faculty, setFaculty] = useState("");
  useEffect(() => {
    getFaculty().then((data) => setFaculty(data[0]));
  }, []);

  const [isPosting, setIsPosting] = useState(false);
  const [date, setDate] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [bakalavrKun, setBakalavrKun] = useState(bakalavrKunState);

  const [bakalavrSrt, setBakalavrSrt] = useState(bakalavrSrtState);

  const [magistr, setMagistr] = useState(magistrState);

  const [prof, setProf] = useState(profState);

  const postResults = () => {
    setIsPosting(true);
    axios
      .post("/api/v1/attendance-control/", {
        time: date,
        base_attendances: [
          {
            type: "Бакалавриат (кундузги)",
            all_count:
              Number(bakalavrKun.in) +
              Number(bakalavrKun.out) +
              Number(bakalavrKun.desc.noGraf.value) +
              Number(bakalavrKun.desc.prac.value),
            attended_count: Number(bakalavrKun.in),
            not_attendeds_count: Number(bakalavrKun.out),
            ill_count: Number(bakalavrKun.desc.ill.value),
            go_away_count: Number(bakalavrKun.desc.abroad.value),
            causeless_count: Number(bakalavrKun.desc.noIdea.value),
            dont_have_classes_count: Number(bakalavrKun.desc.noGraf.value),
            practicants_count: Number(bakalavrKun.desc.prac.value),
          },
          {
            type: "Бакалавриат (сиртқи)",
            all_count:
              Number(bakalavrSrt.in) +
              Number(bakalavrSrt.out) +
              Number(bakalavrSrt.desc.noGraf.value) +
              Number(bakalavrSrt.desc.prac.value),
            attended_count: Number(bakalavrSrt.in),
            not_attendeds_count: Number(bakalavrSrt.out),
            ill_count: Number(bakalavrSrt.desc.ill.value),
            go_away_count: Number(bakalavrSrt.desc.abroad.value),
            causeless_count: Number(bakalavrSrt.desc.noIdea.value),
            dont_have_classes_count: Number(bakalavrSrt.desc.noGraf.value),
            practicants_count: Number(bakalavrSrt.desc.prac.value),
          },
          {
            type: "Магистратура",
            all_count:
              Number(magistr.in) +
              Number(magistr.out) +
              Number(magistr.desc.noGraf.value) +
              Number(magistr.desc.prac.value),
            attended_count: Number(magistr.in),
            not_attendeds_count: Number(magistr.out),
            ill_count: Number(magistr.desc.ill.value),
            go_away_count: Number(magistr.desc.abroad.value),
            causeless_count: Number(magistr.desc.noIdea.value),
            dont_have_classes_count: Number(magistr.desc.noGraf.value),
            practicants_count: Number(magistr.desc.prac.value),
          },
          {
            type: "Профессор-ўқитувчилар",
            all_count: Number(prof.in) + Number(prof.out),
            attended_count: Number(prof.in),
            not_attendeds_count: Number(prof.out),
            ill_count: Number(prof.desc.ill.value),
            go_away_count: Number(prof.desc.abroad.value),
            causeless_count: Number(prof.desc.noIdea.value),
          },
        ],
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success("Saqlandi");
          getData();
          setIsSubmitted(false);
        }
        setIsPosting(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error("Ma'lumot kiritish vaqti tugallangan!");
        }
        setIsPosting(false);
      });
  };

  useEffect(() => {
    setBakalavrKun(bakalavrKunState);
    setBakalavrSrt(bakalavrSrtState);
    setMagistr(magistrState);
    setProf(profState);
  }, [date]);

  useEffect(() => {
    if (data?.length && date === "12:00") {
      for (let d of data) {
        if (d.time === "12:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Бакалавриат (кундузги)") {
              setBakalavrKun({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Бакалавриат (сиртқи)") {
              setBakalavrSrt({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Магистратура") {
              setMagistr({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Профессор-ўқитувчилар") {
              setProf({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                },
              });
            }
          }
        }
      }
    }
    if (data?.length && date === "18:00") {
      for (let d of data) {
        if (d.time === "18:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Бакалавриат (кундузги)") {
              setBakalavrKun({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Бакалавриат (сиртқи)") {
              setBakalavrSrt({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Магистратура") {
              setMagistr({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  noGraf: {
                    title:
                      "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                    value: aType.dont_have_classes_count,
                  },
                  prac: {
                    title: "Aмалиётга юборилганлар",
                    value: aType.practicants_count,
                  },
                },
              });
            }
            if (aType.type === "Профессор-ўқитувчилар") {
              setProf({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "Ректор буйруғига асосан чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                },
              });
            }
          }
        }
      }
    }
  }, [data, date]);

  return (
    <div className="mt-36 container mx-auto text-center py-4">
      <ToastContainer />
      <div className="font-bold text-lg">
        Тошкент давлат иқтисодиёт университети {faculty?.faculty_title}{" "}
        факультети тўғрисида кунлик
      </div>
      <div className="font-bold text-lg">МАЪЛУМОТ</div>

      <FormControl>
        <RadioGroup
          onChange={(e) => setDate(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="12:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 12:00 ҳолатига`}
          />
          <FormControlLabel
            value="18:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 18:00 ҳолатига`}
          />
        </RadioGroup>
      </FormControl>
      {/* <div className="text-black">30.01.2024 йил соат 12:00 ҳолатига</div>
      <div className="">30.01.2024 йил соат 18:00 ҳолатига</div> */}

      {/* Table */}
      <table className={`${!date && "hidden"} mt-4`}>
        <thead>
          <tr>
            <th rowSpan={2} colSpan={1}>
              т/р
            </th>
            <th rowSpan={2} colSpan={4}>
              Қатнашчилар тоифаси
            </th>
            <th rowSpan={2} colSpan={2}>
              Факультет 1/2-смена бўйича Жами
            </th>
            <th rowSpan={1} colSpan={4}>
              Шундан
            </th>
            <th rowSpan={2} colSpan={5}>
              Изоҳ
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Дарсга (ишга) қатнашганлар сони</th>
            <th colSpan={2}>Дарсга (ишга) қатнашмаганлар сони</th>
          </tr>
        </thead>
        {/*  */}
        <tbody>
          <tr>
            <td colSpan={1}>1</td>
            <td colSpan={4}>Бакалавриат (кундузги)</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bakAll"
                className={`absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                required
                value={
                  Number(bakalavrKun.in) +
                  Number(bakalavrKun.out) +
                  Number(bakalavrKun.desc.noGraf.value) +
                  Number(bakalavrKun.desc.prac.value)
                }
                id="bakAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bakTrue"
                className={`${
                  isSubmitted && !bakalavrKun.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                type="number"
                value={bakalavrKun.in}
                onChange={(e) =>
                  setBakalavrKun({ ...bakalavrKun, in: e.target.value })
                }
                id="bakTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bakFalse"
                className={`${
                  isSubmitted &&
                  bakalavrKun.out === "" &&
                  "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                value={bakalavrKun.out}
                disabled
                id="bakFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={bakalavrKun} callback={setBakalavrKun} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(bakalavrKun.desc).map((item) => (
                  <li key={item}>
                    {bakalavrKun.desc[item].title} -{" "}
                    <b>{bakalavrKun.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>2</td>
            <td colSpan={4}>Бакалавриат (сиртқи)</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bak2All"
                className={`absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={
                  Number(bakalavrSrt.in) +
                  Number(bakalavrSrt.out) +
                  Number(bakalavrSrt.desc.noGraf.value) +
                  Number(bakalavrSrt.desc.prac.value)
                }
                id="bak2All"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bak2True"
                className={`${
                  isSubmitted && !bakalavrSrt.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                type="number"
                value={bakalavrSrt.in}
                onChange={(e) =>
                  setBakalavrSrt({ ...bakalavrSrt, in: e.target.value })
                }
                id="bak2True"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bak2False"
                className={`${
                  isSubmitted &&
                  bakalavrSrt.out === "" &&
                  "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                value={bakalavrSrt.out}
                disabled
                id="bak2False"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={bakalavrSrt} callback={setBakalavrSrt} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(bakalavrSrt.desc).map((item) => (
                  <li key={item}>
                    {bakalavrSrt.desc[item].title} -{" "}
                    <b>{bakalavrSrt.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>3</td>
            <td colSpan={4}>Магистратура</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="magAll"
                className={`absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={
                  Number(magistr.in) +
                  Number(magistr.out) +
                  Number(magistr.desc.noGraf.value) +
                  Number(magistr.desc.prac.value)
                }
                id="magAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="magTrue"
                className={`${
                  isSubmitted && !magistr.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                value={magistr.in}
                onChange={(e) => setMagistr({ ...magistr, in: e.target.value })}
                type="number"
                id="magTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="magFalse"
                className={`${
                  isSubmitted && magistr.out === "" && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full `}
              ></label>
              <input
                value={magistr.out}
                disabled
                type="number"
                id="magFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={magistr} callback={setMagistr} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(magistr.desc).map((item) => (
                  <li key={item}>
                    {magistr.desc[item].title} -{" "}
                    <b>{magistr.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>4</td>
            <td colSpan={4}>Профессор-ўқитувчилар</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="profAll"
                className={`absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={Number(prof.in) + Number(prof.out)}
                id="profAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="proTrue"
                className={`${
                  isSubmitted && !prof.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                value={prof.in}
                onChange={(e) => setProf({ ...prof, in: e.target.value })}
                type="number"
                id="proTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="proFalse"
                className={`${
                  isSubmitted && prof.out === "" && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full `}
              ></label>
              <input
                value={prof.out}
                disabled
                type="number"
                id="proFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={prof} callback={setProf} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(prof.desc).map((item) => (
                  <li key={item}>
                    {prof.desc[item].title} - <b>{prof.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={`${!date && "hidden"} mt-4`}>
        <Button
          onClick={() => {
            setIsSubmitted(true);
            if (
              isClear(bakalavrKun) &&
              isClear(bakalavrSrt) &&
              isClear(magistr) &&
              isClear(prof)
            ) {
              if (
                // isEqual(bakalavrKun.out, bakalavrKun.desc) &&
                // isEqual(bakalavrSrt.out, bakalavrSrt.desc) &&
                // isEqual(magistr.out, magistr.desc) &&
                // isEqual(prof.out, prof.desc)
                true
              ) {
                postResults();
              } else {
                toast.error(
                  "Дарсга (ишга) қатнашмаганлар сони Изоҳга тенг емас!"
                );
              }
            }
          }}
          disabled={isPosting}
          variant="contained"
          size="large"
          color="success"
        >
          Saqlash
        </Button>
      </div>
    </div>
  );
};

export default TeachersStudents;
