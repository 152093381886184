import React, {useState, useEffect, useRef} from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { connect } from "react-redux";
import {useTranslation} from 'react-i18next'
import logo from '../media/Logo1.png'
import en from '../media/en.png'
import ru from '../media/ru.png'
import uz from '../media/uz.png'
import avatar from '../media/avatar.jpg'
import { useNavigate, useLocation } from "react-router-dom";
import { LogoutAction } from '../../redux';
import { useSnackbar } from 'notistack';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import './Header.css'

const useStyles = makeStyles((theme) => ({
    headerOuterGrid: {
        top: 0,
        borderBottom: "0.2vw solid #eee",
        minHeight: "5.5vw",
        position: "fixed",
        backgroundColor: "white",
        zIndex: "40",
        [theme.breakpoints.down('sm')]: {
            // backgroundColor: "red",
            minHeight: "7vw",
            borderBottom: "0.3vw solid #eee",
        },
        [theme.breakpoints.down('xs')]: {
            // backgroundColor: "red",
            minHeight: "16vw",
            maxHeight: "18vw",
            borderBottom: "0.3vw solid #eee",
        },
    },
    headerLogo: {
        height: "3.5vw",
        marginRight: "1vw",
        transition: "all 0.5s ease",
        cursor: "pointer",
        "&:hover": {
            transition: "all 0.5s ease",
            transform: "scale(0.9)"
        },
        [theme.breakpoints.down('sm')]: {
            height: "5vw",
            marginRight: "2vw",
        },
        [theme.breakpoints.down('xs')]: {
            height: "12vw",
            marginLeft: "0vw",
            marginRight: "1vw",
        },
    },
    headerLinkButton: {
        fontSize: "0.65vw",
        lineHeight: 1.4,
        color: "#333333",
        padding: 0,
        letterSpacing: "0.1vw",
        fontWeight: 500,
        maxWidth: "7vw",
        minWidth:"6.7vw",
        height: "3vw",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(10,151,255,0.06)"
        },
        [theme.breakpoints.down('sm')]: {
            // backgroundColor: "red",
            fontSize: "0.86vw",
            maxWidth: "9.1vw",
            minWidth:"8.7vw",
            height: "5vw",
        },
    },
    headerLoginButton: {
        margin:0,
        fontSize: "0.73vw",
        lineHeight: 1.4,
        minWidth: "6vw",
        height: "2vw",
        padding: "0",
        letterSpacing: "0.1vw",
        color: "white",
        backgroundImage: "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
        backgroundPositionX: "100%",
        backgroundPositionY: 0,
        backgroundSize: "200%",
        backgroundRepeat: "initial",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
        boxShadow: "0 0.42vw 0.63vw 0 rgb(14 36 129 / 8%)",
        borderRadius: "0.3vw",
        "&:hover": {
            boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
            backgroundPosition: "0 0 !important",
            transition: "all 1s ease",
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.82vw",
            lineHeight: 1.4,
            minWidth: "6vw",
            height: "2.5vw",
        },
        [theme.breakpoints.down('xs')]: {
            position: "absolute",
            right: "2vw",
            fontSize: "2.4vw",
            lineHeight: 1.4,
            minWidth: "15vw",
            height: "8vw",
            boxShadow: "0 1vw 1.2vw 0 rgb(14 36 129 / 8%)",
            borderRadius: "1vw",
        },
    },
    langImg: {
        width: "1.5vw",
        height: "1vw",
        marginRight: "0.5vw",
        [theme.breakpoints.down('sm')]: {
            width: "2vw",
            height: "1.6vw",
            marginRight: "0.8vw",
        },
        [theme.breakpoints.down('xs')]: {
            width: "4.5vw",
            height: "3vw",
            marginRight: "1vw",
            marginLeft: "1vw"
        },
    },
    langSellect: {
        marginRight: "0.5vw",
        // backgroundColor: "blue",
        fontSize: "0.8vw",
        lineHeight: "1vw",
        fontWeight: 500,
        width: "2.5vw",
        fontFamily :"inherit",
        textAlign: "center",
        "& .MuiSvgIcon-root": {
            display: "none"
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: "0.5vw",
            fontSize: "1vw",
            width: "3.5vw",
            lineHeight: 1.5,
            // "& .MuiSvgIcon-root": {
            //     // justifyContent: "center",
            //     fontSize: "2vw",
            //     // textAlign: "center",

            // },
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: "0.5vw",
            fontSize: "3vw",
            width: "5.5vw",
        },
    }, 
    avatarButton: {
        backgroundImage: `url(${avatar})`,
        backgroundPosition: "center", 
        minWidth: "2.62vw", 
        minHeight: "2.62vw", 
        backgroundSize: "210%", 
        borderRadius: "50%", 
        marginRight: "1.5vw", 
        padding: 0, 
        border: "0.1vw solid #2280BA", 
        color: "#1AB5E0",
        [theme.breakpoints.down('sm')]: {
            minWidth: "4.1vw", 
            minHeight: "4.1vw", 
        },
        [theme.breakpoints.down('xs')]: {
            position: "absolute",
            right: "20vw",
            minWidth: "10vw", 
            minHeight: "10vw", 
        },
    }
}))
function Header({appData, LogoutAction}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs")); 
    const [menustate, setMenuState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setMenuState(open);
    };
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    const header = useRef()
    const {t, i18n}=useTranslation();
    const changeLanguage =(lang)=>{
        i18n.changeLanguage(lang);
    }
    const handleButtonClick = (pageURL)=> {
        toggleDrawer(false)
        if (location.pathname==="/") {
            const violation = document.getElementById(`${pageURL}`); 
            window.scrollTo({
                top:violation.offsetTop - header.current.offsetHeight,
                behavior:"smooth"
            });
        }else{
            navigate('/');
        }
    }
    const [langImg, setlangImg] = useState(en)
    useEffect(() => {
        switch (t('header.lang')) {
            case "en":
                setlangImg(en)
                break;
            case "ru":
                setlangImg(ru)
                break;
            case "uz":
                setlangImg(uz)
                break;
            default:
                break;
        }
    }, [t('header.lang')])
    const classes = useStyles();
    return (
        <>
            {
                isMobile ?
                        <Grid ref={header} container item xs={12} direction="row" justify="flex-start" alignItems="center" className={classes.headerOuterGrid}  >
                            <IconButton style={{position: "relative", top: "0px", left: 0, cursor: "pointer", color: "#1E3B83"}} onClick={toggleDrawer(true) } >
                                <MenuIcon color="inherit" style={{fontSize: "12vw", }} />
                            </IconButton>
                            <img src={logo} className={classes.headerLogo} alt="Text" onClick={()=>{navigate('/');  window.scrollTo(0, 0);}} />
                            <img className={classes.langImg} alt="language" src={langImg}/>
                            <NativeSelect className={classes.langSellect} defaultValue={t('header.lang')} onChange={(e)=>changeLanguage(e.target.value)}  disableUnderline>
                                <option value={'uz'}>UZ</option>
                                <option value={'en'}>EN</option>
                                <option value={'ru'}>RU</option>
                            </NativeSelect>
                            {
                                    appData.isLoggedIn ?
                                    <React.Fragment>
                                        <Button className={classes.avatarButton} onClick={() => {navigate('/profile');  window.scrollTo(0, 0);}} />
                                        <Button className={classes.headerLoginButton}  onClick={() => { LogoutAction(navigate, enqueueSnackbar); window.scrollTo(0, 0);}} >{t('header.button1')}</Button>
                                    </React.Fragment>
                                    :<Button className={classes.headerLoginButton} onClick={()=>{ navigate('/login');  window.scrollTo(0, 0);}} >{t('header.button')}</Button>
                                }
                            <SwipeableDrawer anchor='top' open={menustate} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)} >
                                <div role="presentation" onClick={toggleDrawer(false)}  onKeyDown={toggleDrawer(false)} >
                                    <List className="nav1">
                                        {
                                            t('header.menuItems', {returnObjects: true}).map((menuItem, index) => {
                                                const { menuTitle, pageURL } = menuItem;
                                                return(
                                                    <Grid key={menuTitle} container alignItems="center" style={{padding: "0 15vw"}}>
                                                        <ListItem button className="list1" onClick={() => handleButtonClick(pageURL)} >
                                                            <ListItemText className="text1" > {menuTitle} </ListItemText>
                                                        </ListItem>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <IconButton style={{position: "absolute", top: "2vw", left: "1vw", cursor: "pointer", padding: "1vw", color: "#1E3B83"}} onClick={toggleDrawer(false)} >
                                            <CloseIcon color="inherit" style={{fontSize: "12vw"}} />
                                        </IconButton>
                                    </List>
                                </div>
                            </SwipeableDrawer>
                        </Grid>
                :<Grid ref={header} container direction="row" justifyContent="center" alignItems="center" className={classes.headerOuterGrid}  >
                    <Grid container item md={8} sm={10} xs={12} direction="row" justifyContent="flex-start " alignItems="center">
                        <Grid container item xs={10} direction="row" justifyContent="flex-start" alignItems="center" 
                        // style={{backgroundColor: "blue"}} 
                        >
                        <img src={logo} className={classes.headerLogo} alt="Text" onClick={()=>{navigate('/');  window.scrollTo(0, 0);}} />
                            <img className={classes.langImg} alt="language" src={langImg}/>
                            <NativeSelect className={classes.langSellect} defaultValue={t('header.lang')} onChange={(e)=>changeLanguage(e.target.value)}  disableUnderline>
                                <option value={'uz'}>UZ</option>
                                <option value={'en'}>EN</option>
                                <option value={'ru'}>RU</option>
                            </NativeSelect>
                            {
                                t('header.menuItems', {returnObjects: true}).map((menuItem, index) => {
                                    const { menuTitle, pageURL } = menuItem;
                                    return(
                                        <Button key={menuTitle}  className={classes.headerLinkButton} onClick={() => handleButtonClick(pageURL)} > {menuTitle} </Button>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container item xs={2} direction="row" justifyContent="flex-end" alignItems="center" >
                                {
                                    appData.isLoggedIn ?
                                    <React.Fragment>
                                        <Button className={classes.avatarButton} onClick={() => {navigate('/profile');  window.scrollTo(0, 0);}} />
                                        <Button className={classes.headerLoginButton}  onClick={() => { LogoutAction(navigate, enqueueSnackbar); window.scrollTo(0, 0);}} >{t('header.button1')}</Button>
                                    </React.Fragment>
                                    :<Button className={classes.headerLoginButton} onClick={()=>{ navigate('/login');  window.scrollTo(0, 0);}} >{t('header.button')}</Button>
                                }
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>    
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        LogoutAction: (navigate, enqueueSnackbar) => dispatch(LogoutAction(navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)