export const isClearDesc = (obj) => {
  for (let val in obj) {
    if (!obj[val].value) {
      return false;
    }
  }
  return true;
};

export const isClear = (obj) => {
  for (let key in obj) {
    // if (key == "desc") {
    //   for (let val in obj[key]) {
    //     if (obj[key][val].value === "") {
    //       return false;
    //     }
    //   }
    // } else if (obj[key] === "") {
    //   return false;
    // }

    if (obj[key] === "") {
      return false;
    }
  }
  return true;
};

export const isEqual = (sum, values) => {
  let sumOfValues = 0;
  for (let key in values) {
    if (key !== "noGraf") {
      sumOfValues += Number(values[key].value);
    }
  }
  return sumOfValues === Number(sum);
};

export const generateDates = () => {
  const startDate = new Date("2024-01-01");
  const currentDate = new Date();
  const datesArray = [];

  for (
    let date = startDate;
    date <= currentDate;
    date.setDate(date.getDate() + 1)
  ) {
    datesArray.push(new Date(date));
  }

  return datesArray;
};
