import React, {useEffect} from 'react'
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux'
import { DebtAction } from '../../redux'
import './debt.css'
import Loader from '../Loader/Loader';

function Debt({appData, DebtAction}) {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        DebtAction(enqueueSnackbar)
    }, [])
    return (
        appData.loading ? <Loader/>
        : appData.debt ?
    //    ( Object.keys(appData.debt).length==13) ?
            <div className="outerDiv" >
                <p className="titleName" > Aylanma varaqa holati </p>
                <table className="debtTable">
                    <tr className="headerRow">
                        <th> &#8470;</th>
                        <th>Bo'lim nomi</th>
                        <th>Holati</th>
                        <th>Holati haqida ma'lumot</th>
                        <th>Tekshirilgan Sanasi</th>
                    </tr>
                    <tr className={appData.debt.faculty_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>1</td>
                        <td>Fakultet dekanati </td>
                        <td>{appData.debt.faculty_status}</td>
                        <td>{appData.debt.faculty_comment} </td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                    <tr className={appData.debt.library_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>2</td>
                        <td>Kutubxona</td>
                        <td>{appData.debt.library_status}</td>
                        <td>{appData.debt.library_comment} </td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                    <tr className={appData.debt.kafedra_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>3</td>
                        <td>Kafedra</td>
                        <td>{appData.debt.kafedra_status}</td>
                        <td>{appData.debt.kafedra_comment}</td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                    <tr className={appData.debt.marketing_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>4</td>
                        <td>Marketing bo’limi</td>
                        <td>{appData.debt.marketing_status}</td>
                        <td>{appData.debt.marketing_comment}</td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                    <tr className={appData.debt.hhb_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>6</td>
                        <td>Harbiy hisob bo’limi</td>
                        <td>{appData.debt.hhb_status}</td>
                        <td>{appData.debt.hhb_comment}</td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                    <tr className={appData.debt.dormitory_status=="Qarzdorligi yo'q" ? "qarzdoremas" : "qarzdor"} >
                        <td>7</td>
                        <td>Yotoqxona </td>
                        <td>{appData.debt.dormitory_status}</td>
                        <td>{appData.debt.dormitory_comment}</td>
                        <td>{appData.debt.checked_at}</td>
                    </tr>
                </table>
            </div>
            :<div className="outerDiv" >
                <p className="titleName" > Aylanma varaqa holati </p>
                <table className="debtTable1">
                    <tr className="headerRow">
                        <th> &#8470;</th>
                        <th>Bo'lim nomi</th>
                        <th>Holati</th>
                        <th>Holati haqida ma'lumot</th>
                        <th>Tekshirilgan Sanasi</th>
                    </tr>
                    <tr className="qarzdor" >
                        <td>1</td>
                        <td>Fakultet dekanati </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="qarzdoremas" >
                        <td>2</td>
                        <td>Kutubxona</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="qarzdor" >
                        <td>3</td>
                        <td>Kafedra</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="qarzdoremas" >
                        <td>4</td>
                        <td>Marketing bo’limi</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="qarzdor" >
                        <td>6</td>
                        <td>Harbiy hisob bo’limi</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="qarzdoremas" >
                        <td>7</td>
                        <td>Yotoqxona </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
        </div>
    )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        DebtAction: (enqueueSnackbar) => dispatch(DebtAction(enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Debt)