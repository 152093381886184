import Staffs from "./Staffs";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import TeachersStudents from "./TeachersStudents";
import { useEffect, useState } from "react";
import Results from "./Results";
import LanguageCenter from "./LanguageCenter";

const Attendance = ({ appData }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      appData?.user?.role !== "dean" &&
      appData?.user?.role !== "rahbariyat" &&
      appData?.user?.role !== "kadr" &&
      appData?.user?.role !== "kafedra"
    ) {
      navigate("/");
    }
  }, [appData]);

  if (appData?.user?.role === "rahbariyat") {
    return <Results />;
  }
  if (appData?.user?.role === "dean") {
    return <TeachersStudents />;
  }
  if (appData?.user?.role === "kadr") {
    return <Staffs />;
  }
  if (appData?.user?.role === "kafedra") {
    return <LanguageCenter />;
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    appData: state.app,
  };
};
export default connect(mapStateToProps)(Attendance);
