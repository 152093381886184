import React from 'react'
import {useTranslation} from 'react-i18next'

function SubjectsRegistration() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide md:mb-6 mb-3'>
                {t('subjects_registration.name')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('subjects_registration.part1')}
            </p>
        </div>
    </div>
  )
}

export default SubjectsRegistration