import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import React, { useState } from "react";
import Select from "react-select";
import {useTranslation} from 'react-i18next'
import { connect } from 'react-redux'
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { ComplaintAction } from '../../redux';

const ContactRegistrar1 = ({ComplaintAction}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const options = [
    { value: "Register office", label: t('registrar.Register') },
    { value: "Credit system office", label: t('registrar.Credit') },
    { value: "Database office", label: t('registrar.Database') },
  ];
  const [selectedOption, setSelectedOption] = useState(options[2].value);
  const [success, setSuccess] = useState(false);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  function SendApply(complaintState) {
    ComplaintAction("student_appeal", complaintState, navigate, enqueueSnackbar);
    setSuccess(!success)
  }

  return (
      <div className="w-full grid grid-cols-12 xl:my-36 my-28" >
        <div className="lg:col-span-5 md:col-span-6 lg:col-start-2 col-span-12 pl-4 ">
          <h1 className="text-2xl text-[#263d8a] font-bold mb-[20px] ">
          {t('registrar.boldText1')}
          </h1>
          {
            t('registrar.contactInformation', {returnObjects: true}).map((contact) => {
                const { boldText, lightText } = contact;
                return(
                    <p className="text-[#0f0f0f] mb-2 flex items-start"  ><strong className="text-[#1cb5e0]" >{boldText}</strong> <span className="ml-2"> {lightText} </span></p>
                )
            })
        }
        </div>
        <div className="lg:col-span-5 md:col-span-6 col-span-12 pl-3 md:mt-0 mt-8 ">
          <h1 className="text-2xl text-[#263d8a] font-bold ">
              {t('registrar.headerBoldText')}
          </h1>
          <div className="grid gap-[15px] my-[15px]">
            <div className="col-span-12">
              <Formik
                initialValues={{
                  fullname: "",
                  phone: "",
                  comment: "",
                  to_whom: selectedOption
                }}
                validationSchema={Yup.object().shape({
                  fullname: Yup.string().required(t('registrar.fullname_error')),
                  phone: Yup.string().required(t('registrar.phone_error')),
                  comment: Yup.string().required(t('registrar.comment_error'))
                })}
                onSubmit={(complaintState) => {
                  SendApply(complaintState);
                  complaintState.comment = ""
                  complaintState.fullname = ""
                  complaintState.phone = ""
                }}
              >
                {({ errors, touched, handleBlur, handleChange }) => (
                  <Form className="grid grid-cols-12 gap-[15px]">
                    <div className="col-span-11">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="fullname" >
                        {t('registrar.fullname')}
                      </label>
                      <Field
                        name="fullname"
                        type="text"
                        placeholder={t('registrar.fullname')}
                        className={
                          "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px] " +
                          (errors.fullname && touched.fullname
                            ? "border-red-500"
                            : "")
                        }
                      />
                      {errors.fullname && <span className="text-red-500 text-xs ml-2" >*{errors.fullname}</span>}
                    </div>
                    <div className="col-span-11">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="phone" >
                        {t('registrar.phone')}
                      </label>
                      <Field name="phone" >
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            name="phone"
                            id="phone"
                            placeholder="+998 __ ______"
                            type="tel"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px]" +
                              (errors.phone && touched.phone
                                ? " border-red-500"
                                : "")
                            }
                          />
                        )}
                      </Field>
                      {errors.phone && <span className="text-red-500 text-xs ml-2" >*{errors.phone}</span>}
                    </div>
                    <div className="col-span-11">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="to_whom" >
                        {t('registrar.to_whom')}
                      </label>
                      <Select
                      name="to_whom"
                        options={options}
                        defaultValue={options[2]}
                        onChange={(e) => setSelectedOption(e.value)}
                      />
                    </div>
                    <div className="col-span-11">
                      <label className="text-[#8C97AB] font-semibold" htmlFor="comment" >
                      {t('registrar.comment')}
                      </label>
                      <Field
                        name="comment"
                        type="text"
                        as="textarea"
                        placeholder={t('registrar.comment')}
                        className={
                          "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm py-[10px] px-[15px]" +
                          (errors.comment && touched.comment
                            ? " border-red-500"
                            : "")
                        }
                      />
                      {errors.comment && <span className="text-red-500 text-xs ml-2" >*{errors.comment}</span>}
                    </div>
                    {/* submit */}
                    <div className="col-span-11 text-right">
                      <button
                        type="submit"
                        className="bg-[#1cb5e0] hover:bg-[#263d8a] text-white border mr-[15px] border-[#E2E5E9] py-[5px] px-[10px] rounded-sm transition"
                      >
                        {t('registrar.sendButton')}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
  );
};

const mapStateToProps = state => {
  return {
    appData: state.app
  }
}

const mapDispatchToProps = dispatch => {
  return {
      ComplaintAction: (Url, complaintState, navigate, enqueueSnackbar) => dispatch(ComplaintAction(Url, complaintState, navigate, enqueueSnackbar))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactRegistrar1)
