import React from 'react'
import {useTranslation} from 'react-i18next'

function AcademicMobility() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide mb-3'>
                {t('academic_mobility.name')}
            </p>
            <p className='xl:text-lg text-[#263d8a] sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                <span className='font-semibold' >{t('academic_mobility.name')}</span> {t('academic_mobility.part1')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part2')}
            </p>
            <ul className='list-inside pl-6 pr-3 sm:text-base text-sm italic tracking-normal'>
                {
                    t('academic_mobility.part3', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                - {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3 mt-2' >
                {t('academic_mobility.part4')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part5')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part6')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part7')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part8')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part9')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part10')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part11')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('academic_mobility.part12')}
            </p>
            
        </div>
    </div>
  )
}

export default AcademicMobility