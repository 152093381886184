import React from 'react'
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

function ProtectedRoute({appData, redirectPath = '/login', children, pathFrom}) {
    if (!appData.isLoggedIn) {
        return <Navigate to={redirectPath} replace />;
      }else{
        switch (pathFrom) {
            case "editCV":
               if (appData.studentuser.student_resume) {
                return children;
               }else{
                return <Navigate to={"/createCV"} replace />;
               }
            default:
                return children
        }
      }
}

const mapStateToProps = state => {
    return {
        appData: state.app
    }
}
export default connect(
    mapStateToProps
    )(ProtectedRoute)