import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DescModal from "./DescModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import axios from "axios";
import { isClear } from "./helpers";
import moment from "moment/moment";

const profState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title: "Ректор буйруғига асосан чет давлатга юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
  },
};

const LanguageCenter = () => {
  const [data, setData] = useState(null);

  const getData = () => {
    axios
      .get(
        `api/v1/attendance-control/list/?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    getData();
  }, []);

  const [isPosting, setIsPosting] = useState(false);
  const [date, setDate] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [prof, setProf] = useState(profState);

  const postResults = () => {
    setIsPosting(true);
    axios
      .post("api/v1/attendance-control/", {
        time: date,
        base_attendances: [
          {
            type: "Профессор-ўқитувчилар",
            all_count: Number(prof.in) + Number(prof.out),
            attended_count: Number(prof.in),
            not_attendeds_count: Number(prof.out),
            ill_count: Number(prof.desc.ill.value),
            go_away_count: Number(prof.desc.abroad.value),
            causeless_count: Number(prof.desc.noIdea.value),
          },
        ],
      })
      .then((res) => {
        if (res.status == 201) {
          toast.success("Saqlandi");
          getData();
          setIsSubmitted(false);
        }
        setIsPosting(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error("Ma'lumot kiritish vaqti tugallangan!");
        }
        setIsPosting(false);
      });
  };

  useEffect(() => {
    setProf(profState);
  }, [date]);

  useEffect(() => {
    if (data?.length && date === "12:00") {
      for (let d of data) {
        if (d.time === "12:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Профессор-ўқитувчилар") {
              setProf({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title: "Ректор буйруғига асосан чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                },
              });
            }
          }
        }
      }
    }
    if (data?.length && date === "18:00") {
      for (let d of data) {
        if (d.time === "18:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Профессор-ўқитувчилар") {
              setProf({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title: "Ректор буйруғига асосан чет давлатга юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                },
              });
            }
          }
        }
      }
    }
  }, [data, date]);


  return (
    <div className="mt-36 container mx-auto text-center py-4">
      <ToastContainer />
      <div className="font-bold text-lg">
        Тошкент давлат иқтисодиёт университети тўғрисида кунлик
      </div>
      <div className="font-bold text-lg">МАЪЛУМОТ</div>

      <FormControl>
        <RadioGroup
          onChange={(e) => setDate(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="12:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 12:00 ҳолатига`}
          />
          <FormControlLabel
            value="18:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 18:00 ҳолатига`}
          />
        </RadioGroup>
      </FormControl>

      {/* Table */}
      <table className={`${!date && "hidden"} mt-4`}>
        <thead>
          <tr>
            <th rowSpan={2} colSpan={1}>
              т/р
            </th>
            <th rowSpan={2} colSpan={4}>
              Қатнашчилар тоифаси
            </th>
            <th rowSpan={2} colSpan={2}>
              Факультет 1/2-смена бўйича Жами
            </th>
            <th rowSpan={1} colSpan={4}>
              Шундан
            </th>
            <th rowSpan={2} colSpan={5}>
              Изоҳ
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Дарсга (ишга) қатнашганлар сони</th>
            <th colSpan={2}>Дарсга (ишга) қатнашмаганлар сони</th>
          </tr>
        </thead>
        {/*  */}
        <tbody>
          <tr>
            <td colSpan={1}>4</td>
            <td colSpan={4}>Профессор-ўқитувчилар</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="profAll"
                className={`absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={Number(prof.in) + Number(prof.out)}
                id="profAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="proTrue"
                className={`${
                  isSubmitted && !prof.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                value={prof.in}
                onChange={(e) => setProf({ ...prof, in: e.target.value })}
                type="number"
                id="proTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="proFalse"
                className={`${
                  isSubmitted && prof.out === "" && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full `}
              ></label>
              <input
                value={prof.out}
                disabled
                type="number"
                id="proFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={prof} callback={setProf} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(prof.desc).map((item) => (
                  <li key={item}>
                    {prof.desc[item].title} - <b>{prof.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={`${!date && "hidden"} mt-4`}>
        <Button
          onClick={() => {
            setIsSubmitted(true);
            if (isClear(prof)) {
              if (true) {
                postResults();
              } else {
                toast.error(
                  "Дарсга (ишга) қатнашмаганлар сони Изоҳга тенг емас!"
                );
              }
            }
          }}
          disabled={isPosting}
          variant="contained"
          size="large"
          color="success"
        >
          Saqlash
        </Button>
      </div>
    </div>
  );
};

export default LanguageCenter;
