import { connect } from "formik";
import React, { useEffect } from "react";

const Test = ({appData}) => {
     // for test
  useEffect(() => {
    // const socket = new WebSocket("ws://10.10.115.102:8003/ws/chat/nimadir", {
    //   headers: {
    //     Authorization: `Bearer ${appData?.user?.access}`,
    //   },
    // });
    const user = JSON.stringify(appData?.user)
    const socket = new WebSocket(`ws://10.10.115.102:8004/ws/chat/room_` + appData?.user.id);
    socket.onopen = () => console.log("connected")
    socket.onerror = () => console.log('ERROR')
    socket.onclose = () => console.log('Close')
    socket.onmessage = (message) => {
      const data = JSON.parse(message?.data)
    }
  }, []);
  // end test line
  return <div>Test</div>;
};

const mapStateToProps = (state) => {
  return {
    appData: state.app,
  };
};

export default connect(mapStateToProps)(Test);




// {
//         "cardTitle": "Booking",
//         "pageURL": "/booking"
//       },

// {
//         "cardTitle": "Бронирование",
//         "pageURL": "/booking"
//       },


// {
//         "cardTitle": "Bron qilish",
//         "pageURL": "/booking"
//       },