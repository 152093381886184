import React, {useState} from 'react'
import { RiChatSmileFill, RiCloseFill } from "react-icons/ri"
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { ComplaintAction } from '../../../redux';
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux'
import {useTranslation} from 'react-i18next'
import audio from '../../media/pop.mp3'
import img2 from '../../media/tgbg.jpg'

function ContactButton1({ComplaintAction}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const options = [
    { value: "Complaint", label: t('registrar.Complaint') },
    { value: "Offer", label: t('registrar.Offer') },
  ];
  const [selectedOption, setSelectedOption] = useState(options[1].value);
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];

  function SendApply(complaintState) {
    ComplaintAction('appeal', complaintState, navigate, enqueueSnackbar); 
  }
  const [contactIcon, setContactIcon] = useState(false)
  const iconClick = () => {
    new Audio(audio).play();
    setContactIcon(!contactIcon);
  }
  return (
    <>
    <div className="fixed flex justify-center items-center sm:bottom-[0.5vw] bottom-[2.5vw] sm:right-[1vw] right-[3vw] lg:w-[3.5vw] md:w-[4.5vw] sm:w-[5.5vw] w-[13.5vw] lg:h-[3.5vw] md:h-[4.5vw] sm:h-[5.5vw] h-[13.5vw] cursor-pointer rounded-[50%] bg-[#8BC0BA] " onClick={()=>{iconClick();}} >
      {contactIcon ? 
        <RiCloseFill className="text-white lg:text-[2.2vw] md:text-[2.7vw] sm:text-[3.7vw] text-[7.7vw]" />
        :<RiChatSmileFill className="text-white lg:text-[2.2vw] md:text-[2.7vw] sm:text-[3.7vw] text-[7.7vw]" />
        }
    </div>
    <div className={ (contactIcon ? "sm:right-[1.5vw] right-[5vw] " : "right-[-100vw] " ) +"fixed lg:bottom-[4.3vw] md:bottom-[5.3vw] sm:bottom-[6.5vw] bottom-[18vw] xl:w-[23vw] lg:w-[27vw] md:w-[32vw] sm:w-[40vw] w-[90vw] xl:h-[78vh] lg:h-[80vh] md:h[80vh] sm:h-[80vh] h-[80vh] z-50 rounded-t-[0.9vw] overflow-y-scroll transition-all duration-500 sm:py-[0.9vw] py-[3vw] md:px-[0.8vw] sm:px-[1.1vw] px-[2vw] "} style={{backgroundImage: `url(${img2})`, backgroundSize: "cover"}} >
        <div className='text-white xl:text-[0.9vw] lg:text-[1.1vw] md:text-[1.4vw] sm:text-[1.7vw] text-[4vw] lg:rounded-[0.4vw] sm:rounded-[0.6vw] rounded-[1.6vw] bg-black bg-opacity-25 lg:py-[0.3vw] sm:py-[0.5vw] py-[2.5vw] lg:px-[0.4vw] sm:px-[0.8vw] px-[2.5vw] ' >
          <p className='opacity-100' > {t('registrar.text1')} </p>
          <p> {t('registrar.text2')} </p>
        </div>
        <div className="col-span-12 bg-[#115f77] bg-opacity-30 lg:py-[1vw] sm:py-[1.5vw] pb-[3.5vw] lg:px-[0.5vw] sm:px-[1vw] px-[2.5vw] lg:rounded-[0.4vw] sm:rounded-[0.8vw] rounded-[1.8vw] sm:mt-[1.5vw] mt-[6.5vw] xl:text-[1.1vw] lg:text-[1.2vw] md:text-[1.4vw] sm:text-[1.8vw] text-[4vw] ">
                <Formik
                  initialValues={{
                    full_name: "",
                    phone: "",
                    comment: "",
                    appeal_type: selectedOption
                  }}
                  validationSchema={Yup.object().shape({
                    full_name: Yup.string().required(t('registrar.fullname_error')),
                    phone: Yup.string().required(t('registrar.phone_error')),
                    comment: Yup.string().required(t('registrar.comment_error'))
                  })}
                  onSubmit={(complaintState) => {
                    SendApply(complaintState);
                    complaintState.full_name = ""
                    complaintState.phone = ""
                    complaintState.comment = ""
                  }}
                >
                  {({ errors, touched, handleBlur, handleChange }) => (
                    <Form className="grid grid-cols-12 gap-[0.5vw]">
                      <div className="col-span-12 sm:pt-0 pt-[3vw]">
                        <label className="text-black font-semibold " htmlFor="full_name" >
                          {t('registrar.fullname')}
                        </label>
                        <Field
                          name="full_name"
                          type="text"
                          placeholder={t('registrar.fullname')}
                          className={
                            "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm lg:py-[0.5vw] md:py-[0.8vw] sm:py-[1vw] py-[1.5vw] lg:px-[0.7vw] md:px-[1vw] sm:px-[1.2vw] px-[1.7vw] sm:mb-[0.3vw] mb-[1vw] lg:mb-0 sm:mt-[0.3vw] mt-[2.3vw] " +
                            (errors.full_name && touched.full_name
                              ? "border-red-500"
                              : "")
                          }
                        />
                        {errors.full_name && <span className="text-red-500 lg:text-[1vw] md:text-[1.2vw] sm:sm:text-[1.5vw] text-[3.5vw] sm:sm:ml-[0.5vw] ml-[1.5vw] " >*{errors.full_name}</span>}
                      </div>
                      <div className="col-span-12 sm:pt-0 pt-[3vw]">
                        <label className="text-black font-semibold" htmlFor="phone" >
                        {t('registrar.phone')}
                        </label>
                        <Field name="phone" >
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              name="phone"
                              id="phone"
                              placeholder="+998 __ ______"
                              type="tel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm lg:py-[0.5vw] md:py-[0.8vw] sm:py-[1vw] py-[1.5vw] lg:px-[0.7vw] md:px-[1vw] sm:px-[1.2vw] px-[1.7vw] lg:mb-0 sm:mb-[0.3vw] mb-[1vw] sm:mt-[0.3vw] mt-[2.3vw]" +
                                (errors.phone && touched.phone
                                  ? " border-red-500"
                                  : "")
                              }
                            />
                          )}
                        </Field>
                        {errors.phone && <span className="text-red-500 lg:text-[1vw] md:text-[1.2vw] sm:text-[1.5vw] text-[3.5vw] sm:ml-[0.5vw] ml-[1.5vw]" >*{errors.phone}</span>}
                      </div>
                      <div className="col-span-12 sm:pt-0 pt-[3vw]">
                        <label className="text-black font-semibold" htmlFor="appeal_type" >
                        {t('registrar.to_whom')}
                        </label>
                        <Select
                          name='appeal_type'
                          options={options}
                          defaultValue={options[1]}
                          onChange={(e) => setSelectedOption(e.value)}
                          className="sm:mt-[0.3vw] mt-[2.3vw]"
                        />
                      </div>
                      <div className="col-span-12 sm:pt-0 pt-[3vw]">
                        <label className="text-black font-semibold" htmlFor="comment" >
                          {t('registrar.comment')}
                        </label>
                        <Field
                          name="comment"
                          type="text"
                          as="textarea"
                          placeholder={t('registrar.comment')}
                          className={
                            "w-full form-control focus:outline-[#1474B2] border border-[#E2E5E9] rounded-sm lg:py-[0.5vw] md:py-[0.8vw] sm:py-[1vw] py-[1.5vw] lg:px-[0.7vw] md:px-[1vw] sm:px-[1.2vw] px-[1.7vw] lg:mb-0 sm:mb-[0.3vw] mb-[1vw] sm:mt-[0.3vw] mt-[2.3vw]" +
                            (errors.comment && touched.comment
                              ? " border-red-500"
                              : "")
                          }
                        />
                        {errors.comment && <span className="text-red-500 lg:text-[1vw] md:text-[1.2vw] sm:text-[1.5vw] text-[3.5vw] sm:ml-[0.5vw] ml-[1.5vw]" >*{errors.comment}</span>}
                      </div>
                      {/* submit */}
                      <div className="col-span-12 text-right sm:pt-0 pt-[3vw]">
                        <button
                          type="submit"
                          className="bg-[#1cb5e0] hover:bg-[#263d8a] text-white border mr-[15px] border-[#E2E5E9] py-[5px] px-[10px] rounded-sm transition"
                        >
                          {t('registrar.sendButton')}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
    </div>
    </>
  )
}

const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ComplaintAction: (Url, complaintState, navigate, enqueueSnackbar) => dispatch(ComplaintAction(Url, complaintState, navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ContactButton1)