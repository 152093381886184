import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ru from "@fullcalendar/core/locales/ru";
import { Formik } from "formik";
import * as Yup from "yup";
import OutsideClickHandler from "react-outside-click-handler";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";


const Booking = ({ appData }) => {
 

  const { t, i18n } = useTranslation();
  const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ];
  const notify = (message) => toast.error(message);
  const [isRightUser, setIsRightUser] = useState(false);
  const [currentTab, setCurrentTab] = useState("");
  const [roomsList, setRoomsList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [roomDropdown, setRoomDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModalWithEventId, setShowDeleteModalWithEventId] =
    useState(false);
  const [showEventModalWithDetails, setShowEventModalWithDetails] =
    useState(false);

  function postBookingEvent(values, callbackFun) {
    const post_data = { ...values, room: values.room.id };
    axios
      .post("/api2/v2/booking-create/", post_data)
      .then(() => {
        getEventsList();
        setShowModal(false);
        callbackFun();
      })
      .catch((err) => {
        notify(err.response.data.error[0]);
      });
  }

  function deleteBookingEvent() {
    axios
      .delete(`/api2/v2/booking-delet/${showDeleteModalWithEventId}/`)
      .then(() => {
        setShowDeleteModalWithEventId(false);
        getEventsList();
      })
      .catch(() => {
        notify("Siz buni o'chira olmaysiz");
        setShowDeleteModalWithEventId(false);
      });
  }

  function getEventsList() {
    axios.get("/api2/v2/booking-list/").then((res) => setEventsList(res.data));
  }

  useEffect(() => {
    function getRoomsList() {
      axios
        .get("/api2/v2/room-list/")
        .then((res) => {
          setRoomsList(res.data);
        })
        .catch(() => {
          notify("Bu bo'limga ruhsat yo'q");
          setIsRightUser(true);
        });
    }
    getRoomsList();
    getEventsList();
  }, []);

  useEffect(() => {
    setCurrentTab(roomsList[0]?.name);
  }, [roomsList]);

  useEffect(() => {
    let calendarEvents = [];
    calendarEvents = eventsList?.map((event) => {
      return {
        title: event.room,
        phone: event.phone,
        description: event.description,
        open_time: event.open_time,
        close_time: event.close_time,
        start: event.open_time,
        end: event.close_time,
      };
    });
    setCalendarEvents(calendarEvents);
  }, [eventsList]);

  if (isRightUser) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          404
        </h1>
      </div>
    );
  }

  return (
    // <div className="w-full xl:my-36 my-20 grid grid-cols-12">
    <div className="relative w-full my-[150px] px-[25px]">
      <ToastContainer />
      {/* // <div className="lg:col-start-3 lg:col-span-8 col-span-12"> */}
      <div className="w-full">
        {/* tabs */}
        <div className="table-top flex items-center justify-between text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul
            className={`${
              !roomsList?.length && "hidden"
            } flex overflow-auto -mb-px`}
          >
            {roomsList?.map((room) => (
              <li
                key={room.id}
                className={`${
                  currentTab === room.name
                    ? "border-blue-500 text-blue-500"
                    : "border-transparent"
                } cursor-pointer mr-2 inline-block p-4 rounded-t-lg border-b-2 hover:text-gray-600 hover:border-blue-300 `}
                onClick={() => setCurrentTab(room.name)}
              >
                {room.name}
              </li>
            ))}
          </ul>
          {/* <button
            onClick={() => setShowModal(true)}
            type="button"
            className="flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            band qilish
            <svg
              className="w-4 h-4 ml-[5px]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
          </button> */}
        </div>
        {/* table */}
        <div className="table-top overflow-x-auto relative shadow-md sm:rounded-lg mt-1">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  {t("booking.by_whom")}
                </th>
                <th scope="col" className="py-3 px-6">
                  {t("booking.phone")}
                </th>
                <th scope="col" className="py-3 px-6">
                  {t("booking.start_time")}
                </th>
                <th scope="col" className="py-3 px-6">
                  {t("booking.end_time")}
                </th>
                <th scope="col" className="py-3 px-6">
                  {t("booking.description")}
                </th>
                <th scope="col" className="py-3 px-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {eventsList?.map((event) => (
                <tr
                  key={event.id}
                  className={`${
                    event.room !== currentTab && "hidden"
                  } bg-white border-b dark:bg-gray-800 dark:border-gray-700`}
                >
                  <td className="py-4 px-6 whitespace-nowrap">{event.user}</td>
                  <td className="py-4 px-6 whitespace-nowrap">{event.phone}</td>
                  <td className="py-4 px-6 whitespace-nowrap">
                    {moment(event.open_time).format("DD MM YYYY hh:mm A")}
                  </td>
                  <td className="py-4 px-6 whitespace-nowrap">
                    {moment(event.close_time).format("DD MM YYYY hh:mm A")}
                  </td>
                  <td className="py-4 px-6">{event.description}</td>
                  <td className="py-4 px-6">
                    <button
                      type="button"
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      onClick={() => setShowDeleteModalWithEventId(event.id)}
                    >
                      <svg
                        className="w-4 h-4"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Calendar */}
        <div className="mt-[50px]">
          {calendarEvents?.length ? (
            <FullCalendar
              plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={calendarEvents}
              displayEventEnd
              eventClick={(e) => {
                setShowEventModalWithDetails({
                  ...e.event._def.extendedProps,
                  room: e.event._def.title,
                  ...e.event._instance.range,
                });
              }}
              dateClick={(e) => {
                setShowModal({
                  open_time: e.date,
                  show: true,
                });
              }}
              locales={[ru]}
              locale={
                i18n.language === "ru" || i18n.language === "uz" ? "ru" : "en"
              }
            />
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Modal Form */}
      <div
        style={{ backgroundColor: "rgba(0,0,0, 0.3)" }}
        className={`${
          showModal?.show ? "opacity-100 z-[9999]" : "opacity-0 -z-[9999]"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 w-full md:inset-0 h-modal md:h-full flex justify-center items-center`}
      >
        <div
          className={`transition-all duration-200 relative p-4 w-full max-w-xl h-full md:h-auto`}
          // className={`${
          //   showModal ? "opacity-100" : "opacity-0"
          // } transition-all duration-200 relative p-4 w-full max-w-xl h-full md:h-auto`}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="py-6 px-6 lg:px-8">
              <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                {t("booking.book")}
              </h3>
              <Formik
                enableReinitialize
                initialValues={{
                  room: "",
                  description: "",
                  open_time: moment(showModal?.open_time).format(
                    "YYYY-MM-DDTh:mm"
                  ),
                  close_time: "",
                  phone: "",
                }}
                validationSchema={Yup.object({
                  room: Yup.object().required(),
                  description: Yup.string().required(),
                  open_time: Yup.string().required(),
                  close_time: Yup.string().required(),
                  phone: Yup.string().required(),
                })}
                onSubmit={(values, { resetForm }) => {
                  postBookingEvent(values, resetForm);
                }}
              >
                {(props) => (
                  <>
                    <button
                      onClick={() => {
                        props.resetForm();
                        setShowModal(false);
                      }}
                      type="button"
                      className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                      data-modal-toggle="authentication-modal"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close</span>
                    </button>
                    <form className="space-y-6" onSubmit={props.handleSubmit}>
                      <div>
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          {t("booking.rooms")}
                        </label>

                        <div className="relative">
                          <button
                            className={`${
                              props.errors?.room ? "bg-red-600" : "bg-blue-700"
                            } text-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                            type="button"
                            onClick={() => setRoomDropdown(!roomDropdown)}
                          >
                            {props.values.room?.name
                              ? props.values.room?.name
                              : "Xonalar"}
                            <svg
                              className="ml-2 w-4 h-4"
                              aria-hidden="true"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </button>
                          <OutsideClickHandler
                            onOutsideClick={() => setRoomDropdown(false)}
                          >
                            <div
                              className={`${
                                !roomDropdown && "hidden"
                              } absolute top-full z-10 w-60 bg-white rounded divide-y divide-gray-100 shadow-md dark:bg-gray-700 dark:divide-gray-600`}
                            >
                              <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
                                {roomsList?.map((room) => (
                                  <li key={room.id}>
                                    <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                      <div className="flex items-center h-5">
                                        <input
                                          id={room.name}
                                          name="room"
                                          type="radio"
                                          checked={
                                            props.values.room?.id === room.id
                                          }
                                          value={room}
                                          onClick={() => {
                                            props.setFieldValue("room", room);
                                            setRoomDropdown(false);
                                          }}
                                          className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        />
                                      </div>
                                      <div className="cursor-pointer ml-2 text-sm w-full">
                                        <label
                                          htmlFor={room.name}
                                          className="font-medium text-gray-900 dark:text-gray-300"
                                        >
                                          <div>{room.name}</div>
                                          {/* <p
                                    id="helper-radio-text-4"
                                    className="text-xs font-normal text-gray-500 dark:text-gray-300"
                                  >
                                    Some helpful instruction goes over here.
                                  </p> */}
                                        </label>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </OutsideClickHandler>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="phone"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          {t("booking.phone_number")}
                        </label>
                        <MaskedInput
                          mask={phoneNumberMask}
                          name="phone"
                          id="phone"
                          placeholder="(__) ___ __ __"
                          type="tel"
                          value={props.values.phone}
                          onChange={props.handleChange}
                          className={`${
                            props.errors.phone
                              ? "border-red-600"
                              : "border-gray-200"
                          } mt-1 px-[15px] py-[10px] w-full rounded-md border shadow-sm sm:text-sm focus:outline-gray-300`}
                        />
                      </div>
                      <div className="flex gap-2 flex-col md:flex-row">
                        <div>
                          <label
                            htmlFor="open_time"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("booking.start_time")}
                          </label>
                          <input
                            type="datetime-local"
                            name="open_time"
                            id="open_time"
                            className={`${
                              props.errors.open_time
                                ? "border-red-600"
                                : "border-gray-200"
                            } bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                            value={props.values.open_time}
                            onChange={props.handleChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="close_time"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {t("booking.end_time")}
                          </label>
                          <input
                            type="datetime-local"
                            name="close_time"
                            id="close_time"
                            className={`${
                              props.errors.close_time
                                ? "border-red-600"
                                : "border-gray-200"
                            } bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                            value={props.values.close_time}
                            onChange={props.handleChange}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="description"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                        >
                          {t("booking.description")}
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          rows="4"
                          className={`${
                            props.errors.description
                              ? "border-red-600"
                              : "border-gray-200"
                          } bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                          placeholder="Sababi..."
                          value={props.values.description}
                          onChange={props.handleChange}
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={(e) => {
                          // e.preventDefault();
                        }}
                      >
                        {t("booking.book")}
                      </button>
                    </form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      {/* Warrning Modal */}
      <div
        id="popup-modal"
        style={{ backgroundColor: "rgba(0,0,0, 0.3)" }}
        className={`${
          !showDeleteModalWithEventId
            ? "opacity-0 -z-[9999]"
            : "opacity-100 z-[9999]"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div className="relative p-4 w-full h-full flex justify-center items-center">
          <div
            className={`transition-all duration-200 relative bg-white rounded-lg shadow dark:bg-gray-700`}
            // className={`${
            //   !showDeleteModalWithEventId ? "opacity-0" : "opacity-100"
            // } transition-all duration-200 relative bg-white rounded-lg shadow dark:bg-gray-700`}
          >
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowDeleteModalWithEventId(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <svg
                aria-hidden="true"
                className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                {t("booking.delete_warning")}
              </h3>
              <button
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                onClick={deleteBookingEvent}
              >
                {t("booking.yes")}
              </button>
              <button
                type="button"
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                onClick={() => setShowDeleteModalWithEventId(false)}
              >
                {t("booking.no")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Event Modal */}
      <div
        style={{ backgroundColor: "rgba(0,0,0, 0.3)" }}
        className={`${
          !showEventModalWithDetails?.room && "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[5000] md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div className="relative p-4 w-full h-full flex justify-center items-center">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 pt-[20px]">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              onClick={() => setShowEventModalWithDetails(false)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
              <div class="overflow-x-auto relative">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t("booking.room")}:
                      </th>
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {showEventModalWithDetails?.room}
                      </th>
                    </tr>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t("booking.phone")}:
                      </th>
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {showEventModalWithDetails?.phone}
                      </th>
                    </tr>
                    <tr class="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t("booking.start_time")}:
                      </th>
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {moment(showEventModalWithDetails?.open_time).format(
                          "DD MM YYYY hh:mm A"
                        )}
                      </th>
                    </tr>
                    <tr class="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t("booking.end_time")}:
                      </th>
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {moment(showEventModalWithDetails?.close_time).format(
                          "DD MM YYYY hh:mm A"
                        )}
                      </th>
                    </tr>
                    <tr class="bg-white dark:bg-gray-800">
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t("booking.description")}:
                      </th>
                      <th
                        scope="row"
                        class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {showEventModalWithDetails?.description}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appData: state.app,
  };
};

export default connect(mapStateToProps)(Booking);
