import React from 'react'
import {useTranslation} from 'react-i18next'

function DuplicateDiploma() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide mb-3'>
                {t('duplicate_diploma.name')}
            </p>
            <p className='xl:text-lg text-[#263d8a] sm:text-base text-sm tracking-wide sm:mb-3 mb-1 lg:px-0 px-3' >
                {t('duplicate_diploma.part1')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3' >
                {t('duplicate_diploma.part2')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part3')}  <a className='text-blue-500 underline italic' href='https://my.gov.uz/oz'>{t('duplicate_diploma.part4')}</a> {t('duplicate_diploma.part5')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part6')} 
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part7')} 
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part8')} 
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part9')} 
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3 lg:ml-5 ml-2' >
                {t('duplicate_diploma.part10')} 
            </p>
            
        </div>
    </div>
  )
}

export default DuplicateDiploma