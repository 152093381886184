import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "./Components/Loader/Loader";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import ContractDownload from "./Components/ContractDownload/ContractDownload";
import OfferPaper from "./Components/OfferPaper/OfferPaper";
import SimpleDownload from "./Components/SimpleDownload/SimpleDownload";
import ContactRegistrar from "./Components/ContactRegistrar/ContactRegistrar";
import Profile from "./Components/Profile/Profile";
import Debt from "./Components/Debt/Debt";
import Conduct from "./Components/Conduct/Conduct";
import Syllabuses from "./Components/Syllabuses/Syllabuses";
import AcademicCalendar from "./Components/AcademicCalendar/AcademicCalendar";
import GoverningBody1 from "./Components/GoverningBody/GoverningBody1";
import Department from "./Components/Department/Department";
import Economy from "./Components/Economy/Economy";
import Tourism from "./Components/Tourism/Tourism";
import Corporate from "./Components/Corporate/Corporate";
import Finance from "./Components/Finance/Finance";
import DigitalEconomy from "./Components/DigitalEconomy/DigitalEconomy";
import AcademicLeave from "./Components/AcademicLeave/AcademicLeave";
import Booking from "./Components/Booking";
import Group from "./Components/Group/Group";
import Dormitory from "./Components/Dormitory/Dormitory";
import AcademicMobility from "./Components/AcademicMobility/AcademicMobility";
import StudyPlans2 from "./Components/StudyPlans/StudyPlans2";
import ScientificConferences from "./Components/ScientificConferences/ScientificConferences";
import ContactRegistrar1 from "./Components/ContactRegistrar/ContactRegistrar copy";
import ExchangePrograms from "./Components/ExchangePrograms/ExchangePrograms";
import DuplicateDiploma from "./Components/DuplicateDiploma/DuplicateDiploma";
import AboutUniversity from "./Components/AboutUniversity/AboutUniversity";
import LearningAgreement from "./Components/LearningAgreement/LearningAgreement";
import SubjectsRegistration from "./Components/SubjectsRegistration/SubjectsRegistration";
import AppealingExams from "./Components/AppealingExams/AppealingExams";
import CreditRecognition from "./Components/CreditRecognition/CreditRecognition";
import Certificate from "./Components/Certificate";
import Test from "./Components/Test";
import OfferPaper2 from "./Components/OfferPaper2/OfferPaper2";
import ParentAppeal from "./Components/ParentAppeal/ParentAppeal";
// import Uz from "./Components/Form/Uz";
import Attendance from "./Components/Attendance";

const useStyles = makeStyles((theme) => ({
  snackbarProvider: {
    fontSize: "0.8vw !important",
    top: "5vw",
    position: "relative !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.6vw !important",
      left: "30vw",
      top: "14vw",
      with: "70vw",
      marignTop: "0 !important",
      marign: "1vw !important",
      padding: "1vw !important",
    },
  },
}));

function App() {
  const classes = useStyles();
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <SnackbarProvider
          className={classes.snackbarProvider}
          autoHideDuration={1400}
          maxSnack={3}
        >
          <Header />
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="login" element={<Login />} />
            <Route
              path="contract"
              element={
                <ProtectedRoute pathFrom="contract">
                  {" "}
                  <ContractDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="offer"
              element={
                <ProtectedRoute pathFrom="offer">
                  {" "}
                  <OfferPaper />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="offer2"
              element={
                <ProtectedRoute pathFrom="offer">
                  {" "}
                  <OfferPaper2 />{" "}
                </ProtectedRoute>
              }
            />
            <Route path="parent-appeal" element={<ParentAppeal />} />
            <Route
              path="reference"
              element={
                <ProtectedRoute pathFrom="reference">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="referencef26"
              element={
                <ProtectedRoute pathFrom="referencef26">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="offerdownload"
              element={
                <ProtectedRoute pathFrom="offerdownload">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="referencef28"
              element={
                <ProtectedRoute pathFrom="referencef28">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="diploma"
              element={
                <ProtectedRoute pathFrom="diploma">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="transcript"
              element={
                <ProtectedRoute pathFrom="transcript">
                  {" "}
                  <SimpleDownload />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="registrar"
              element={
                <ProtectedRoute pathFrom="registrar">
                  {" "}
                  <ContactRegistrar1 />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="profile"
              element={
                <ProtectedRoute pathFrom="profile">
                  {" "}
                  <Profile />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="debt"
              element={
                <ProtectedRoute pathFrom="debt">
                  {" "}
                  <Debt />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="booking"
              element={
                <ProtectedRoute pathFrom="booking">
                  {" "}
                  <Booking />{" "}
                </ProtectedRoute>
              }
            />
            <Route
              path="certificate"
              element={
                <ProtectedRoute pathFrom="certificate">
                  {" "}
                  <Certificate />{" "}
                </ProtectedRoute>
              }
            />
            <Route path="academic_leave" element={<AcademicLeave />} />
            <Route path="studyplans" element={<StudyPlans2 />} />
            <Route path="group" element={<Group />} />
            <Route path="syllabuses" element={<Syllabuses />} />
            <Route path="dormitory" element={<Dormitory />} />
            <Route path="calendar" element={<AcademicCalendar />} />
            <Route path="academic_mobility" element={<AcademicMobility />} />
            <Route path="conduct" element={<Conduct />} />
            <Route path="exchange_programs" element={<ExchangePrograms />} />
            <Route path="duplicate_diploma" element={<DuplicateDiploma />} />
            <Route path="learning_agreement" element={<LearningAgreement />} />
            <Route
              path="subjects_registration"
              element={<SubjectsRegistration />}
            />
            <Route path="appealing_exams" element={<AppealingExams />} />
            <Route path="credit_recognition" element={<CreditRecognition />} />
            <Route
              path="scientific_conferences"
              element={<ScientificConferences />}
            />
            <Route path="about_university" element={<AboutUniversity />} />
            <Route path="governing_body" element={<GoverningBody1 />} />
            <Route path="department" element={<Department />} />
            <Route path="economy" element={<Economy />} />
            <Route path="tourism" element={<Tourism />} />
            <Route path="corporate" element={<Corporate />} />
            <Route path="finance" element={<Finance />} />
            <Route path="digital_economy" element={<DigitalEconomy />} />
            <Route path="example" element={<Test />} />
            {/* <Route path="form" element={<Uz />} /> */}
            <Route path="attendance" element={<Attendance />} />
          </Routes>
        </SnackbarProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
