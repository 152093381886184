import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next'
import { IoIosPeople} from "react-icons/io"
import { FaChalkboardTeacher, FaUserGraduate, FaCubes, FaHandshake, FaUserCheck} from "react-icons/fa"
import { GiTeacher } from "react-icons/gi"
import { BsFillSignpost2Fill } from "react-icons/bs"
import bluerectanglelogo from '../../media/bluerectanglelogo.png'
import homeBac from '../../media/homeBac.png'
const useStyles = makeStyles((theme) => ({
    
    home3OuterGrid: {
        backgroundColor: "#ECF9FD",
        backgroundImage: `url(${homeBac})`,
        backgroundSize: "auto 120%",
        minHeight: "80vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0% 0%",
        overflow: "visible",
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "auto 80%",
            backgroundPosition: "50% 0%",
            minHeight: "48vh",
        },
    },
    home3InnerGrid: {
        backgroundColor: "white",
        boxShadow: "-0.4vw 0.52vw 1.9vw 0.26vw rgb(59 70 78 / 15%)",
        padding: "2.94vw 3.67vw",
        marginRight: "2.62vw",
        [theme.breakpoints.down('xs')]: {
            marginRight: "2vw",
            marginLeft: "2vw",
            background: "rgba(255,255,255,0.65)",
            marginTop: "-5vw"
            
        },
    },
    home3InnerGridDiv: {
        width: "100%",
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            alignItems: "flex-start",
        },
    },
    home3InnerGridImg: {
        width: "4.72vw",
        [theme.breakpoints.down('xs')]: {
            width: "10vw",
            marginTop: "1.2vw"
        },
    },
    home3InnerGridImgTitle: {
        width: "100%",
        fontSize: "1.36vw",
        fontWeight: 600,
        marginTop: "0.52vw",
        marginLeft: "0.52vw",
        color: "#404040",
        [theme.breakpoints.down('xs')]: {
            fontSize: "4vw",
        },
    },
    home3InnerGridDescr: {
        fontSize: "0.84vw",
        lineHeight: 1.7,
        margin: "0.78vw 0 1.3vw 0",
        color: "#404040",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.2vw",
        },
    },
    home3InnerGridInfoGrid: {
        padding: "0.4vw", 
        lineHeight: 1.4,
        display: "flex", 
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "rgba(10,151,255,0.03)"
        },
        [theme.breakpoints.down('xs')]: {
            padding: "1vw 0vw", 
        },
    },
    home3InnerGridInfoGridIcon: {
        fontSize: "1.83vw",
        color: "#046DB4",
        [theme.breakpoints.down('xs')]: {
            fontSize: "4vw",
        },
    },
    home3InnerGridInfoGridText: {
        marginLeft: "0.52vw",
        fontSize: "0.68vw",
        fontWeight: 500,
        letterSpacing: "0.078vw",
        lineHeight: 1.5,
        color: "#5c5c5c",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2vw",
            marginLeft: "1vw",
        },
    },
    home3InnerGridInfoGridQuen: {
        marginLeft: "0.26vw",
        fontSize: "0.78vw",
        fontWeight: 500,
        letterSpacing: "0.1vw",
        lineHeight: 1.5,
        color: "#046DB4",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.2vw",
            marginLeft: "1vw",
        },
    },
}))
function AboutPage() {
    const classes = useStyles();
    const {t}=useTranslation();
    const Icons = [IoIosPeople, FaChalkboardTeacher, FaUserGraduate, FaUserCheck, GiTeacher, FaHandshake, BsFillSignpost2Fill, FaCubes]
    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.home3OuterGrid} >
            <Grid container item xs={12} direction="row" justifyContent="flex-end" alignItems="flex-start" >
                <Grid container item sm={5} xs={12} direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.home3InnerGrid} >
                        <div className={classes.home3InnerGridDiv}>
                            <img src={bluerectanglelogo} alt="Text" className={classes.home3InnerGridImg} />
                            <p className={classes.home3InnerGridImgTitle} > {t('home3.boldText')}</p>
                        </div>
                        <p className={classes.home3InnerGridDescr} >{t('home3.lightText')}</p>
                        {
                            t('home3.buttons', {returnObjects: true}).map((button, index) => {
                                const { name, number } = button;
                                const ButtonIcon = Icons[index]
                                return(
                                    <Grid key={index} container item xs={6} direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                        <div className={classes.home3InnerGridInfoGrid} >
                                            <ButtonIcon className={classes.home3InnerGridInfoGridIcon} />
                                            <span className={classes.home3InnerGridInfoGridText} >{name}</span>
                                            <span className={classes.home3InnerGridInfoGridQuen} >{number}</span>
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AboutPage
