import React from 'react'
import {useTranslation} from 'react-i18next'

function CreditRecognition() {
    const {t}=useTranslation();
  return (
    <div className="w-full xl:my-36 my-20 grid grid-cols-12">
        <div className="lg:col-start-3 lg:col-span-8 col-span-12">
            <p className='text-center xl:text-2xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide md:mb-6 mb-3'>
                {t('credit_recognition.name')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3' >
                {t('credit_recognition.part1')}
            </p>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3' >
                {t('credit_recognition.part2')}
            </p>
            <ul className='list-inside pl-3 pr-3 sm:text-base text-sm italic tracking-normal mb-4'>
                {
                    t('credit_recognition.part3', {returnObjects: true}).map((card, index) => {
                        const {text} = card
                        return(
                            <li key={index} className='pl-1 mt-2' >
                                - {text}
                            </li>
                        )
                    })
                }
            </ul>
            <p className='xl:text-lg sm:text-base text-sm tracking-wide sm:mb-2 mb-1 lg:px-0 px-3' >
                {t('credit_recognition.part4')}
            </p>
        </div>
    </div>
  )
}

export default CreditRecognition