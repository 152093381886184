import React from 'react'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next'
import cross from '../../media/cross.png'
import holiday from '../../media/90.svg'
import indexbg from '../../media/indexbg.svg'

const useStyles = makeStyles((theme) => ({
    homeOuterGrid: {
        margin: 0,
        minWidth: "100%",
        height: "38vw !important",
        backgroundImage: `url(${indexbg})`,
        backgroundPosition: "0 20%",
        backgroundRepeat: "space",
        backgroundSize: "140%",
        overflow: "hidden",
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            marginTop: "18vw",
            height: "50vw !important",
            backgroundSize: "200%",
            backgroundPosition: "100% 30%",
        },
    },
    homeLeftGrid: {
        [theme.breakpoints.down('xs')]: {
            height: "100%",
            backgroundImage: `url(${cross})`,
            backgroundSize: "248%",
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
        },
    },
    homeInnerGridDiv: {
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            // background: "rgba(0,0,0,0.1)"
        },
    },
    homeInnerGridHoliday: {
        left: "7vw", 
        height: "8.8vw", 
        position: "absolute",
        marginTop: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            left: "3vw", 
            height: "22vw", 
            marginTop: "0.5vw",
        },
    },
    homeInnerGrid1BoldText: {
        marginTop: "0.5vw",
        marginBottom: "0",
        paddingLeft: "2vw",
        color: "#404040",
        fontSize: "2.62vw",
        fontWeight: 500,
        lineHeight: 1.1,
        fontFamily: "inherit",
        textTransform: "uppercase",
        [theme.breakpoints.down('xs')]: {
            position: "relative",
            fontSize: "6.6vw",
            padding: "0",
            width: "70vw",
            marginLeft: "31vw"
        },
    },
    homeInnerGrid1LightText: {
        fontSize: "0.94vw",
        paddingLeft: "2vw",
        color: "#404040",
        fontWeight: 400,
        lineHeight: "1.43vw",
        textTransform: "uppercase",
        fontFamily: "inherit",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "22vw",
            fontSize: "2.8vw",
            lineHeight: 1.5,
            marginTop: "1vw"
        },

    },
    homeInnerGrid2Div: {
        minWidth: "220%", 
        position: "relative", 
        marginBottom: "-9.2vw",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
    },
}))

function WelcomePage() {
    const {t}=useTranslation();
    const classes = useStyles();
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.homeOuterGrid} >
            <Grid container item sm={8} xs={12} direction="row" justifyContent="center" alignItems="center" >
                <Grid container item sm={6} xs={12} direction="column" justifyContent="flex-start " alignItems="flex-start" className={classes.homeLeftGrid} >
                    <div className={classes.homeInnerGridDiv} >
                        <img src={holiday} className={classes.homeInnerGridHoliday} />
                        <p className={classes.homeInnerGrid1BoldText} >
                            {t('home.boldText')} 
                        </p>
                    </div>
                    <p className={classes.homeInnerGrid1LightText} >{t('home.lightText')}</p>
                </Grid>
                <Grid container item sm={6} xs={0} direction="row" justifyContent="flex-start " alignItems="center" >
                    <div className={classes.homeInnerGrid2Div} > 
                        <img width="100%" src={cross}  />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default WelcomePage
