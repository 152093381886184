import React, {useState} from 'react'
import IconButton from '@material-ui/core/IconButton';
import { AiOutlineFieldNumber } from "react-icons/ai"
import { FaCloudDownloadAlt } from "react-icons/fa"
import { makeStyles } from '@material-ui/core/styles';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import './style.css'
import Conduct from '../Conduct/Conduct';
import {useTranslation} from 'react-i18next'
const useStyles = makeStyles((theme) => ({
    mainOuterDiv: {
        marginTop: "10vw", 
        marginBottom: "10vw",
        display: "flex", 
        flexFlow: "column", 
        alignItems: "center", 
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {
            marginTop: "28vw", 
            marginBottom: "28vw"
        },
    },
    selectDiv: {
        width: "60vw", 
        display: "flex", 
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            width: "96vw",
            flexDirection: "column"
        },
        
    }
}))
function StudyPlans2() {const classes = useStyles();
    const { t } = useTranslation();
    const educatioTypes = [{type: "1-Kurs"}, {type: "2-Kurs"}, {type: "3-Kurs"}];
    const Kurs1 = ["60112400-Professional ta'lim (60310100-Iqtisodiyot)", "60112400-Professional ta'lim (60411200-Menejment)", "60310100-Iqtisodiyot (tarmoqlar va sohalar bo'yicha)", "60310200-Ekonometrika", "60310300-Mintaqaviy iqtisodiyot", "60310400-Iqtisodiy xavfsizlik", "60310500-Raqamli iqtisodiyot (tarmoqlar va sohalar bo'yicha)", "60410100-Buxgalteriya hisobi va audit (tarmoqlar bo'yicha)", "60410200-Soliqlar va soliqqa tortish (faoliyat turlari bo'yicha)", "60410400-Moliya va moliyaviy texnologiyalar", "60410500-Bank ishi va auditi", "60410600-Sug'urta ishi", "60410700-Byudjet nazorati va g'aznachiligi", "60410800-Statistika (tarmoqlar va sohalar bo'yicha)", "60411200-Menejment (tarmoqlar va sohalar bo'yicha)", "60411201-Menejment (turizm biznesini boshqarish)", "60411300-Biznesni boshqarish (tarmoqlar bo'yicha)", "60411400-Inson resurslarini boshqarish", "60411500-Biznes tahlil", "60411600-Korporativ boshqaruv", "60411900-Jahon iqtisodiyoti va xalqaro iqtisodiy munosabatlar (mintaqalar va faoliyat turlari bo'yicha)", "60412500-Marketing (reklama ishi)", "60412500-Marketing (tarmoqlar va sohalar bo'yicha)", "60412600-Savdo ishi (turlari bo'yicha)", "60610200-Axborot tizimlari va texnologiyalari (tarmoqlar va sohalar bo'yicha)", "61010100-Mehmonxona xo'jaligini tashkil etish va boshqarish", "61010400-Turizm (faoliyat yo'nalishlari bo'yicha)"]
    const Kurs2 = ["60410400-Moliya va moliyaviy texnalogiyalar", "Axborot tizimlari va texnalogiyalari (tarmoqlar va sohalar bo'yicha)", "Bank ishi va auditi", "Bug'alteriya hisobi va audit (tarmoqlar bo'yicha)", "Byudjet nazorati va g'aznachiligi", "Ekonometrika", "Inson resurslarini boshqarish", "Iqtisodiy havfsizlik", "Iqtisodiyot (tarmoqlar va sohalar bo'yicha)", "Jahon iqtisodiyoti va halqaro iqtisodiy munosabatlar (mintaqalar va faoliyat turlari bo'yicha)", "Korporative boshqaruv", "Logistika (yo'nalishlar bo'yicha)", "Marketing (reklama ishi)", "Marketing (tarmoqlar va sohalar bo'yicha)", "Menejment (tarmoqlar va sohalar bo'yicha)", "Menejment (Turizm biznesni boshqarish)", "Mexmonhona ho'jaligini tashkil etish va boshqarish", "Mintaqaviy iqtisodiyot", "Professional ta'lim (60310100-Iqtisodiyot)", "Professional ta'lim (60411200-Menejment)", "Raqamli iqtisodiyot", "Savdo ishi (turlari bo'yicha)", "Soliqlar va soliqqa tortish (faoliyat turlari bo'yicha)", "Statistika", "Sug'urta ishi", "Turizm (faoliyat yo'nalishlari bo'yicha)"]
    const Kurs3 = ["5230100-Iqtisodiyot(tarmoqlar va sohalar bo'yicha)", "5230200-Menejment(tarmoqlar va sohalar bo'yicha)", "5230202-Menejment Turizm biznesini boshqarish", "5230400-Marketing(reklama ishi)", "5230400-Marketing(tarmoqlar va sohalar bo'yicha)", "5230600-Moliya va moliyaviy texnologiyalar", "5230700-Bank ishi va audit", "5230800-Soliqlar va soliqqa tortish", "5230900-Buxgalteriya hisobi va audit (tarmoqlar bo'yicha)", "5231100-Jahon iqtisodiyoti va xalqaro iqtisodiy munosabatlar", "5231200-Sug'urta ishi", "5231300-Byudjet nazorati va g'aznachiligi", "5231400-Statistika tarmoklar va soxalar buyicha", "5231600-Inson resurslarini boshqarish", "5231900-Korporativ boshqaruv", "5232200-Ekonometrika", "5232400-Iqtisodiy havfsizlik", "5232500-Logistika (yo'nalishlar bo'yicha)", "5233600-savdo ishi (turlar bo'yicha)", "5234100-Raqamli iqtisodiyot (tarmoqlar va sohalar bo'yicha)", "5330200-Axborat tizimlari va texnologiyalari", "5610200-Mexmonxona xo'jaligini tashkil etish va boshqarish", "5610300-Turizm (faoliyat yo'nalishlari bo'yicha)"]
    const [selectedEducationType, setSelectedEducationType] = useState([])
    const [selectedEducationTypeName, setSelectedEducationTypeName] = useState("")
    const [selectedSubject, setSelectedSubject] = useState("")
    const handleEducationTypesChange = (e) =>{
        setSelectedSubject(null)
        document.getElementById("subject").value=""
        setSelectedEducationTypeName(e.target.value)
        switch (e.target.value) {
            case "1-Kurs":
                setSelectedEducationType(Kurs1)
                break;
            case "2-Kurs":
                setSelectedEducationType(Kurs2)
            
                break;
            case "3-Kurs":
                setSelectedEducationType(Kurs3)
            
                break;
            default:
                setSelectedEducationType([])
                break;
        }
    }
    return (
        <div className={classes.mainOuterDiv} >
            <div className={classes.selectDiv} >
                <select required className="selectInput" placeholder="TalimShakli va o'quv yilini tanlang" onChange={(e)=> handleEducationTypesChange(e)} >
                    <option value="" disabled selected> {t('studyplans.education_form')} </option>
                    {
                        educatioTypes.map((educatioType)=>{
                            const {type} = educatioType
                            return(
                                <option key={type} value={type} >{type}</option>
                            )
                        })
                    }
                </select>
                <select required id="subject" className="selectInput selectInput1" onChange={(e)=>setSelectedSubject(e.target.value)} placeholder="TalimShakli va o'quv yilini tanlang" >
                    <option value="" disabled selected>{t('studyplans.study_field')}</option>
                    {
                        selectedEducationType.map((subject)=>{
                            return(
                                <option key={subject} value={subject} >{subject}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className="table-wrapper" tabindex="0">
                <table>
                    <thead>
                        <tr>
                            <th className="bodyIcon" style={{color: "white"}} ><AiOutlineFieldNumber/></th>
                            <th className="type" >{t('studyplans.specialty')}</th>
                            <th >{t('studyplans.download')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            !selectedSubject && 
                            selectedEducationType.map((subject, index)=>{
                                return(
                                    <tr>
                                        <td className="bodyTable">{index+1}</td>
                                        <td className="type">{subject}</td>
                                        <td className="bodyTable"> <IconButton className="bodyIcon" href={process.env.PUBLIC_URL + `/StudyPlans/${selectedEducationTypeName}/${subject}.pdf`} download > <FaCloudDownloadAlt/> </IconButton></td>
                                </tr>
                                )
                            })
                        }
                        {
                            selectedSubject && 
                                <tr>
                                    <td className="bodyTable">1</td>
                                    <td className="type">{selectedSubject}</td>
                                    <td className="bodyTable"> <IconButton className="bodyIcon" href={process.env.PUBLIC_URL + `/StudyPlans/${selectedEducationTypeName}/${selectedSubject}.pdf`} download > <FaCloudDownloadAlt/> </IconButton></td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                    selectedSubject && 
                    <Conduct url={`/StudyPlans/${selectedEducationTypeName}/${selectedSubject}.pdf`} />
                }
        </div>
    )
}

export default StudyPlans2
