import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DescModal from "./DescModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { isClear } from "./helpers";
import moment from "moment";

const depState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    vacation: {
      title: "таътил",
      value: "",
    },
    family: {
      title: "оилавий сабаб",
      value: "",
    },
  },
};

const techState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    vacation: {
      title: "таътил",
      value: "",
    },
    family: {
      title: "оилавий сабаб",
      value: "",
    },
  },
};

const Staffs = () => {
  const [data, setData] = useState(null);
  const getData = () => {
    axios
      .get(
        `/api/v1/attendance-control/list/?date=${moment(new Date()).format(
          "YYYY-MM-DD"
        )}`
      )
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    getData();
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [date, setDate] = useState(null);
  const [dep, setDep] = useState(depState);
  const [tech, setTech] = useState(techState);

  const postResults = () => {
    setIsPosting(true);
    axios
      .post("/api/v1/attendance-control/", {
        time: date,
        base_attendances: [
          {
            type: "Бошқарув ходимлари",
            all_count: Number(dep.in) + Number(dep.out),
            attended_count: Number(dep.in),
            not_attendeds_count: Number(dep.out),
            ill_count: Number(dep.desc.ill.value),
            go_away_count: Number(dep.desc.abroad.value),
            causeless_count: Number(dep.desc.noIdea.value),
            on_vacation_count: Number(dep.desc.vacation.value),
            family_reason_count: Number(dep.desc.family.value),
          },
          {
            type: "Техник ходимлар",
            all_count: Number(tech.in) + Number(tech.out),
            attended_count: Number(tech.in),
            not_attendeds_count: Number(tech.out),
            ill_count: Number(tech.desc.ill.value),
            go_away_count: Number(tech.desc.abroad.value),
            causeless_count: Number(tech.desc.noIdea.value),
            on_vacation_count: Number(tech.desc.vacation.value),
            family_reason_count: Number(tech.desc.family.value),
          },
        ],
      })
      .then((e) => {
        if (e.status == 201) {
          toast.success("Saqlandi");
          setIsSubmitted(false);
          getData();
        }
        setIsPosting(false);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          toast.error("Ma'lumot kiritish vaqti tugallangan!");
        }
        setIsPosting(false);
      });
  };

  useEffect(() => {
    setDep(depState);
    setTech(techState);
  }, [date]);

  useEffect(() => {
    if (data?.length && date === "12:00") {
      for (let d of data) {
        if (d.time === "12:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Бошқарув ходимлари") {
              setDep({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  vacation: {
                    title: "таътил",
                    value: aType.on_vacation_count,
                  },
                  family: {
                    title: "оилавий сабаб",
                    value: aType.family_reason_count,
                  },
                },
              });
            }
            if (aType.type === "Техник ходимлар") {
              setTech({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  vacation: {
                    title: "таътил",
                    value: aType.on_vacation_count,
                  },
                  family: {
                    title: "оилавий сабаб",
                    value: aType.family_reason_count,
                  },
                },
              });
            }
          }
        }
      }
    }
    if (data?.length && date === "18:00") {
      for (let d of data) {
        if (d.time === "18:00") {
          for (let aType of d.base_attendances) {
            if (aType.type === "Бошқарув ходимлари") {
              setDep({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  vacation: {
                    title: "таътил",
                    value: aType.on_vacation_count,
                  },
                  family: {
                    title: "оилавий сабаб",
                    value: aType.family_reason_count,
                  },
                },
              });
            }
            if (aType.type === "Техник ходимлар") {
              setTech({
                all: true,
                in: aType.attended_count,
                out: aType.not_attendeds_count,
                desc: {
                  ill: {
                    title: "касаллар сони",
                    value: aType.ill_count,
                  },
                  abroad: {
                    title:
                      "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                    value: aType.go_away_count,
                  },
                  noIdea: {
                    title: "сабабсиз келмаганлар",
                    value: aType.causeless_count,
                  },
                  vacation: {
                    title: "таътил",
                    value: aType.on_vacation_count,
                  },
                  family: {
                    title: "оилавий сабаб",
                    value: aType.family_reason_count,
                  },
                },
              });
            }
          }
        }
      }
    }
  }, [data, date]);

  return (
    <div className="mt-36 container mx-auto text-center py-4">
      <ToastContainer />
      <div className="font-bold">
        Тошкент давлат иқтисодиёт университети тўғрисида кунлик
      </div>
      <div className="font-bold text-lg">МАЪЛУМОТ</div>

      <FormControl>
        <RadioGroup
          onChange={(e) => setDate(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="12:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 12:00 ҳолатига`}
          />
          <FormControlLabel
            value="18:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 18:00 ҳолатига`}
          />
        </RadioGroup>
      </FormControl>

      {/* Table */}
      <table className={`${!date && "hidden"} mt-4`}>
        <thead>
          <tr>
            <th rowSpan={2} colSpan={1}>
              т/р
            </th>
            <th rowSpan={2} colSpan={4}>
              Қатнашчилар тоифаси
            </th>
            <th rowSpan={2} colSpan={2}>
              Жами
            </th>
            <th rowSpan={1} colSpan={4}>
              Шундан
            </th>
            <th rowSpan={2} colSpan={5}>
              Изоҳ
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Дарсга (ишга) қатнашганлар сони</th>
            <th colSpan={2}>Дарсга (ишга) қатнашмаганлар сони</th>
          </tr>
        </thead>
        {/*  */}
        <tbody>
          <tr>
            <td colSpan={1}>1</td>
            <td colSpan={4}>Бошқарув ходимлари</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bakAll"
                className={`${
                  isSubmitted && !dep.all && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={Number(dep.in) + Number(dep.out)}
                id="bakAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="bakTrue"
                className={`${
                  isSubmitted && !dep.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                type="number"
                value={dep.in}
                onChange={(e) => setDep({ ...dep, in: e.target.value })}
                id="bakTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="depFalse"
                className={`${
                  isSubmitted && dep.out === "" && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                value={dep.out}
                disabled
                id="depFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={dep} callback={setDep} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(dep.desc).map((item) => (
                  <li key={item}>
                    {dep.desc[item].title} - <b>{dep.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>2</td>
            <td colSpan={4}>Техник ходимлар</td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="techAll"
                className={`${
                  isSubmitted && !tech.all && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                disabled
                value={Number(tech.in) + Number(tech.out)}
                id="techAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="techTrue"
                className={`${
                  isSubmitted && !tech.in && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full cursor-pointer`}
              ></label>
              <input
                type="number"
                value={tech.in}
                onChange={(e) => setTech({ ...tech, in: e.target.value })}
                id="techTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <label
                htmlFor="techFalse"
                className={`${
                  isSubmitted && tech.out === "" && "bg-red-500 opacity-50"
                } absolute top-0 left-0 w-full h-full`}
              ></label>
              <input
                type="number"
                value={tech.out}
                disabled
                id="techFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5} className={`relative cursor-pointer`}>
              <DescModal values={tech} callback={setTech} />
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(tech.desc).map((item) => (
                  <li key={item}>
                    {tech.desc[item].title} - <b>{tech.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={`${!date && "hidden"} mt-4`}>
        <Button
          onClick={() => {
            setIsSubmitted(true);
            if (isClear(tech) && isClear(dep)) {
              if (true) {
                postResults();
              } else {
                toast.error(
                  "Дарсга (ишга) қатнашмаганлар сони Изоҳга тенг емас!"
                );
              }
            }
          }}
          disabled={isPosting}
          variant="contained"
          size="large"
          color="success"
        >
          Saqlash
        </Button>
      </div>
    </div>
  );
};

export default Staffs;
