import React, { useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { OrgNameAction } from '../../redux';
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';
import indexbg from '../media/indexbg.svg'
import {useTranslation} from 'react-i18next'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    outerGrid: {
        height: "100vh",
        backgroundImage: `url(${indexbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-100% 20%",
        overflow: "visible",
    },
    grid: {
        backgroundColor: "white",
        padding: "2vw 1vw 2vw 1vw",
        width: "100%",
        boxShadow: "0.26vw 0.78vw 0.62vw 0.26vw rgb(14 36 129 / 8%)",
        minHeight: "25vw",
        maxHeight: "30vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "45vw",
            maxHeight: "45vw",
        },
    },
    grid1: {
        backgroundColor: "white",
        padding: "4vw 1vw 4vw 1vw",
        width: "100%",
        boxShadow: "0.26vw 0.78vw 0.62vw 0.26vw rgb(14 36 129 / 8%)",
        minHeight: "20vw",
        maxHeight: "20vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "40vw",
            maxHeight: "40vw",
        },
    },
    outputText: {
        color: "#104279",
        fontWeight: "bold",
        fontSize: "1.2vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.2vw",
        },
    },
    orgName: {
        minHeight: "10vw",
        maxHeight: "10vw",
        minWidth: "25vw",
        maxWidth: "25vw",
        padding: "0.5vw 1vw", 
        boxSizing: "border-box", 
        margin: "0.5vw 0", 
        fontSize: "0.7vw", 
        border: "0.1vw solid #ADADAD", 
        outline: "none", 
        borderRadius: "0.5vw",
        [theme.breakpoints.down('xs')]: {
            minHeight: "17vw",
            maxHeight: "17vw",
            minWidth: "33vw",
            maxWidth: "33vw",
            padding: "1.5vw 2vw", 
            margin: "1vw 0", 
            fontSize: "1.7vw", 
            borderRadius: "1.5vw",
        },
    },
    submitButton: {
        fontSize: "0.73vw",
        lineHeight: 1.4,
        padding: "0.52vw 1.3vw",
        letterSpacing: "0.1vw",
        color: "white",
        backgroundImage: "linear-gradient(30deg, #1cb5e0 0, #1cb5e0 55%, #263d8a 90%)",
        backgroundPositionX: "100%",
        backgroundPositionY: 0,
        backgroundSize: "200%",
        backgroundRepeat: "initial",
        backgroundAttachment: "initial",
        backgroundOrigin: "initial",
        backgroundClip: "initial",
        backgroundColor: "initial",
        boxShadow: "0 0.4vw 0.63vw 0 rgb(14 36 129 / 8%)",
        borderRadius: "0.3vw",
        transition: "all 0.5s ease",
        marginTop: "0.78vw",
        "&:hover": {
            boxShadow: "0 0 0 0 rgba(14,36,129,0) !important",
            backgroundPosition: "0 0 !important",
            marginTop: "0.7vw",
            transition: "all 0.5s ease",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.5vw",
            padding: "1.5vw 2vw",
            marginTop: "4vw",
        },
    }
}))
function OfferPaper({appData, OrgNameAction}) {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [orgName, setOrgName] = useState({
        job_name: ""
    })
    const downloadContract = () =>{
        const downloadUrl = appData.downloadOfferURL
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'Offer.pdf'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        navigate('/');
        const message = 'Thank you for using!'
        enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right', }})
        const app = appData
        app.downloadOfferURL=null;
        localStorage.setItem("app", JSON.stringify(app));
    }
    
    return (
        appData.loading ? (
            <Loader/>
        ):
        (
            appData.downloadOfferURL ? (
                <Grid container item xs={12} direction="column" justify="center" alignItems="center" className={classes.outerGrid} >
                <Grid container item sm={3} xs={9} direction="column" justify="flex-start" alignItems="center" className={classes.grid1} >
                    <p className={classes.outputText}>{t('contractDownload.boldText')}</p>
                    <Button className={classes.submitButton} variant="contained" color="primary" onClick={()=>{  downloadContract() }}> {t('contractDownload.button')} </Button>
                </Grid>
            </Grid>
            ):
            (
                <Grid container item xs={12} direction="column" justify="center" alignItems="center" className={classes.outerGrid} >
                    <Grid container item sm={4} xs={10} direction="column" justify="flex-start" alignItems="center" className={classes.grid} >
                        <p className={classes.outputText}>{t('OfferPaper.boldText')}</p>
                        <textarea maxLength={200}
                        value={orgName.job_name} onChange={(e)=>setOrgName({job_name: e.target.value})}
                        className={classes.orgName} 
                        name="job_name" id="job_name"/>
                        <Button className={classes.submitButton} variant="contained" color="primary" onClick={()=> {OrgNameAction(orgName, navigate, enqueueSnackbar)}} > {t('OfferPaper.button')} </Button>
                    </Grid>
                </Grid>
            )
        )
        
    )
}


const mapStateToProps = state => {
    return {
      appData: state.app
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        OrgNameAction: (orgName, navigate, enqueueSnackbar) => dispatch(OrgNameAction(orgName, navigate, enqueueSnackbar))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(OfferPaper)