import axios from "axios";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./index.css";
import moment from "moment";
import { generateDates } from "./helpers";
import BasicSelect from "./Select";
import List from "./List";
import GutterlessList from "./List";
import InteractiveList from "./List";

const url = `/api/v1/attendance-control/stats/excel/?attendance__date=${moment(
  new Date()
).format("YYYY-MM-DD")}`;
const urlData = `/api/v1/attendance-control/stats/?attendance__date=${moment(
  new Date()
).format("YYYY-MM-DD")}`;

const bakalavrKunState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: 0,
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: 0,
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: 0,
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: 0,
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: 0,
    },
  },
};

const bakalavrSrtState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: 0,
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: 0,
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: 0,
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: 0,
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: 0,
    },
  },
};

const magistrState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: 0,
    },
    abroad: {
      title:
        "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
      value: 0,
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: 0,
    },
    noGraf: {
      title:
        "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
      value: 0,
    },
    prac: {
      title: "Aмалиётга юборилганлар",
      value: 0,
    },
  },
};

const profState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: 0,
    },
    abroad: {
      title:
        "Ректор буйруғига асосан чет давлатга юборилганлар",
      value: 0,
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: 0,
    },
  },
};

const depState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: 0,
    },
    abroad: {
      title:
        "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
      value: 0,
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: 0,
    },
    vacation: {
      title: "таътил",
      value: "",
    },
    family: {
      title: "оилавий сабаб",
      value: "",
    },
  },
};

const techState = {
  all: true,
  in: "",
  out: "",
  desc: {
    ill: {
      title: "касаллар сони",
      value: "",
    },
    abroad: {
      title:
        "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
      value: "",
    },
    noIdea: {
      title: "сабабсиз келмаганлар",
      value: "",
    },
    vacation: {
      title: "таътил",
      value: "",
    },
    family: {
      title: "оилавий сабаб",
      value: "",
    },
  },
};

const Results = () => {
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [bakalavrKun, setBakalavrKun] = useState(bakalavrKunState);
  const [bakalavrSrt, setBakalavrSrt] = useState(bakalavrSrtState);
  const [magistr, setMagistr] = useState(magistrState);
  const [prof, setProf] = useState(profState);
  const [dep, setDep] = useState(depState);
  const [tech, setTech] = useState(techState);
  const download = () => {
    axios
      .get(url, {
        method: "GET",
        responseType: "blob", // important
        params: {
          // attendance__: date,
          attendance__time: date,
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Ma'lumot.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const [data, setData] = useState();

  useEffect(() => {
    setBakalavrKun(bakalavrKunState);
    setBakalavrSrt(bakalavrSrtState);
    setMagistr(magistrState);
    setProf(profState);
    setDep(depState);
    setTech(techState);
  }, [date]);

  useEffect(() => {
    axios
      .get(urlData, {
        params: {
          attendance__time: `${date}`,
        },
      })
      .then((res) => setData(res.data))
      .catch((error) => console.log(error));
  }, [date]);

  useEffect(() => {
    setBakalavrKun(bakalavrKunState);
    setBakalavrSrt(bakalavrSrtState);
    setMagistr(magistrState);
    setProf(profState);
    setDep(depState);
    setTech(techState);
    if (data) {
      for (let aType in data) {
        if (aType === "Бакалавриат (кундузги)") {
          setBakalavrKun({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
              noGraf: {
                title:
                  "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                value: 0,
                value: data[aType].total_dont_have_classes_count || "",
              },
              prac: {
                title: "Aмалиётга юборилганлар",
                value: data[aType].total_practicants_count || "",
              },
            },
          });
        }
        if (aType === "Бакалавриат (сиртқи)") {
          setBakalavrSrt({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
              noGraf: {
                title:
                  "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                value: data[aType].total_dont_have_classes_count || "",
              },
              prac: {
                title: "Aмалиётга юборилганлар",
                value: data[aType].total_practicants_count || "",
              },
            },
          });
        }
        if (aType === "Магистратура") {
          setMagistr({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "Ректор буйруғига асосан (академ мобиллик, амалиёт, малака ошириш ва х.к.) чет давлатга юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
              noGraf: {
                title:
                  "ўқув жараёни графигига кўра дарс машғулотлари мавжуд бўлмаган талабалар сони",
                value: data[aType].total_dont_have_classes_count || "",
              },
              prac: {
                title: "Aмалиётга юборилганлар",
                value: data[aType].total_practicants_count || "",
              },
            },
          });
        }
        if (aType === "Профессор-ўқитувчилар") {
          setProf({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "Ректор буйруғига асосан чет давлатга юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
            },
          });
        }
        if (aType === "Бошқарув ходимлари") {
          setDep({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
              vacation: {
                title: "таътил",
                value: data[aType].total_on_vacation_count || "",
              },
              family: {
                title: "оилавий сабаб",
                value: data[aType].total_family_reason_count || "",
              },
            },
          });
        }
        if (aType === "Техник ходимлар") {
          setTech({
            all: data[aType].total_all_count || "",
            in: data[aType].total_attended_count || "",
            out: data[aType].total_not_attendeds_count || "",
            desc: {
              ill: {
                title: "касаллар сони",
                value: data[aType].total_ill_count || "",
              },
              abroad: {
                title:
                  "чет давлатларга ёки бошқа ҳудудларга хизмат сафарига юборилганлар",
                value: data[aType].total_go_away_count || "",
              },
              noIdea: {
                title: "сабабсиз келмаганлар",
                value: data[aType].total_causeless_count || "",
              },
              vacation: {
                title: "таътил",
                value: data[aType].total_on_vacation_count || "",
              },
              family: {
                title: "оилавий сабаб",
                value: data[aType].total_family_reason_count || "",
              },
            },
          });
        }
      }
    }
  }, [date, data]);

  return (
    <div className="mt-36 container mx-auto text-center py-4">
      <div className="font-bold text-lg">
        Тошкент давлат иқтисодиёт университети тўғрисида кунлик
      </div>
      <div className="font-bold text-lg">МАЪЛУМОТ</div>

      {/* <BasicSelect dates={generateDates()} callback={setTime} /> */}
      <FormControl>
        <RadioGroup
          onChange={(e) => setDate(e.target.value)}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="12:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 12:00 ҳолатига`}
          />
          <FormControlLabel
            value="18:00"
            control={<Radio />}
            label={`${moment(new Date()).format(
              "DD.MM.YYYY"
            )} йил соат 18:00 ҳолатига`}
          />
        </RadioGroup>
      </FormControl>

      <div>
        <Button
          onClick={() => {
            download();
          }}
          variant="contained"
          size="large"
          color="success"
          disabled={!date}
        >
          Yuklab olish
        </Button>
      </div>

      {/* Table */}
      <table className={`${!date && "hidden"} mt-4`}>
        <thead>
          <tr>
            <th rowSpan={2} colSpan={1}>
              т/р
            </th>
            <th rowSpan={2} colSpan={4}>
              Қатнашчилар тоифаси
            </th>
            <th rowSpan={2} colSpan={2}>
              Факультет 1/2-смена бўйича Жами
            </th>
            <th rowSpan={1} colSpan={4}>
              Шундан
            </th>
            <th rowSpan={2} colSpan={5}>
              Изоҳ
            </th>
          </tr>
          <tr>
            <th colSpan={2}>Дарсга (ишга) қатнашганлар сони</th>
            <th colSpan={2}>Дарсга (ишга) қатнашмаганлар сони</th>
          </tr>
        </thead>
        {/*  */}
        <tbody>
          <tr>
            <td colSpan={1}>1</td>
            <td colSpan={4}>Бакалавриат (кундузги)</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                required
                value={bakalavrKun.all}
                id="bakAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={bakalavrKun.in}
                disabled
                id="bakTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={bakalavrKun.out}
                disabled
                id="bakFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(bakalavrKun.desc).map((item) => (
                  <li key={item}>
                    {bakalavrKun.desc[item].title} -{" "}
                    <b>{bakalavrKun.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>2</td>
            <td colSpan={4}>Бакалавриат (сиртқи)</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                value={bakalavrSrt.all}
                id="bak2All"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={bakalavrSrt.in}
                disabled
                id="bak2True"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={bakalavrSrt.out}
                disabled
                id="bak2False"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(bakalavrSrt.desc).map((item) => (
                  <li key={item}>
                    {bakalavrSrt.desc[item].title} -{" "}
                    <b>{bakalavrSrt.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>3</td>
            <td colSpan={4}>Магистратура</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                value={magistr.all}
                id="magAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                value={magistr.in}
                disabled
                type="number"
                id="magTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                value={magistr.out}
                disabled
                type="number"
                id="magFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(magistr.desc).map((item) => (
                  <li key={item}>
                    {magistr.desc[item].title} -{" "}
                    <b>{magistr.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>4</td>
            <td colSpan={4}>Профессор-ўқитувчилар</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                value={prof.all}
                id="profAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                value={prof.in}
                disabled
                type="number"
                id="proTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                value={prof.out}
                disabled
                type="number"
                id="proFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(prof.desc).map((item) => (
                  <li key={item}>
                    {prof.desc[item].title} - <b>{prof.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>5</td>
            <td colSpan={4}>Бошқарув ходимлари</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                value={dep.all}
                id="bakAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={dep.in}
                disabled
                id="bakTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={dep.out}
                disabled
                id="depFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(dep.desc).map((item) => (
                  <li key={item}>
                    {dep.desc[item].title} - <b>{dep.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={1}>6</td>
            <td colSpan={4}>Техник ходимлар</td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                disabled
                value={tech.all}
                id="techAll"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={tech.in}
                disabled
                id="techTrue"
                className="border-none"
              />
            </td>
            <td colSpan={2} className="relative">
              <input
                type="number"
                value={tech.out}
                disabled
                id="techFalse"
                className="border-none"
              />
            </td>
            <td colSpan={5}>
              <ul className="text-left px-3 list-inside list-disc">
                {Object.keys(tech.desc).map((item) => (
                  <li key={item}>
                    {tech.desc[item].title} - <b>{tech.desc[item].value}</b>
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      {data && data?.faculties_statuses && date && (
        <InteractiveList
          list={
            data?.faculties_statuses
              ? [
                  ...data.faculties_statuses.filter(
                    (item) => item.name !== "test-fakultet"
                  ),
                  {
                    name: "Кадрлар",
                    status: data.is_kadr_given,
                  },
                  {
                    name: "Тил маркази",
                    status: data.kaferda,
                  },
                ]
              : []
          }
        />
      )}
    </div>
  );
};

export default Results;
