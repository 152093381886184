import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import indexbg from '../media/indexbg.svg'

const useStyles = makeStyles((theme) => ({
    grid: {
        height: "100vh",
        backgroundColor: "white",
        backgroundImage: `url(${indexbg})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-100% 20%",
        overflow: "visible",
    },
}))

function Loader() {
  const classes = useStyles();
  return (
    <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" className={classes.grid}></Grid>
  )
}

export default Loader
