import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import {useTranslation} from 'react-i18next'
import AcademicCalendarPDF from './AcademicCalendar.pdf'
const useStyles = makeStyles((theme) => ({
    pdfContsiner: {
        margin: "10vw auto",
        width: "75vw",
        height: "40vw",
        [theme.breakpoints.down('xs')]: {
            margin: "30vw auto",
            width: "96vw",
            height: "60vh",
        },
    },
    pdfContsinerTitle:{
        margin: "2vw auto",
        textAlign: "center",
        fontSize: "2vw",
        fontWeight: 500,
        letterSpacing: "0.1vw",
        [theme.breakpoints.down('xs')]: {
            margin: "4vw auto",
            fontSize: "4vw",
            letterSpacing: "0.2vw",
        },
    }
}))


function AcademicCalendar() {
    const {t} = useTranslation();
    const classes = useStyles();
    const [defaultPdfFile]=useState(AcademicCalendarPDF);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className={classes.pdfContsiner} >
            <p className={classes.pdfContsinerTitle} >{t('home4.cards', {returnObjects: true})[8].cardTitle}</p>
            {defaultPdfFile&&<><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
          <Viewer fileUrl={defaultPdfFile}
            plugins={[defaultLayoutPluginInstance]} />
      </Worker></>}
        </div>
    )
}

export default AcademicCalendar
