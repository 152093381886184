import React from 'react'
import {useTranslation} from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { IoMdFunnel } from "react-icons/io"
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    home1OuterGrid: {
        paddingTop: "3.67vw",
        paddingBottom: "3.67vw",
        backgroundColor: "#ECF9FD",
    },
    home1OuterGridTitle: {
        fontSize: "2vw", 
        fontFamily: "inherit", 
        margin: "1vw 0 3.4vw 0", 
        lineHeight: "1.42vw", 
        color: "#404040", 
        fontWeight: 400,
        [theme.breakpoints.down('xs')]: {
            fontSize: "6vw", 
            lineHeight: 1.5, 
        },
    },
    home1OuterGridDesc: {
        margin: "0.78vw 0 3.4vw", 
        fontSize: "0.83vw", 
        color: "#404040", 
        fontWeight: "normal", 
        lineHeight: "1.42vw",
        [theme.breakpoints.down('xs')]: {
            fontSize: "2.5vw", 
            lineHeight: 1.5,
            width: "90vw",
            textAlign: "center"
        },
    },
    home1ServiceGridx: {
        padding: "0 1.57vw 1.57vw 0",
        [theme.breakpoints.down('xs')]: {
            padding: "0 3vw 6vw 3vw",
        },
    },
    home1ServiceGridx1: {
        padding: "0 0 1.57vw 0",
        [theme.breakpoints.down('xs')]: {
            padding: "0 3vw 6vw 3vw",
        },
    },
    home1ServiceGridxCon: {
        backgroundColor: "white",
        padding: 0,
        margin: 0,
        width: "100%",
        height: "12vw",
        cursor: "pointer",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        border: "1px solid white",
        color: "#404040",
        textTransform: "none",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
        transition: "all 0.5s ease",
        "&:hover": {
            transition: "all 0.5s ease",
            backgroundColor: "#2277B3",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            color: "white",
           "& $home1ServiceGridxConIcon": {
                color: "white"
            }
        },
        [theme.breakpoints.down('xs')]: {
            height: "38vw",
        },
    },
    home1ServiceGridxCon1: {
        marginTop: "2.57vw",
        width: "100%",
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            marginTop: "5vw",
        },
    },
    home1ServiceGridxConIcon: {
        fontSize: "1.84vw",
        color: "#0F5E96",
        [theme.breakpoints.down('xs')]: {
            fontSize: "10vw",
        },
    },
    home1ServiceGridxConTitle: {
        fontSize: "1vw", 
        fontWeight: 400,
        margin: "2vw 0 0.52vw 0",
        lineHeight: 1.2,
        color: "inherit",
        [theme.breakpoints.down('xs')]: {
            fontSize: "3.5vw",
            marginTop: "5vw",
            padding: "0 3vw"
        },
    },
    homeLine: {
        position: "absolute",
        top: "30vw",
        left: 0,
        width: "100%",
        overflow: "hidden",
        lineHeight: 0,
        transform: "rotate(180deg)",
        [theme.breakpoints.down('xs')]: {
            top: "54vw",
        },
    },
    homeLineSvg: {
        position: "relative",
        display: "block",
        width: "calc(139% + 1.3px)",
        height: "8.2vw",
        [theme.breakpoints.down('xs')]: {
            height: "14vw",
        },
    },
}))
function InteractiveServices() {
    const classes = useStyles();
    const {t}=useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const handleButtonClick = (pageURL) => {
        if (pageURL.length<15) {
            // if (pageURL==="/transcript") {
            //     const message = t('snackbarText')
            // enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right', }})
            // } else {
                navigate(pageURL);
                window.scrollTo(0, 0);
            // }
        }else{
            window.location.href=pageURL
        }
    };
    return (
        <Grid id="InteractiveServices" container direction="column" justifyContent="center" alignItems="center" className={classes.home1OuterGrid} >
            <Grid container item sm={8} xs={12} direction="column" justifyContent="center" alignItems="center" >
                <p className={classes.home1OuterGridTitle} >{t('home1.boldText')}</p>
                <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" >
                {
                    t('home1.cards', {returnObjects: true}).map((card, index) => {
                        const { cardTitle, pageURL } = card;
                        return(
                            <>
                                <div className={classes.homeLine} >
                                    <svg viewBox="0 0 1200 120" preserveAspectRatio="none" className={classes.homeLineSvg} >
                                        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" className="shape-fill" style={{
                                            fill: "#ECF9FD"
                                        }}></path>
                                    </svg>
                                </div>
                                <Grid key={index} container item sm={4} xs={6} direction="column" justifyContent="center" alignItems="center" className={(index===2 || index===5 || index===8) ? classes.home1ServiceGridx1 : classes.home1ServiceGridx}  >
                                    <Button className={classes.home1ServiceGridxCon} onClick={()=> handleButtonClick(pageURL)} >
                                        <div className={classes.home1ServiceGridxCon1} >
                                            <IoMdFunnel className={classes.home1ServiceGridxConIcon}/>
                                            <p className={classes.home1ServiceGridxConTitle} style={{padding: '0 10px'}}>{cardTitle}</p>
                                        </div>
                                    </Button>
                                </Grid>
                            </>
                        )
                    })
                }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InteractiveServices
