import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MdOutlineErrorOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

export default function GutterlessList({ list }) {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {list.map((value, i) => (
        <ListItem
          key={value}
          disableGutters
          secondaryAction={
            value.status ? (
              <FaCheck className={`text-green-500 text-2xl`} />
            ) : (
              <MdOutlineErrorOutline className="text-red-500 text-2xl" />
            )
          }
        >
          <ListItemText primary={`${value.name}`} />
        </ListItem>
      ))}
    </List>
  );
}
