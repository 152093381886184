import React from 'react'
import {useTranslation} from 'react-i18next'
import { FcOvertime, FcPhone, FcAddressBook, FcPrint  } from "react-icons/fc"
import person1 from '../media/Amirov_Lochin_economy.jpeg'
import person2 from '../media/Azimov_Tolmas_economy.jpeg'
import person3 from '../media/Shfyusupova__Nargiza_economy.jpeg'
import person4 from '../media/Rahimov_Hasanboy__economy.jpeg'
import person5 from '../media/Hursandov_Komiljon_economy.jpeg'
function Economy() {
    const Image = [person1, person2, person3, person4, person5]
    const {t}=useTranslation();
    const on_Mouse_Enter = () => {
        document.getElementById('upper').style.backgroundColor = "#faf8f8"
        document.getElementById('border').style.backgroundColor = "#2277B3"
        document.getElementById('textColor').style.color = "black"
        document.getElementById('mail').style.color = "#007bff"
    }
    const on_Mouse_Leave = () => {
        document.getElementById('upper').style.backgroundColor = "#2277B3"
        document.getElementById('border').style.backgroundColor = "#8cc8f3"
        document.getElementById('textColor').style.color = "white"
        document.getElementById('mail').style.color = "white"
    }
  return (
    <div className="w-full xl:my-32 my-20 grid grid-cols-12 xl:gap-[15px] gap-[5px]">
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 lg:p-2 my-2 px-3 group">
            <p className='xl:text-2xl text-[#263d8a] group-hover:text-[#1cb5e0] cursor-default transition-colors duration-700 sm:text-xl text-lg font-semibold xl:tracking-wider tracking-wide mb-3' > 
                {t('economy.name')} 
            </p>
            <div className=' w-[100px] rounded-lg h-[3px] bg-[#1cb5e0] group-hover:bg-[#263d8a] group-hover:w-full duration-700 transition-all mt-1 xl:mb-6 mb-3' ></div>
        </div>
        {
            t('economy.shortInfo', {returnObjects: true}).map((card, index) => {
                const {name, position, receptionDays, phone, fax, email} = card
                return(
                    <>
                        {
                            index===0 ? 
                            (
                                <div id='upper' className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10 col-span-12  overflow-hidden md:rounded-lg md:border-3 bg-[#2277B3] group hover:bg-[#faf8f8] transition-all duration-500 md:duration-700 opacity-95 hover:opacity-100W pb-5 md:pb-0">
                                    {/* <Link to={`/governing_body${name}`} className="basis-full cursor-pointer"> */}
                                        <div className="flex flex-col md:flex-row items-center">
                                            <img src={Image[index]} alt="news-img" className="xl:w-[240px] xl:h-[225px] md:w-[200px] md:h-[205px] sm:w-[290px] sm:h-[300px] w-[270px] h-[290px] object-cover opacity-100" />
                                            <div id='textColor' className="md:w-full md:ml-[40px] md:mt-2 text-white group-hover:text-black duration-500 md:duration-700 transition-all md:p-2 md:pr-3 w-auto ">
                                                <p className='ms:text-lg text-base font-semibold sm:tracking-wider tracking-wide mt-4' >{position}</p>
                                                <div id="border" className=' md:w-[100px] sm:w-[80px] w-[60px] rounded-lg h-[3px] bg-[#8cc8f3] group-hover:bg-[#2277B3] group-hover:w-full sm:group-hover:w-[300px] duration-1000 transition-all sm:mt-1 xl:mb-3 mb-2 mt-1 ' ></div>
                                                <p className='xl:text-xl sm:text-lg text-base xl:tracking-wide tracking-normal mb-1' >{name}</p>
                                                <div className='xl:text-base md:text-sm ms:text-base text-xs' >
                                                    <p className="flex items-center" > <FcOvertime className="xl:text-xl ms:text-lg text-base mr-2" />{receptionDays}</p>
                                                    <p className="flex items-center" > <FcPhone className="xl:text-xl ms:text-lg text-base  mr-2 -rotate-90 " /> {phone}</p>
                                                    <p className="flex items-center" > <FcPrint className="xl:text-xl ms:text-lg text-base  mr-2" /> {fax}</p>
                                                    <p id='mail' className="flex items-center text-white group-hover:text-[#007bff] duration-500 md:duration-700 transition-all" > <FcAddressBook className="xl:text-xl ms:text-lg text-base mr-2"/> {email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>
                            ) :
                            (
                                <div onMouseEnter={on_Mouse_Enter} onMouseLeave = {on_Mouse_Leave} key={index} className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10 col-span-12 overflow-hidden md:rounded-lg md:border-3 bg-[#faf8f8] group hover:bg-[#2277B3] transition-all duration-500 md:duration-700 opacity-95 hover:opacity-100 xl:mt-5 md:mt-3 mt-5 pb-5 md:pb-0">
                                    {/* <Link to={`/governing_body${name}`} className="basis-full cursor-pointer"> */}
                                        <div className="flex flex-col md:flex-row items-center">
                                            <img src={Image[index]} alt="news-img" className="xl:w-[240px] xl:h-[225px] md:w-[200px] md:h-[205px] sm:w-[290px] sm:h-[300px] object-cover opacity-100" />
                                            <div className="md:w-full md:ml-[40px] md:mt-2 text-black group-hover:text-white duration-500 md:duration-700 transition-all md:p-2 md:pr-3 w-auto sm:mx-12 mx-2 ">
                                                <p className='ms:text-lg text-base font-semibold xl:tracking-wider tracking-wide leading-tight mt-4 md:mt-0' >{position}</p>
                                                <div className=' md:w-[100px] sm:w-[80px] w-[60px] rounded-lg h-[3px] bg-[#2277B3] group-hover:bg-[#8cc8f3] group-hover:w-full sm:group-hover:w-[300px] duration-1000 transition-all sm:mt-1 xl:mb-2 md:mb-1 mb-2 mt-1' ></div>
                                                <p className='xl:text-xl sm:text-lg text-base xl:tracking-wide tracking-normal mb-1' >{name}</p>
                                                <div className='xl:text-base md:text-sm ms:text-base text-xs' >
                                                    <p className="flex items-center" > <FcOvertime className="xl:text-xl ms:text-lg text-base mr-2" />{receptionDays}</p>
                                                    <p className="flex items-center" > <FcPhone className="xl:text-xl ms:text-lg text-base  mr-2 -rotate-90 " /> {phone}</p>
                                                    <p className="flex items-center" > <FcPrint className="xl:text-xl ms:text-lg text-base  mr-2" /> {fax}</p>
                                                    <p className="flex items-center text-[#007bff] group-hover:text-white duration-500 md:duration-700 transition-all" > <FcAddressBook className="xl:text-xl ms:text-lg text-base  mr-2"/> {email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Link> */}
                                </div>
                            )
                        }
                    </>
                )
            })
        }
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part1')}
            </p>
            <p className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                {t('economy.part1_text')}
            </p>
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part2')}
            </p>
            {
                t('economy.part2_text', {returnObjects: true}).map((card, index) => {
                    const {text} = card
                    return(
                        <p key={index} className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                            <span className='mr-3' >{index+1}.</span>{text}
                        </p>
                    )
                })
            }
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part3')}
            </p>
            {
                t('economy.part3_text', {returnObjects: true}).map((card, index) => {
                    const {text} = card
                    return(
                        <p key={index} className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                            <span className='mr-3' >{index+1}.</span>{text}
                        </p>
                    )
                })
            }
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part4')}
            </p>
            <p className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                {t('economy.part4_tetx')}
            </p>
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part5')}
            </p>
            {
                t('economy.part5_text', {returnObjects: true}).map((card, index) => {
                    const {text} = card
                    return(
                        <p key={index} className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed mb-3' >
                            {text}
                        </p>
                    )
                })
            }
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part6')}
            </p>
            <p className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                {t('economy.part6_text')}
            </p>
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part7')}
            </p>
            <p className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed' >
                {t('economy.part7_text')}
            </p>
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part9')}
            </p>
            {
                t('economy.part9_tetx', {returnObjects: true}).map((card, index) => {
                    const {text} = card
                    return(
                        <p key={index} className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed mb-3' >
                            {text}
                        </p>
                    )
                })
            }
        </div>
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 md:p-2 sm:mx-5 sm:py-2 mx-4 mt-3">
            <p className='lg:col-start-2 lg:col-span-10 col-span-12 xl:text-xl text-[#1cb5e0] hover:text-[#263d8a] cursor-default transition-colors duration-700 sm:text-lg text-base font-semibold xl:tracking-wider tracking-wide sm:mb-3 mb-1' >
                {t('economy.part10')}
            </p>
            {
                t('economy.part10_text', {returnObjects: true}).map((card, index) => {
                    const {text} = card
                    return(
                        <p key={index} className='pl-3 mr-2 sm:text-base text-sm tracking-wide leading-relaxed mb-3' >
                            {text}
                        </p>
                    )
                })
            }
        </div>
    </div>
  )
}

export default Economy