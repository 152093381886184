import React from 'react'
import about1 from '../media/about1.jpg'
import about2 from '../media/about2.jpg'
import about3 from '../media/about3.jpg'
import about4 from '../media/about4.jpg'
import about5 from '../media/about5.jpg'

function AboutUniversity() {
  return (
    <div className='w-full 2xl:my-40 xl:my-28 sm:my-20 ms:my-[19vw] my-20 grid grid-cols-12 xl:px-0 md:px-5 sm:px-8 px-3' >
        <div className='xl:col-start-2 xl:col-span-10 col-span-12 grid grid-cols-12 gap-4'>
            <div className='col-span-12' >
                <img src={about1} width="100%" />
            </div>
            <div className='lg:col-span-6 col-span-12 lg:mb-0 mb-7' >
                <img src={about2} width="100%" />
            </div>
            <div className='lg:col-span-6 col-span-12 lg:mb-0 mb-7' >
                <img src={about3} width="100%" />
            </div>
            <div className='lg:col-span-6 col-span-12 lg:mb-0 mb-7' >
                <img src={about4} width="100%" />
            </div>
            <div className='lg:col-span-6 col-span-12 lg:mb-0 mb-7' >
                <img src={about5} width="100%" />
            </div>
        </div>
    </div>
  )
}

export default AboutUniversity