import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Viewer } from '@react-pdf-viewer/core'; // install this library
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core'; // install this library
import {useTranslation} from 'react-i18next'
import Conferences from './Conferences.pdf'
import ConferencesEn from './Conferences_en.pdf'
import ConferencesRu from './Conferences_ru.pdf'
const useStyles = makeStyles((theme) => ({
    pdfContsiner: {
        margin: "10vw auto",
        width: "75vw",
        height: "70vw",
        [theme.breakpoints.down('xs')]: {
            margin: "30vw auto",
            width: "96vw",
            height: "80vh",
        },
    },
    pdfContsinerTitle:{
        margin: "2vw auto",
        textAlign: "center",
        fontSize: "2vw",
        fontWeight: 500,
        letterSpacing: "0.1vw",
        [theme.breakpoints.down('xs')]: {
            margin: "4vw auto",
            fontSize: "4vw",
            letterSpacing: "0.2vw",
        },
    }
}))


function ScientificConferences() {
    const {t}=useTranslation();
    const classes = useStyles();
    const [defaultPdfFile, setdefaultPdfFile] = useState(null)
    useEffect(() => {
        switch (t('header.lang')) {
            case "uz":
                setdefaultPdfFile(Conferences)
                break;
            case "en":
                setdefaultPdfFile(ConferencesEn)
                break;
            default:
                setdefaultPdfFile(ConferencesRu)
        }
    }, [t('header.lang')])
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className={classes.pdfContsiner} >
            <p className={classes.pdfContsinerTitle} >{t('home4.cards', {returnObjects: true})[11].cardTitle}</p>
            {defaultPdfFile&&<><Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
          <Viewer fileUrl={defaultPdfFile}
            plugins={[defaultLayoutPluginInstance]} />
      </Worker></>}
        </div>
    )
}

export default ScientificConferences
